<template>
	<div class="content-custom-filter">
		<p>Admins can create bid adjustments or exclusions using NextGens custom filtering interface.</p>
		<div class="custom-bids">
			<template v-for="custom_bid in sortedCustomBids" :key="custom_bid.id">
				<custom-bid
					:custom-bid="custom_bid"
					:mode="campaignStore.campaign.mode"
					type="source"
					filter-option
					@edit="editCustomBid"
					@delete="deleteCustomBid"
				>
					<strong>{{ custom_bid.name }}</strong>
				</custom-bid>
			</template>
		</div>
		<template v-if="sortedCustomBids.length === 0">
			<p-message :closable="false">Add a custom bid for advanced control over bidding for a campaign.</p-message>
		</template>
		<div class="actions justify-content-start flex gap-20 flex-wrap">
			<p-button icon="pi pi-plus" label="Add Custom Bid" @click="addNewCustomBid" />
			<p-button
				v-if="!hasSourceTargetingBid"
				icon="pi pi-plus"
				label="Add Source Targeting"
				@click="addSourceTargetingCustomBid"
			/>
		</div>
	</div>

	<!-- START CUSTOM BID MODAL -->
	<p-dialog id="filtering-modal" v-model:visible="show_bid_modal" style="width: 760px" :modal="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="target-account" size="24px" style="color: var(--color-b)" />
				<strong>Create Custom Filtering Logic</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Custom Bid Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text
							id="filtering-group"
							ref="custom_bid_name_input"
							v-model="custom_bid.name"
							aria-describedby="filtering-group-help"
							autofocus
							placeholder="Enter a group name: I.E. High Income Leads"
							:disabled="!$root.sessionStore.isSuperAdmin"
						/>
						<div v-if="v$.custom_bid.name.$error" class="validation-error">
							{{ v$.custom_bid.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Conditions:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse.lazy="custom_bid.match" :disabled="!$root.sessionStore.isSuperAdmin" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Disqualification Message:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="custom_bid.dq_message" :disabled="!$root.sessionStore.isSuperAdmin" />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<p-button text label="Test Filter" @click="openTestModal" />
			<p-button label="Cancel" text @click="show_bid_modal = false" />
			<p-button v-if="$root.sessionStore.isSuperAdmin" label="Save Custom Bid" icon="pi pi-check" @click="saveBid" />
		</template>
	</p-dialog>
	<!-- END CUSTOM BID MODAL -->

	<!-- START SOURCE TARGETING MODAL -->
	<p-dialog modal v-model:visible="show_source_targeting_modal" style="max-width: 600px">
		<template #header>
			<div class="flex align-items-center">
				<icon type="bullseye-arrow" size="24px" /><gutter size="10px" />
				<strong>Explicit Source Targeting</strong>
			</div>
		</template>
		<p>Enter a list of sources that you would like to match leads against, one per line...</p>
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field">
						<p-textarea v-model="parsedSources" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<div class="controls gap-10" style="margin-bottom: 0">
					<div class="field fit">
						<p-radio-button input-id="target_sources" v-model="source_targeting_bid.match[0].invert" :value="true" />
					</div>
					<div class="field">
						<label for="target_sources">Only bid on leads from sources that match</label>
					</div>
				</div>
				<div class="controls gap-10">
					<div class="field fit">
						<p-radio-button input-id="exclude_sources" v-model="source_targeting_bid.match[0].invert" :value="false" />
					</div>
					<div class="field">
						<label for="exclude_sources"><strong>Exclude</strong> leads from sources that match</label>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex align-items-center justify-content-between">
				<p-button text label="Cancel" @click="show_source_targeting_modal = false" />
				<p-button icon="pi pi-check" label="Save Source Filter" @click="saveSourceTargetingCustomBid()" />
			</div>
		</template>
	</p-dialog>
	<!-- END SOURCE TARGETING MODAL -->

	<p-dialog id="testing-modal" v-model:visible="show_test_modal" style="width: 960px" :modal="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="target-account" size="24px" style="color: var(--color-b)" />
				<strong>Testing Match Logic</strong>
			</div>
		</template>
		<div v-if="lead_test_results.length === 0">
			<p>The Custom Bid values will be used to determine the final bid, or disqualification reason if needed.</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Conditions:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse.lazy="custom_bid.match" />
						</div>
					</div>
				</div>
			</div>
			<p class="mt-3"><strong>Select how to test the Custom Filter</strong></p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Mode:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="status"
								v-model="test_mode"
								:options="test_mode_options"
								placeholder="Select Mode"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
				<div v-if="test_mode === 'random'">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Random Lead Count:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="status"
										v-model="leads_to_test"
										:options="lead_count_options"
										placeholder="Select Number of Leads"
										option-label="label"
										option-value="value"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="test_mode === 'lead_id'">
					<div class="control-group">
						<div class="inner">
							<label class="control-label required">Lead ID:</label>
							<div class="controls">
								<div class="field">
									<p-input-text id="test_lead_id" v-model="lead_id" placeholder="Enter a Lead ID, e.g. D-T343-DE33" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<code-editor v-model.parse.lazy="leads" />
				</div>
			</div>
		</div>
		<div v-else>
			<h2>Custom Filter Test Results</h2>
			<div v-for="result in lead_test_results">
				<p class="mb-0">
					<span class="label">Lead ID: </span> <strong>{{ result.lead_id }}</strong>
				</p>
				<p class="mb-0">
					<span class="label">Matches: </span> <code style="color: var(--orange-500)">{{ result.matches }}</code>
				</p>
				<p>
					<span class="label">Result: </span>
					<strong>{{ typeof result.result === 'number' ? currency(result.result) : result.result }}</strong>
				</p>
				<gutter size="15px" />
				<div v-for="(outer_details, index) in result.match_values" class="details-container">
					<p-fieldset legend="Details">
						<div v-for="details in outer_details" class="detail-box">
							<p class="mb-0">
								<span class="label">Strategy:</span>
								<code style="color: var(--orange-500)">{{ details.strategy }}</code>
							</p>
							<p class="mb-0" v-if="'comparator_path' in details">
								<span class="label">Comparator Path:</span> <code>{{ details.comparator_path }}</code>
							</p>
							<p class="mb-0" v-if="'comparator_transformer' in details">
								<span class="label">Comparator Transformer:</span> <code>{{ details.comparator_transformer }}</code>
							</p>
							<p class="mb-0" v-if="'comparator_value' in details">
								<span class="label">Expected Value:</span> <code>{{ details.comparator_value }}</code>
							</p>
							<p class="mb-0">
								<span class="label">Target Path:</span> <code>{{ details.target_path }}</code>
							</p>
							<p class="mb-0" v-if="'target_transformer' in details">
								<span class="label">Target Transformer:</span> <code>{{ details.target_transformer }}</code>
							</p>
							<p class="mb-0">
								<span class="label">Actual Value:</span> <code>{{ details.target_value }}</code>
							</p>
							<p class="mb-0">
								<span class="label">Invert:</span>
								<code>{{ details.invert }}</code>
							</p>
						</div>
					</p-fieldset>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-between w-full">
				<p-button
					text
					label="Cancel"
					@click="
						show_test_modal = false;
						lead_test_results = [];
					"
				/>
				<div class="actions">
					<p-button text label="Re-Test" @click="lead_test_results = []" v-if="lead_test_results.length > 0" />
					<p-button label="Test" icon="pi pi-check" @click="testBid" :loading="loading" />
				</div>
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { cloneDeep, merge, sortBy } from 'lodash-es';
import { nextTick } from 'vue';
import customBid from '@/components/widgets/CustomBid.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import { useCampaignStore } from '@/stores/campaign';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import codeEditor from '@/components/forms/CodeEditor.vue';
import { getRandomLead, getLeadList, getLead, testBid } from '@GQL';
import { currency } from '@/lib/Filters';
import pFieldset from 'primevue/fieldset';

const default_custom_bid = {
	id: null,
	name: '',
	type: 'custom',
	match: [
		{
			target: {
				path: '',
				transformer: null,
			},
			strategy: 'equals',
			comparator: {
				value: '',
				path: null,
				transformer: null,
				regex_flags: null,
			},
			match_missing: false,
			invert: false,
		},
	],
	method: 'multiply',
	amount: 1,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Lead excluded from targeting by custom filter',
};

const default_source_targeting_bid = {
	id: 'source_targeting_bid',
	name: 'Explicit Source Targeting',
	type: 'custom',
	match: [
		{
			target: {
				path: 'lead',
				transformer: 'sourceValues',
			},
			strategy: 'matches_some',
			comparator: {
				value: [],
				transformer: 'wildcard',
			},
			match_missing: false,
			invert: false,
		},
	],
	method: 'multiply',
	amount: 0,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Source excluded from targeting',
};

export default {
	name: 'CustomFilteringContent',
	props: {
		product: {
			required: true,
			type: String,
		},
		vertical_id: {
			required: true,
			type: String,
		},
	},
	components: {
		customBid,
		deleteAction,
		pDialog,
		pMessage,
		pFieldset,
		codeEditor,
	},
	setup() {
		return {
			campaignStore: useCampaignStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			test_mode_options: [
				{ label: 'Random', value: 'random' },
				{ label: 'Specific Lead ID', value: 'lead_id' },
				{ label: 'Custom', value: 'custom' },
			],
			lead_count_options: [
				{ label: '5', value: 5 },
				{ label: '10', value: 10 },
				{ label: '15', value: 15 },
			],
			loading: false,
			lead_id: '',
			leads: [],
			lead_test_results: [],
			leads_to_test: 5,
			test_mode: 'random',
			show_bid_modal: false,
			show_test_modal: false,
			show_paused: false,
			custom_bid: cloneDeep(default_custom_bid),
			source_targeting_bid: cloneDeep(default_source_targeting_bid),
			show_source_targeting_modal: false,
		};
	},
	computed: {
		hasSourceTargetingBid() {
			const source_targeting_bid = this.campaignStore.campaign.bids.find((custom_bid) => {
				return custom_bid.type === 'custom' && custom_bid.name === 'Explicit Source Targeting';
			});

			return source_targeting_bid;
		},
		sortedCustomBids() {
			return sortBy(
				this.campaignStore.campaign.bids.filter((custom_bid) => custom_bid.type === 'custom'),
				(custom_bid) => {
					return custom_bid.name;
				}
			);
		},
		parsedSources: {
			get() {
				return this.source_targeting_bid.match[0].comparator.value.join('\n');
			},
			set(new_value) {
				const sources = new_value
					.replace(',', '\n')
					.split(/\n|\s/g)
					.map((v) => {
						return v.trim();
					});

				this.source_targeting_bid.match[0].comparator.value = sources;
			},
		},
	},
	validations() {
		return {
			custom_bid: {
				name: {
					required: helpers.withMessage('A name for this custom bid is required', required),
					isUnique: helpers.withMessage('A custom bid with this name already exists', (value) => {
						return this.campaignStore.isUniqueCustomBid(value, 'custom', this.custom_bid.id);
					}),
					notReserved: helpers.withMessage(`The name 'Explicit Source Targeting' is reserved`, (value) => {
						return value !== 'Explicit Source Targeting';
					}),
					$lazy: true,
				},
			},
		};
	},
	methods: {
		currency,
		async openTestModal() {
			await this.getRandomLead();
			this.show_test_modal = true;
		},
		async testBid() {
			this.loading = true;
			try {
				// based on the mode we may not need to get a lead at all
				if (this.test_mode === 'custom') {
					// pull in a lead
					// the lead should have been edited already
				} else if (this.test_mode === 'random') {
					// this is where we get the leads
					const { rows } = await getLeadList({
						filters: [[`vertical_id = '${this.vertical_id}' AND product = '${this.product}'`]],
						order: {
							field: 'created_at',
							desc: true,
						},
						pagination: {
							page_size: this.leads_to_test,
							page: 0,
						},
					});
					if (rows.length > 0) {
						this.leads = rows;
					} else {
						this.$toast.add({
							severity: 'warn',
							summary: `Unable to get a random lead that matches the campaign's product and vertical`,
							life: 6000,
						});
					}
				} else if (this.test_mode === 'lead_id') {
					const lead = await getLead(this.lead_id);
					if (lead) {
						this.leads_to_test = [lead];
					} else {
						this.$toast.add({
							severity: 'warn',
							summary: `Unable to get the specified lead`,
							life: 6000,
						});
					}
				}

				if (this.leads.length > 0) {
					// do the filtering on the tests and see what the results are
					// apply the custom filter logic and see what happens with each
					const result = await testBid({ leads: this.leads, custom_bid: this.custom_bid });

					this.lead_test_results = result;
				} else {
					this.$toast.add({
						severity: 'warn',
						summary: `No leads to test were detected`,
						life: 6000,
					});
				}
			} finally {
				this.loading = false;
			}
		},
		resetModalState() {
			this.v$.$reset();
			this.custom_bid = cloneDeep(default_custom_bid);
		},
		addNewCustomBid() {
			this.resetModalState();
			this.show_bid_modal = true;

			nextTick(() => {
				this.$refs.custom_bid_name_input.$el.focus();
			});
		},
		closeTestModal() {
			this.show_test_modal = false;
		},
		editCustomBid(bid_id: string) {
			if (bid_id === 'source_targeting_bid') {
				this.editSourceTargetingCustomBid();
			} else {
				this.resetModalState();

				const custom_bid = this.campaignStore.campaign.bids.find((custom_bid) => custom_bid.id === bid_id);
				if (custom_bid) {
					this.custom_bid = custom_bid;
				}

				this.show_bid_modal = true;
				nextTick(() => {
					this.$refs.custom_bid_name_input.$el.select();
				});
			}
		},
		deleteCustomBid(bid_id: string) {
			return this.campaignStore.deleteBid(bid_id);
		},
		addSourceTargetingCustomBid() {
			this.source_targeting_bid = cloneDeep(default_source_targeting_bid);
			this.show_source_targeting_modal = true;
		},
		editSourceTargetingCustomBid() {
			// Find the source targeting custom bid
			const matching_source_targeting_bid = this.campaignStore.campaign.bids.find((custom_bid) => {
				return custom_bid.id === 'source_targeting_bid';
			});

			if (matching_source_targeting_bid) {
				this.source_targeting_bid = cloneDeep(matching_source_targeting_bid);
				this.show_source_targeting_modal = true;
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to find source targeting bid',
					detail: 'Please try refreshing the page',
				});
			}
		},
		saveSourceTargetingCustomBid() {
			const source_targeting_bid = cloneDeep(this.source_targeting_bid);

			const existing_bid = this.campaignStore.campaign.bids.find((custom_bid) => {
				return custom_bid.id === 'source_targeting_bid';
			});

			if (existing_bid) {
				this.campaignStore.saveBid(source_targeting_bid);
			} else {
				this.campaignStore.addBid(source_targeting_bid);
			}

			this.show_source_targeting_modal = false;
		},
		deleteSourceTargetingCustomBid() {
			const source_targeting_bid_index = this.campaignStore.campaign.bids.findIndex((custom_bid) => {
				return custom_bid.id === 'source_targeting_bid';
			});

			if (source_targeting_bid_index) {
				this.campaignStore.campaign.bids.splice(source_targeting_bid_index, 1);
			}
		},
		async saveBid() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				const custom_bid = cloneDeep(this.custom_bid);

				if (custom_bid.id) {
					this.campaignStore.saveBid(custom_bid);
				} else {
					this.campaignStore.addBid(custom_bid);
				}

				this.show_bid_modal = false;
			}
		},
		async getRandomLead() {
			try {
				if (this.vertical_id !== '' && this.product !== '') {
					const random_lead = await getRandomLead(this.vertical_id, this.product);
					if (random_lead && 'id' in random_lead) {
						this.leads = [random_lead];
					} else {
						console.error('No Lead found');
					}
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';
.label {
	color: var(--gray-50);
	font-size: 0.8rem;
}
.details-container {
	margin-bottom: 1rem;
}
.detail-box {
	&:not(:last-child) {
		border-bottom: 1px var(--gray-50) dashed;
		margin-bottom: 1rem;
		padding: 0 0 1rem 0;
	}
}
</style>
