<template>
	<div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="status">Target Type:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="target_type"
							v-model="modelValue.target_type"
							:options="target_type_options"
							placeholder="Select Target Type"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="types_with_text_input.indexOf(modelValue.target_type) > -1">
			<div class="inner">
				<label class="control-label req" for="status">Value:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="modelValue.value" :placeholder="textFieldPlaceHolder" />
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'account_users_by_mpid'">
			<div class="inner">
				<label class="control-label req" for="status">MPID:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.value"
							placeholder="Select MPID"
							:options="mpid_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'user'">
			<div class="inner">
				<label class="control-label req" for="status">User:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.value"
							:options="user_options"
							showClear
							option-group-label="label"
							option-group-children="items"
							option-label="label"
							option-value="value"
							class="w-full"
							filter
						>
						</p-dropdown>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="modelValue.target_type === 'users_by_marketplace_role'">
			<div class="inner">
				<label class="control-label req" for="status">MPID:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="modelValue.mpid"
							placeholder="Select MPID"
							:options="mpid_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { getUsersAsOptions, getMarketplacesAsOptions } from '@GQL';

const target_type_options = [
	{ label: 'Single User', value: 'user' }, // value to be user ulid
	{ label: 'All Account Users', value: 'account_users' }, // value to be account_id
	{ label: 'All Account Users for Account Manager', value: 'account_users_by_account_manager' }, // value to be Account Manager
	{ label: 'All Account Users for Marketplace', value: 'account_users_by_mpid' }, // value to be only mpids
	{ label: 'All Internal Users', value: 'internal_users' }, // No VALUE
	{ label: 'All External Users', value: 'external_users' }, // No VALUE
	{ label: 'All Users w/ Role', value: 'users_by_role' },
	{ label: 'All Users w/ specific Marketplace Role', value: 'users_by_marketplace_role' },
	{ label: 'All Account Users w/ Account Tag', value: 'users_by_account_tag' },
];

export default {
	name: 'NotificationFilterComponent',
	props: {
		modelValue: {
			type: Object,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	computed: {
		textFieldPlaceHolder() {
			switch (this.modelValue.target_type) {
				case 'account_users':
					return 'Account ID e.g. NGL-000-002';
				case 'users_by_marketplace_role':
				case 'users_by_role':
					return 'Role ID e.g. account_admin';
			}
		},
	},
	async mounted() {
		// get the users
		this.user_options = await getUsersAsOptions();
		this.mpid_options = await getMarketplacesAsOptions();
	},
	watch: {
		modelValue: {
			handler(new_val) {
				// if the new_val has a target type that doesn't need value, remove it for simplicity
				if (this.types_without_value.indexOf(new_val.target_type) > -1) {
					delete new_val.mpid;
					delete new_val.value;
				}
				if (new_val.target_type !== 'users_by_marketplace_role') {
					delete new_val.mpid;
				}

				this.$emit('update:modelValue', new_val);
			},
			deep: true,
		},
	},
	data() {
		return {
			mpid_options: [],
			user_options: [],
			target_type_options,
			types_without_value: ['internal_users', 'external_users'],
			types_with_text_input: ['account_users', 'users_by_role', 'users_by_marketplace_role'],
		};
	},
};
</script>
