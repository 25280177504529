<template>
	<div class="content-campaign-bids">
		<p v-if="campaignStore.campaign.mode === 'advanced'">
			Advanced mode allows you to bid up or down on various attributes, but rest assured that you will never pay more
			than your Maximum Bid.
		</p>
		<div class="bid-section">
			<div class="bid-row">
				<div class="description">
					<strong class="default-bid-label">
						<template v-if="campaignStore.isBasicMode">Maximum Bid</template>
						<template v-else>Default Bid</template>
					</strong>
					<div class="sub-value">
						<template v-if="campaignStore.isBasicMode">
							This is the highest price this campaign will pay for a lead
						</template>
						<template v-else>
							Modifiers move this bid up or down; when there are no modifiers, the campaign will bid this amount.<br />
							For more information about how advanced bidding works
							<a href="javascript:void(0)" @click="campaignStore.show_bid_info_modal = true">click here</a>.
						</template>
					</div>
					<div v-if="v$.defaultBid.$error" class="validation-error">
						{{ v$.defaultBid.$errors[0].$message }}
					</div>
				</div>
				<div class="default-bid">
					<div class="flex align-items-center justify-content-end" style="gap: 10px">
						<template v-if="campaignStore.isBasicMode">Maximum Bid:</template>
						<template v-else>Default Bid:</template>
						<div class="flex align-items-center gap-2">
							<div class="p-inputgroup">
								<div class="p-inputgroup-addon">$</div>
								<p-input-number
									v-model="campaignStore.campaign.default_bid"
									class="bid-input input-align-right"
									placeholder="15.00"
									mode="decimal"
									:min-fraction-digits="2"
									:max-fraction-digits="2"
									currency="USD"
									locale="en-US"
									@blur="v$.defaultBid.$touch()"
								/>
							</div>
						</div>
					</div>
					<div class="minimum-bid">
						Minimum Bid:
						<strong
							>{{ currency(campaignStore.minimumBid) }}
							<span v-if="campaignStore.campaign.custom_minimum_bid">*</span></strong
						>
						<template v-if="sessionStore.isAdminUser">
							<div class="minimum-bid-edit" @click.prevent="toggleMinBidEditPanel">
								<alt-icon type="edit_square" size="20px" />
							</div>
						</template>
						<p-overlay-panel ref="min_bid_op" class="min-bid-overlay-panel">
							<div class="overlay-container flex-column">
								<div class="control-group">
									<div class="inner">
										<div class="control-label">Custom Min Bid:</div>
										<div v-tooltip.left="'Enable Custom Minimum'" class="controls">
											<div class="field fit">
												<p-input-switch
													v-model="campaignStore.campaign.custom_minimum_bid"
													input-id="custom-min-bid-switch"
												/>
											</div>
											<gutter size="10px" />
											<div class="field xs">
												<div class="p-inputgroup">
													<span class="p-inputgroup-addon">$</span>
													<p-input-number
														v-model="campaignStore.campaign.minimum_bid"
														:disabled="!campaignStore.campaign.custom_minimum_bid"
														class="bid-input input-align-right"
														placeholder="Set Minimum Bid"
														input-id="currency-us"
														mode="decimal"
														:min-fraction-digits="2"
														:max-fraction-digits="2"
														currency="USD"
														locale="en-US"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</p-overlay-panel>
					</div>
				</div>
			</div>
			<div
				v-if="campaignStore.isAdvancedMode"
				:class="['bid-row', 'maximum-bid-row', { disabled: !campaignStore.campaign.enable_maximum_bid }]"
			>
				<div class="toggle">
					<p-input-switch
						v-model="campaignStore.campaign.enable_maximum_bid"
						v-tooltip.top="'Check to enable a maximum bid'"
					/>
				</div>
				<div class="description">
					<strong class="default-bid-label">Maximum Bid</strong>
					<div class="sub-value">
						The highest this campaign will bid. Modifiers will never push your bid above this amount.
					</div>
					<div v-if="v$.maximumBid.$error" class="validation-error">
						{{ v$.maximumBid.$errors[0].$message }}
					</div>
				</div>
				<div class="maximum-bid">
					Maximum Bid:
					<div class="flex align-items-center gap-2">
						<div class="p-inputgroup">
							<div class="p-inputgroup-addon">$</div>
							<p-input-number
								v-model="campaignStore.campaign.maximum_bid"
								class="bid-input input-align-right"
								placeholder="15.00"
								mode="decimal"
								:min-fraction-digits="2"
								:max-fraction-digits="2"
								currency="USD"
								locale="en-US"
								:disabled="!campaignStore.campaign.enable_maximum_bid"
								@blur="v$.maximumBid.$touch()"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="campaignStore.campaign.product_targeting === 'call' && campaignStore.channel.maximum_duration > 0">
			<p-fieldset legend="Call Duration Bidding">
				<p>
					If you only want to pay for calls that last a minimum duration, you can set a duration here. Note that setting
					a duration will lower the weight of your bid in our auction. The higher your minimum duration, the less
					competitive your bid will be.
				</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Channel Duration (Seconds):</label>
						<div class="controls gap-20">
							<div class="field xs">
								<div class="p-inputgroup">
									<p-input-number
										v-model="campaignStore.campaign.duration"
										:step="5"
										:min="campaignStore.channel.minimum_duration"
										:max="campaignStore.channel.maximum_duration"
										class="input-align-right"
									/>
									<div class="p-inputgroup-addon">s</div>
								</div>
							</div>
							<div class="field">
								<p-slider
									v-model="campaignStore.campaign.duration"
									:step="5"
									:min="campaignStore.channel.minimum_duration"
									:max="campaignStore.channel.maximum_duration"
								/>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
		</template>

		<!-- <div class="callout">
			<div class="header">
				<img src="/assets/img/icons/icon-question.svg" alt="Question Icon" />
				How Does Bidding Work?
			</div>
			<p>
				NextGen Leads allows you to adjust your campaign bids based on different factors such as location, the time of
				day and day of week, and the properties on the incoming lead. This helps you maintain a steady flow of lead
				volume while optimizing for your target CPAs. You can adjust your bids between
				<strong>0%</strong> (DO NOT BID) and <strong>300%</strong> of your default bid. Bid adjustments will stack if
				multiple matches are made. If your bid adjusts between $0.01 and the minimum bid amount
				<strong>($8.00)</strong>, your bid will be adjusted to the minimum bid. If your bid goes above 300% of your
				default bid, it will be adjusted down.
				<a href="#"> Click here for more information. </a>
			</p>
		</div> -->
		<p-fieldset v-if="sessionStore.isAdminUser" class="admin-only" legend="Adjust Bids by Source">
			<p>
				<strong>ADMIN ONLY:</strong> Make bid adjustments that target specific source properties on a lead. Any sources
				not included in the list below will bid at 100% of the default bid. Any leads that match multiple source filters
				below will bid according to the first match (top-down).
			</p>
			<div class="custom-bids">
				<template v-for="custom_bid in sortedCustomBids" :key="custom_bid.id">
					<custom-bid
						:custom-bid="custom_bid"
						:mode="campaignStore.campaign.mode"
						type="source"
						filter-option
						:minimum-bid="campaignStore.minimumBid"
						@edit="editCustomBid"
						@delete="deleteCustomBid"
					>
						<strong>{{ custom_bid.name }}</strong>
						<div class="source-details">
							<div v-for="source_value in sourceDetails(custom_bid.name)" class="source-value">
								<div class="label">{{ source_value.label }}</div>
								<div class="value" v-tooltip.top="source_value.value">{{ source_value.value }}</div>
							</div>
						</div>
					</custom-bid>
				</template>
			</div>
			<div class="actions flex gap-2 flex-wrap">
				<p-button label="Add Source Bids" @click="addMultipleCustomBids()">
					<template #icon>
						<div style="margin-right: 10px">
							<icon type="plus-box-multiple-outline" size="20px" />
						</div>
					</template>
				</p-button>
			</div>
		</p-fieldset>

		<p-fieldset legend="Budgets">
			<p>Set a budget based on spend or lead count on a daily, weekly, or monthly basis.</p>
			<p-message v-if="hasAccountBudgets" severity="warn" :closable="false">
				There are active account-level budgets that will affect this campaign.
			</p-message>
			<div class="budgets">
				<div v-for="budget in sortedBudgets" :class="['budget', { paused: !budget.enabled }]" :key="budget.id">
					<div class="toggle">
						<p-input-switch v-model="budget.enabled" />
					</div>
					<div class="product">
						<strong>{{ budgetDescription(budget) }}</strong
						><br />
					</div>
					<div class="toolset">
						<div class="tool">
							<p-button v-tooltip.top="'Edit Budget'" aria-label="Edit Budget" @click="editBudget(budget)">
								<template #icon>
									<icon type="square-edit-outline" size="20px" />
								</template>
							</p-button>
						</div>
						<delete-action message="Are you sure you want to delete this budget?" @delete="deleteBudget(budget.id)" />
					</div>
				</div>
				<div class="row">
					<p-button label="Add Budget" icon="pi pi-plus" @click="addBudget()" />
				</div>
			</div>
		</p-fieldset>
	</div>

	<!-- BID MODAL -->
	<p-dialog id="source-bid-modal" v-model:visible="show_bid_modal" modal style="max-width: 700px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="hand-coin-outline" size="24px" style="color: var(--color-b)" />
				<strong>Source Bid</strong>
			</div>
		</template>
		<template v-if="campaignStore.campaign.promotion_id && !campaignStore.promotion.allow_source_filtering">
			<p-message severity="error" :closable="false">
				The promotion attached to this campaign does not allow source filtering. Any source bids attempting to filter
				out a source will be ignored.
			</p-message>
		</template>
		<p-message severity="warn" style="margin-top: 0">
			Make sure to save your changes, closing out of this window without saving will cancel this grouping.
		</p-message>
		<p>
			Enter a single value to match against the source_id, sub_id, sub_hash, or source_tags. An asterisk
			(<code>*</code>) may be used as a wildcard.
		</p>
		<row>
			<column>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Match Value:</label>
						<div class="controls">
							<div class="field">
								<p-input-text
									ref="source_value"
									v-model="custom_bid_value"
									input-id="custom-bid-value-input"
									placeholder="Source Value"
									name="source_value"
									@blur="v$.custom_bid_value.$touch"
								/>
								<div v-if="v$.custom_bid_value.$errors.length" class="validation-error">
									{{ v$.custom_bid_value.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<template
					v-if="
						campaignStore.campaign.product_targeting === 'call'
						// || campaignStore.campaign.product_targeting === 'live_transfer'
					"
				>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Custom Duration:</label>
							<div class="controls gap-20">
								<div class="field fit">
									<p-input-switch v-model="custom_bid.custom_duration" />
								</div>
								<div class="field xs">
									<div class="p-inputgroup">
										<p-input-number
											v-model="custom_bid.duration"
											:step="5"
											:min="campaignStore.channel.minimum_duration"
											:max="campaignStore.channel.maximum_duration"
											:disabled="!custom_bid.custom_duration"
											class="input-align-right"
										/>
										<div class="p-inputgroup-addon">s</div>
									</div>
								</div>
								<div class="field">
									<p-slider
										v-model="custom_bid.duration"
										:step="5"
										:min="campaignStore.channel.minimum_duration"
										:max="campaignStore.channel.maximum_duration"
										:disabled="!custom_bid.custom_duration"
									/>
								</div>
							</div>
						</div>
					</div>
				</template>
			</column>
		</row>

		<template #footer>
			<p-button text label="Cancel" @click="show_bid_modal = false" />
			<p-button label="Save Source Bid" icon="pi pi-check" @click="saveBid" />
		</template>
	</p-dialog>
	<!-- END BID MODAL -->

	<!-- MULTIPLE BIDS MODAL -->
	<p-dialog id="multiple-source_bids-modal" v-model:visible="show_multiple_bids_modal" modal style="max-width: 700px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="hand-coin-outline" size="24px" style="color: var(--color-b)" />
				<strong>Add Multiple Source Bid</strong>
			</div>
		</template>

		<template v-if="campaignStore.campaign.promotion_id && !campaignStore.promotion.allow_source_filtering">
			<p-message severity="error" :closable="false">
				The promotion attached to this campaign does not allow source filtering. Any source bids attempting to filter
				out a source will be ignored.
			</p-message>
		</template>
		<p>
			Enter a list of values to match against the source_id, sub_id, sub_hash, or source_tags. An asterisk
			(<code>*</code>) may be used as a wildcard.
		</p>
		<row>
			<column>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Match Values:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									ref="multiple_source_values"
									name="multiplesource_values"
									v-model="multiple_bid_values"
									input-id="custom-bid-value-input"
									placeholder="Enter multiple values"
									separator=","
									add-on-blur
									@blur="v$.multiple_bid_values.$touch"
								/>
								<div v-if="v$.multiple_bid_values.$errors.length" class="validation-error">
									{{ v$.multiple_bid_values.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</column>
		</row>

		<template #footer>
			<p-button text label="Cancel" @click="show_multiple_bids_modal = false" />
			<p-button label="Save Source Bids" icon="pi pi-check" @click="saveMultipleBids" />
		</template>
	</p-dialog>
	<!-- END MULTIPLE BIDS MODAL -->

	<!-- BUDGET MODAL -->
	<p-dialog id="budget-modal" v-model:visible="show_budget_modal" :modal="true" style="max-width: 960px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="bank" size="24px" style="color: var(--color-b)" />
				<strong>Campaign Budget</strong>
			</div>
		</template>
		<div v-if="v$.budget_conflict.$error" class="validation-error" style="height: 2.5em">
			{{ v$.budget_conflict.$errors[0].$message }}
		</div>
		<row>
			<column>
				<p-fieldset legend="Choose a Budget Type">
					<div v-if="v$.selected_budget.type.$error" class="validation-error">
						{{ v$.selected_budget.type.$errors[0].$message }}
					</div>
					<p>
						Select how you want to budget your purchases for this campaign &mdash; by number of purchases or by lead
						cost.
					</p>
					<div class="budget-types">
						<div
							v-for="option in budget_type_options"
							:class="['budget-option', { selected: selected_budget.type === option.value }]"
							@click="selected_budget.type = option.value"
						>
							<div class="description">
								<strong>{{ option.label }}</strong
								><br />
								<div class="sub-value">{{ option.description }}</div>
							</div>
							<div class="selected-icon">
								<icon v-if="selected_budget.type === option.value" type="check" size="24px" />
							</div>
						</div>
					</div>
				</p-fieldset>
			</column>
			<gutter size="20px" />
			<column>
				<p-fieldset legend="Choose a Time Period">
					<div v-if="v$.selected_budget.period.$error" class="validation-error">
						{{ v$.selected_budget.period.$errors[0].$message }}
					</div>
					<p>All time periods are based off the time zone selected for your account.</p>
					<div class="time-periods">
						<div
							v-for="option in period_options"
							:class="['budget-option', { selected: selected_budget.period === option.value }]"
							@click="selected_budget.period = option.value"
						>
							<div class="description">
								<strong>{{ option.label }}</strong
								><br />
								<div class="sub-value">{{ option.description }}</div>
							</div>
							<div class="selected-icon">
								<icon v-if="selected_budget.period === option.value" type="check" size="24px" />
							</div>
						</div>
					</div>
				</p-fieldset>
			</column>
		</row>
		<p-fieldset legend="Set a Limit">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<template v-if="selected_budget.type && selected_budget.period">
							<template v-if="selected_budget.type === 'spend'">
								<div class="field s">
									<p-input-currency v-model="selected_budget.limit" class="input-align-right" />
								</div>
								<gutter size="10px" />
								<div class="field caption">spend limit per {{ selected_budget.period }}</div>
							</template>
							<template v-else>
								<div class="field s">
									<p-input-number v-model="selected_budget.limit" class="input-align-right" />
								</div>
								<gutter size="10px" />
								<div class="field caption">purchase count limit per {{ selected_budget.period }}</div>
							</template>
						</template>
						<template v-else>
							<p-message :closable="false">Select a budget type and period to set a limit</p-message>
						</template>
					</div>
				</div>
			</div>
		</p-fieldset>
		<template #footer>
			<p-button text label="Cancel" @click="closeBudgetModal()" />
			<p-button label="Save Budget" icon="pi pi-check" @click="saveBudget()" />
		</template>
	</p-dialog>
	<!-- END BUDGET MODAL -->
</template>

<script lang="ts">
import shortHash from 'shorthash2';
import { cloneDeep, invert, sortBy } from 'lodash-es';
import { nextTick } from 'vue';
import { currency, numberFormat } from '@/lib/Filters';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import pOverlayPanel from 'primevue/overlaypanel';
import pSlider from 'primevue/slider';
import customBid from '@/components/widgets/CustomBid.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pInputPercentage from '@/components/forms/InputPercentage.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';
import { useAccountStore } from '@/stores/account';
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { ulid } from 'ulid';
import legacy_source_hashes from '@/lib/Data/legacy_source_hashes.json';
import legacy_sub_id_hashes from '@/lib/Data/legacy_sub_id_hashes.json';

const v3_source_hashes = invert(legacy_source_hashes);
const v3_sub_id_hashes = invert(legacy_sub_id_hashes);

export default {
	name: 'BidsContent',
	components: {
		customBid,
		deleteAction,
		pDialog,
		pMessage,
		pOverlayPanel,
		pSlider,
		pInputPercentage,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			show_error: false,
			error_message: null,
			custom_bid_id: null,
			custom_bid_value: null,
			multiple_bid_values: [],
			custom_bid: null,
			editing_bid: false,
			show_bid_modal: false,
			show_multiple_bids_modal: false,
			show_paused: false,
			show_budget_modal: false,
			budget_conflict: null,
			selected_budget: {
				period: null,
				type: null,
				limit: 0,
				enabled: true,
			},
			period_options: [
				{ label: 'Daily', value: 'day', description: '12AM to Midnight' },
				{ label: 'Weekly', value: 'week', description: 'Sunday to Saturday' },
				{
					label: 'Monthly',
					value: 'month',
					description: 'Calendar Month',
				},
			],
			budget_type_options: [
				{
					label: 'Spend',
					value: 'spend',
					description: 'Limit the amount of money that is spent by this campaign for the specified time period.',
				},
				{
					label: 'Purchase Count',
					value: 'purchase_count',
					description: 'Limit the amount of leads that are purchased by this campaign for the specified time period.',
				},
			],
		};
	},
	computed: {
		...mapStores(useAccountStore, useCampaignStore, useSessionStore),
		sortedCustomBids() {
			return sortBy(
				this.campaignStore.campaign.bids.filter((custom_bid) => custom_bid.type === 'source'),
				(custom_bid) => {
					return custom_bid.name;
				}
			);
		},
		sortedBudgets() {
			return sortBy(this.campaignStore.campaign.budgets, ['period', 'type']);
		},
		hasAccountBudgets() {
			let budgets = this.sessionStore.account.settings.budgets || [];
			if (!this.sessionStore.isAccountUser) {
				// this means admin's budgets will be pulled in...
				budgets = this.accountStore.account.settings.budgets || [];
			}

			budgets.filter((budget) => {
				return (
					budget.vertical_id === this.campaignStore.campaign.vertical_id_targeting &&
					budget.product === this.campaignStore.campaign.product_targeting &&
					budget.enabled
				);
			});

			return budgets.length > 0;
		},
		defaultBid() {
			return this.campaignStore.campaign.default_bid;
		},
		maximumBid() {
			return this.campaignStore.campaign.maximum_bid;
		},
	},
	watch: {
		defaultBid(new_value) {
			if (new_value > this.campaignStore.campaign.maximum_bid) {
				this.campaignStore.campaign.maximum_bid = new_value;
			}
		},
	},
	validations() {
		return {
			defaultBid: {
				aboveMinimum: helpers.withMessage('Your default bid must be above the minimum bid', (value) => {
					return this.campaignStore.minimumBid <= value;
				}),
				dataBidMax: helpers.withMessage('Data campaigns cannot bid above $75', (value) => {
					return this.campaignStore.campaign.product_targeting !== 'data' || value <= 75;
				}),
			},
			maximumBid: {
				isValid: helpers.withMessage('Your maximum bid must be greater than the minimum bid', (value) => {
					return !this.campaignStore.campaign.enable_maximum_bid || this.campaignStore.minimumBid <= value;
				}),
			},
			custom_bid_value: {
				required: helpers.withMessage('Please enter a source value to match against', required),
				isUnique: helpers.withMessage('A custom bid already exists that targets this source value', (value) => {
					return this.campaignStore.isUniqueCustomBid(value, 'source', this.custom_bid_id);
				}),
				$lazy: true,
			},
			multiple_bid_values: {
				minLength: helpers.withMessage('Please at least one source value to match against', minLength(1)),
				isUnique: helpers.withMessage('A custom bid already exists that targets one of the source values', (values) => {
					return values.every((value) => {
						return this.campaignStore.isUniqueCustomBid(value, 'source', this.custom_bid_id);
					});
				}),
				$lazy: true,
			},
			selected_budget: {
				period: {
					required: helpers.withMessage('A time period is required', required),
				},
				type: {
					required: helpers.withMessage('A budget type is required', required),
				},
			},
			budget_conflict: {
				conflict: helpers.withMessage('This configuration conflicts with an existing budget', (value) => {
					// Check if another budget exists with the same settings
					const existing_budget = this.campaignStore.campaign.budgets.find((budget) => {
						return (
							budget.type === this.selected_budget.type &&
							budget.period === this.selected_budget.period &&
							budget.id !== this.selected_budget.id
						);
					});

					return !existing_budget;
				}),
			},
			$validationGroups: {
				source_bid: ['custom_bid_value'],
				multiple_source_bids: ['multiple_bid_values'],
				budget: ['selected_budget.period', 'selected_budget.type', 'budget_conflict'],
			},
		};
	},
	methods: {
		currency,
		numberFormat,
		sourceDetails(value) {
			// If it is a v3 source hash
			if (v3_source_hashes[value]) {
				return [
					{
						label: 'Source ID',
						value: v3_source_hashes[value],
					},
					{
						label: 'V4 Hash',
						value: shortHash(v3_source_hashes[value]),
					},
					{
						label: 'V3 Hash',
						value,
					},
				];
			}

			// If it is a v3 sub id hash
			else if (v3_sub_id_hashes[value]) {
				return [
					{
						label: 'Sub ID',
						value: v3_sub_id_hashes[value],
					},
					{
						label: 'V4 Hash',
						value: 'Not convertible',
					},
					{
						label: 'V3 Hash',
						value,
					},
				];
			}

			// If it is a source id
			else if (/^[a-zA-Z0-9_]+$/.test(value)) {
				return [
					{
						label: 'Source ID',
						value,
					},
					{
						label: 'V4 Hash',
						value: shortHash(value),
					},
					{
						label: 'V3 Hash',
						value: legacy_source_hashes[value] || 'Not Found',
					},
				];
			}

			// If it is a sub id
			else {
				const hashed_source_id = value.substring(0, value.indexOf(':'));
				const sub_ids = value.substring(value.indexOf(':') + 1).split('|');
				const hashed_sub_ids = sub_ids.map((sub_id) => shortHash(sub_id)).join('|');
				return [
					{
						label: 'Sub ID',
						value,
					},
					{
						label: 'V4 Hash',
						value: `${hashed_source_id}-${hashed_sub_ids}`,
					},
					{
						label: 'V3 Hash',
						value: legacy_sub_id_hashes[value] || 'Not Found',
					},
				];
			}
		},
		toggleMinBidEditPanel(event) {
			this.$refs.min_bid_op.toggle(event);
		},
		resetModalState() {
			this.v$.$reset();
			this.custom_bid_id = null;
			this.custom_bid_value = '';
			this.custom_bid = {
				amount: this.campaignStore.campaign.default_bid,
				status: 'active',
			};
			this.multiple_bid_values = [];
		},
		addMultipleCustomBids() {
			this.resetModalState();
			this.show_multiple_bids_modal = true;
			nextTick(() => {
				this.$refs.multiple_source_values.$el.focus();
			});
		},
		editCustomBid(bid_id: string) {
			this.resetModalState();

			// Load the bid data
			const custom_bid = this.campaignStore.campaign.bids.find((custom_bid) => custom_bid.id === bid_id);
			if (custom_bid) {
				this.custom_bid_id = bid_id;
				this.custom_bid_value = custom_bid.match[0][0].comparator.value;
				this.custom_bid = cloneDeep(custom_bid);
			}

			this.show_bid_modal = true;
			nextTick(() => {
				this.$refs.source_value.$el.select();
			});
		},
		deleteCustomBid(bid_id: string) {
			return this.campaignStore.deleteBid(bid_id);
		},
		async saveBid() {
			await this.v$.$validate();
			if (!this.v$.$validationGroups.source_bid.$invalid) {
				const custom_bid = {
					...this.custom_bid,
					id: null,
					name: this.custom_bid_value,
					type: 'source',
					match: [
						[
							{
								target: {
									path: 'lead',
									transformer: 'sourceValues',
								},
								strategy: 'includes_match',
								comparator: {
									value: this.custom_bid_value,
									transformer: 'wildcard',
								},
							},
						],
					],
				};

				if (this.campaignStore.campaign.product_targeting === 'call') {
					custom_bid.custom_duration = this.custom_bid.custom_duration;
					custom_bid.duration = this.custom_bid.duration;
				}

				if (this.custom_bid_id) {
					custom_bid.id = this.custom_bid_id;
					this.campaignStore.saveBid(custom_bid);
				} else {
					this.campaignStore.addBid(custom_bid);
				}

				// Reset the modal state
				this.custom_bid_id = null;
				this.custom_bid_value = '';
				this.show_bid_modal = false;
			}
		},
		async saveMultipleBids() {
			await this.v$.$validate();
			if (!this.v$.$validationGroups.multiple_source_bids.$invalid) {
				this.multiple_bid_values.forEach((bid_value: string) => {
					const custom_bid = {
						id: null,
						name: bid_value.trim(),
						type: 'source',
						match: [
							[
								{
									target: {
										path: 'lead',
										transformer: 'sourceValues',
									},
									strategy: 'includes_match',
									comparator: {
										value: bid_value.trim(),
										transformer: 'wildcard',
									},
								},
							],
						],
						method: 'multiply',
						amount: 0,
						custom_minimum_bid: false,
						minimum_bid: 0,
						finalize: false,
						status: 'active',
						dq_message: 'Lead source excluded from targeting',
					};

					if (this.campaignStore.campaign.product_targeting === 'call') {
						custom_bid.custom_duration = false;
						custom_bid.duration = 0;
					}

					this.campaignStore.addBid(custom_bid);
				});

				// Reset the modal state
				this.multiple_bid_value = '';
				this.show_multiple_bids_modal = false;
			}
		},
		budgetDescription(budget) {
			return `Limit the ${budget.type === 'spend' ? 'campaign spend' : 'purchase count'} to ${budget.type === 'spend' ? currency(budget.limit) : numberFormat(budget.limit)} per ${budget.period}`;
		},
		async addBudget() {
			this.v$.$reset();
			this.selected_budget = {
				id: ulid(),
				period: 'day',
				type: 'spend',
				limit: 0,
				enabled: true,
			};
			this.show_budget_modal = true;
		},
		async editBudget(budget) {
			this.v$.$reset();
			this.selected_budget = cloneDeep(budget);
			this.show_budget_modal = true;
		},
		async saveBudget() {
			await this.v$.$validate();
			if (!this.v$.$validationGroups.budget.$invalid) {
				const matching_budget_index = this.campaignStore.campaign.budgets.findIndex((budget) => {
					return budget.id === this.selected_budget.id;
				});

				if (matching_budget_index > -1) {
					this.campaignStore.campaign.budgets.splice(matching_budget_index, 1, cloneDeep(this.selected_budget));
				} else {
					this.campaignStore.campaign.budgets.push(cloneDeep(this.selected_budget));
				}

				this.closeBudgetModal();
			}
		},
		closeBudgetModal() {
			this.selected_budget = {
				period: null,
				vertical_id: null,
				product: null,
				type: null,
				limit: 0,
				enabled: true,
			};
			this.show_budget_modal = false;
			this.v$.$reset();
		},
		async toggleBudget(budget) {
			budget.enabled = !budget.enabled;
		},
		async deleteBudget(budget_id) {
			const budget_index = this.campaignStore.campaign.budgets.findIndex((budget) => {
				return budget.id === budget_id;
			});
			if (budget_index > -1) {
				this.campaignStore.campaign.budgets.splice(budget_index, 1);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete budget',
					life: 3000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

.source-details {
	color: var(--gray-50);
	font-size: 0.75rem;
	margin-top: 0.25rem;

	.source-value {
		.label {
			float: left;
			min-width: 70px;
		}

		.label::after {
			content: ':';
			margin-right: 5px;
		}

		.value {
			// max-width: 130px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.p-fieldset-legend {
	align-items: center;
	display: flex;
	font-size: var(--font-size-base);
	font-weight: bold;
	margin-bottom: 1rem;
	place-content: center flex-start;

	img {
		height: 18px;
		margin-right: 0.25rem;
		width: 18px;
	}
}

.bid-section {
	border: 1px solid var(--gray-20);
	border-left: 5px solid var(--color-b);
	border-radius: 4px;
	box-shadow: 0 2px 0 var(--gray-10);
	font-size: var(--font-size-sm);
	margin-bottom: 1rem;

	.bid-row {
		align-items: center;
		border-bottom: 1px solid var(--gray-15);
		display: flex;
		flex-flow: row wrap;
		gap: 10px;
		justify-content: space-between;
		padding: 20px;
	}

	.default-bid-label {
		font-size: 1rem;
		margin-right: 10px;
	}

	.description {
		flex: 2 0;
		line-height: 1.5;

		span {
			color: var(--gray-50);
		}
	}

	.minimum-bid {
		margin-top: 0.5em;
	}

	.minimum-bid-edit {
		color: var(--color-b);
		cursor: pointer;
		margin-left: -8px;
		padding: 0.25em;
	}

	.minimum-bid,
	.maximum-bid {
		align-items: center;
		color: var(--gray-50);
		display: flex;
		flex: 1 0;
		flex-direction: row;
		gap: 10px;
		justify-content: flex-end;
	}

	.maximum-bid-row.disabled {
		background-color: var(--gray-05);

		.maximum-bid {
			color: var(--gray-35);
		}
	}

	.bid-input {
		max-width: 90px;
		min-width: 70px;
	}
}

.callout {
	background: rgb(74 144 226 / 15%) !important;
	border-radius: 8px;
	padding: 1rem;

	.header {
		align-items: center;
		display: flex;
		font-size: var(--font-size-base);
		font-weight: bold;
		margin-bottom: 1rem;
		place-content: center flex-start;

		img {
			height: 18px;
			margin-right: 0.5rem;
			width: 18px;
		}
	}
}

.adjust-source-bids {
	align-items: center;
	background: var(--yellow-light);
	border: 1px solid var(--yellow);
	display: flex;
	flex-flow: row wrap;
	font-size: var(--font-size-sm);
	gap: 1rem;
	justify-content: space-between;
	margin-top: 1rem;
	padding: 0.75rem;

	.header {
		align-items: center;
		display: flex;
		font-size: var(--font-size-base);
		font-weight: bold;
		place-content: center flex-start;

		img {
			height: 18px;
			margin-right: 0.5rem;
			width: 18px;
		}
	}
}

.budget {
	align-items: center;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	display: flex;
	gap: 20px;
	justify-content: space-between;
	margin-bottom: 1em;
	padding: 20px;

	.product {
		flex: 1 0;
		line-height: 1.125em;
	}

	&.paused {
		opacity: 0.5;
	}
}

#budget-modal {
	.row {
		align-items: stretch;

		.column .p-fieldset {
			height: 100%;
		}
	}

	.validation-error {
		margin-bottom: 1em;
	}

	.budget-option {
		align-items: flex-start;
		border: 1px solid var(--gray-20);
		border-radius: 3px;
		cursor: pointer;
		display: flex;
		line-height: 1.125em;
		margin-bottom: 1em;
		padding: 15px;

		&:last-of-type {
			margin-bottom: 0;
		}

		strong {
			line-height: 1.5em;
		}

		&.selected {
			background-color: var(--color-b-lightest);
			border: 2px solid var(--color-b-lighter);
		}

		.description {
			flex: 1 0;
		}

		.selected-icon {
			color: var(--color-b);
			margin-left: 10px;
			min-width: 24px;
		}
	}

	.time-periods .budget-option {
		.description {
			align-items: center;
			display: flex;
		}

		strong {
			flex: 0 0 80px;
		}
	}

	.p-message {
		margin: 0;
		width: 100%;
	}
}
</style>
