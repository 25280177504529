import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';
import type { CaptureConfig } from '@nextgenleads/lead-driver';

export async function getCaptureConfigs(filters?: QueryFilters, no_cache?: string[]): Promise<CaptureConfig[]> {
	const query = `
        query GetCaptureConfigs($filters: [[Any]]) {
            captureConfigs(filters: $filters) {
                id
				mpid
                name
                description
                vendor_id
                vertical_id
                product
                method
                source_ids
                build_steps
                status
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.captureConfigs;
}
