<template>
	<div class="settings-form">
		<h2>Account Settings</h2>
		<gutter size="20px" />
		<p-message severity="warn" :closable="false" v-if="!account.verified">
			This account is <strong>not</strong> verified.
			<span v-if="!$root.sessionStore.hasValidAccountPhone"
				>The daily spend will be limited to $150 per vertical and lead type.</span
			>
			<template v-if="$root.sessionStore.isAccountUser">
				Contact your Account Manager to request your account be verified.</template
			>
		</p-message>
		<div v-if="!account.verified && $root.sessionStore.isAdminUser">
			<p-button severity="warning" icon="pi pi-check" label="Verify Account" @click="verifyAccount" />
		</div>

		<gutter size="20px" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Company Name:</label>
				<div class="controls">
					<div class="field full">
						<p-input-text v-model="account.name" />
						<div v-if="v$.account.name.$error" class="validation-error">
							{{ v$.account.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="$root.sessionStore.isAdminUser" class="control-group">
			<div class="inner">
				<label class="control-label">Display Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="account.display_name" placeholder="Used in results of LTA auctions" />
					</div>
				</div>
			</div>
		</div>
		<template v-if="$root.sessionStore.isAdminUser">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="is-parent" v-model="account.is_parent" />
						</div>
						<div class="field">
							<label for="is-parent">This account is a parent</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Parent Account ID:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="account.parent_account_id" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Group IDs:</label>
					<div class="controls">
						<div class="field">
							<p-chips add-on-blur v-model="account.group_ids" placeholder="Group IDs must match exactly" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<p-fieldset legend="Contact Info">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label req">Phone:</label>
							<p-input-mask mask="(999) 999-9999" unmask :auto-clear="false" v-model="account.contact.phone" />
							<div v-if="v$.account.contact.phone.$error" class="validation-error">
								{{ v$.account.contact.phone.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label req">Email:</label>
							<p-input-text v-model="account.contact.email" />
							<div v-if="v$.account.contact.email.$error" class="validation-error">
								{{ v$.account.contact.email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">State:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="account.contact.state"
								:options="stateOptions"
								optionLabel="label"
								optionValue="value"
								placeholder="Select State"
							/>
							<div v-if="v$.account.contact.state.$error" class="validation-error">
								{{ v$.account.contact.state.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<gutter size="20px" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Timezone:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="account.settings.timezone"
							placeholder="Select Timezone"
							:options="timezoneOptions"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.account.settings.timezone.$error" class="validation-error">
							{{ v$.account.settings.timezone.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="$root.sessionStore.isAdminUser">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Tags:</label>
					<div class="controls">
						<div class="field">
							<p-chips add-on-blur v-model="account.tags" separator="," />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="account.status"
								:options="account_status_options"
								option-label="label"
								option-value="value"
								placeholder="Select status"
							/>
							<div v-if="v$.account.status.$error" class="validation-error">
								{{ v$.account.status.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="inner" v-if="account.status === 'away'">
				<label class="control-label" for="status">Date Of Return:</label>
				<div class="controls">
					<div class="field">
						<p-calendar v-model="date_of_return" date-format="D, M d, yy" :manual-input="false" />
					</div>
				</div>
			</div>
			<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="DNC CSV Tool">
				<p>Uploading a CSV will create DNC records for this account only.</p>
				<DNCCSVUpload :account_id="accountId" />
			</p-fieldset>
		</template>
		<div class="actions flex">
			<p-button label="Save" icon="pi pi-check" @click="updateAccount()" />
		</div>

		<p-confirm-dialog />
	</div>
</template>

<script>
import '@/lib/Utils/isDst';
import { cloneDeep, sortBy } from 'lodash-es';
import pChips from 'primevue/chips';
import pCalendar from 'primevue/calendar';
import STATES from '@/lib/Data/states.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import { account_status_options } from '@/lib/Options';
import { getAccountSettings, updateAccount } from '@GQL';
import { required, email, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import DNCCSVUpload from '@/views/DNCs/Record/CSVUploadModal.vue';
import pConfirmDialog from 'primevue/confirmdialog';

export default {
	name: 'AccountSettings',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		pChips,
		pCalendar,
		DNCCSVUpload,
		pConfirmDialog,
	},
	data() {
		return {
			account: {
				name: null,
				mpid: null,
				display_name: '',
				is_parent: false,
				parent_account_id: '',
				group_ids: [],
				contact: {
					state: null,
					phone: '',
					email: '',
				},
				settings: {
					timezone: null,
				},
				support: {},
				tags: [],
				status: 'active',
				verified: false,
			},
			date_of_return: new Date(),
			account_status_options,
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		timezoneOptions() {
			const is_dst = new Date().isDst();

			const timezones = TIMEZONES.map((tz) => {
				const offset = is_dst ? tz.offset_dst : tz.offset;
				return {
					label: `${tz.label} (${offset})`,
					value: tz.id,
					offset,
				};
			});

			return sortBy(timezones, (tz) => {
				return parseInt(tz.offset);
			});
		},
		stateOptions() {
			return STATES;
		},
	},
	validations() {
		return {
			account: {
				name: {
					required: helpers.withMessage('An account name is required', required),
				},
				contact: {
					phone: {
						required: helpers.withMessage('A contact phone is required', required),
						phone: helpers.withMessage('Please enter a valid phone number', (value) => {
							const phone_pattern = /^\d{10}$/;
							return phone_pattern.test(value);
						}),
					},
					email: {
						required: helpers.withMessage('A contact email is required', required),
						email: helpers.withMessage('Please enter a vlid email address', email),
					},
					state: {
						required: helpers.withMessage('A state is required', required),
					},
				},
				settings: {
					timezone: {
						required: helpers.withMessage('A timezone is required', required),
					},
				},
				status: {
					required: helpers.withMessage('The account status is required', required),
				},
			},
		};
	},
	async beforeMount() {
		const result = await getAccountSettings(this.accountId);
		delete result.account.settings.budgets; // removing extra data returned
		this.account = result.account;
		if (this.account.status === 'away') {
			this.date_of_return = new Date(this.account.support.date_of_return);
		}
		if (this.account.support.matching_accounts) {
			this.account.support.matching_accounts.forEach((matching_account) => {
				matching_account.created_at = new Date(matching_account.created_at);
			});
		}
	},
	methods: {
		verifyAccount() {
			this.$confirm.require({
				header: 'Verify Account',
				message: `Ideally you've confirmed the email address and payment methods of this account and have decided to remove all restrictions on this account`,
				accept: async () => {
					// set the verified_by field and update account
					this.account.verified = true;
					this.account.verified_by = this.$root.sessionStore.user.id;
					this.account.verified_at = new Date();
					this.account.settings.budgets = []; // CLEAR OUT THE ACCOUNT BUDGETS
					await this.updateAccount();
				},
			});
		},
		async updateAccount() {
			const new_account = cloneDeep(this.account);

			// Remove the parent_account_id
			if (new_account.parent_account_id === '') {
				new_account.parent_account_id = null;
			}

			const is_valid = await this.v$.$validate();

			if (is_valid) {
				if (new_account.status === 'away') {
					new_account.support.date_of_return = this.date_of_return;
				} else {
					delete new_account.support.date_of_return;
				}

				const account_result = await updateAccount(this.accountId, new_account);
				if (account_result.updateAccount.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved account settings',
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 3000,
					});
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'A required field must be missing',
					life: 60000,
				});
			}
		},
	},
};
</script>
