<template>
	<div class="settings-form" v-cloak>
		<h2>Conversions</h2>
		<p-fieldset v-if="!sessionStore.isAdminUser" legend="Update Conversions">
			<p>You can use the following options to update the dispositions of your leads...</p>
			<div class="flex aling-items-center gap-20">
				<ConversionCSVUploadModal />
				<ConvertByPhoneModal />
			</div>
		</p-fieldset>
		<p-fieldset legend="Conversion API Key">
			<gutter v-if="!existing_key" size="10px" />
			<p-button
				v-if="!existing_key && sessionStore.isAdminUser"
				label="Create a conversion API Key"
				icon="pi pi-plus"
				@click="createConversionKey()"
			/>
			<p-message v-if="!existing_key && !sessionStore.isAdminUser" severity="info" :closable="false"
				>Please contact an administrator to set up a Conversion API Key</p-message
			>
			<div v-if="existing_key" class="existing-key">
				<div class="flex justify-content-between">
					<div class="key">
						API Key:
						<div class="key-value">{{ apiKey.key }}</div>
					</div>
					<div v-if="sessionStore.isAdminUser" class="toolset">
						<div class="tool">
							<p-button
								v-if="apiKey.status === 'active'"
								text
								v-tooltip.top="'Disable Key'"
								aria-label="Disable Key"
								@click="disableKey(apiKey.id)"
							>
								<template #icon>
									<icon type="close-circle-outline" size="16px" />
								</template>
							</p-button>
							<p-button
								v-if="apiKey.status === 'disabled'"
								text
								v-tooltip.top="'Activate Key'"
								aria-label="Activate Key"
								@click="activateKey(apiKey.id)"
							>
								<template #icon>
									<icon type="play-circle-outline" size="16px" />
								</template>
							</p-button>
						</div>
					</div>
				</div>
			</div>
			<gutter size="20px" />
			<div style="margin-bottom: 0.5em"><strong>Instructions</strong></div>
			<p>
				To update the disposition on one of your leads, simply make an HTTP GET or POST request to the endpoint above
				with the querystring containing your personalized API key and the 9-character NextGen Leads ID for the lead
				(#-####-####). Data Values ENDPOINT URL: https://api.nextgenleads.app/conversion
			</p>
			<gutter size="20px" />
			<h3><strong>Data Values</strong></h3>
			<div class="data-point">
				<div class="label">Endpoint URL:</div>
				<div class="value">https://api.nextgenleads.app/conversion</div>
			</div>
			<div class="data-point">
				<div class="label">Request Method:</div>
				<div class="value">GET or POST</div>
			</div>
			<div v-if="apiKey.key" class="data-point">
				<div class="label">API-Key:</div>
				<div class="value">{{ apiKey.key }}</div>
			</div>
			<div v-if="apiKey.key" class="data-point">
				<div class="label">Querystring Format:</div>
				<div class="value"><strong>api_key</strong>={{ apiKey.key }}&amp;<strong>id</strong>=LEAD_ID</div>
			</div>
			<div v-if="apiKey.key" class="data-point">
				<div class="label">Example Request String</div>
				<div class="value">
					POST https://api.nextgenleads.com/v3/leads/convert?api_key={{ apiKey.key }}&id=ABCD-1234
				</div>
			</div>
		</p-fieldset>
	</div>
</template>

<script lang="ts">
import { insertAPIKey, updateAPIKey, getAPIKeys } from '@/lib/GQL';
import { QueryFilters } from '@nextgenleads/db';
import log from '@/lib/Log';
import type { APIKey } from '@nextgenleads/auth-driver';
import { useVuelidate } from '@vuelidate/core';
import { useSessionStore } from '@/stores/session';
import { ulid } from 'ulid';
import pMessage from 'primevue/message';
import ConversionCSVUploadModal from '@/views/Reports/AccountReports/ConversionsBySource/Modals/CSVUploadModal.vue';
import ConvertByPhoneModal from '@/views/Reports/AccountReports/ConversionsBySource/Modals/ConvertByPhoneModal.vue';

export default {
	name: 'ConversionSettings',
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	components: {
		pMessage,
		ConversionCSVUploadModal,
		ConvertByPhoneModal,
	},
	data() {
		return {
			existing_key: false,
			apiKey: {} as APIKey,
			test_key: {
				mpid: this.mpid,
				owner_id: this.account_id,
				role_id: 'lead_conversion',
				whitelisted_ips: ['.*'],
				allowed_request_methods: ['POST'],
				data: {},
				status: 'active',
			},
		};
	},
	methods: {
		async getConversionKey() {
			const filters: QueryFilters = [
				[
					{
						field: 'role_id',
						op: 'eq',
						value: 'lead_conversion',
					},
				],
			];
			try {
				const result = await getAPIKeys(filters);
				if (result.length === 1) {
					this.existing_key = true;
					this.apiKey = result[0];
				}
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error getting key',
					life: 3000,
				});
			}
		},
		async createConversionKey() {
			const new_key_params: Omit<APIKey, 'key' | 'created_at'> = {
				id: ulid(),
				mpid: this.mpid,
				owner_id: this.account_id,
				role_id: 'lead_conversion',
				whitelisted_ips: ['.*'],
				allowed_request_methods: ['POST'],
				data: {},
				status: 'active',
			};
			try {
				const result = await insertAPIKey(new_key_params);
				this.apiKey = result;
				this.existing_key = true;
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully created Conversion API Key',
					life: 3000,
				});
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to create Conversion API Key',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
		},
		async disableKey(id: string) {
			try {
				const result = await updateAPIKey(id, {
					status: 'disabled',
				});
				this.apiKey.status = 'disabled';
				this.$toast.add({
					severity: 'success',
					summary: 'Disabled Conversion Key',
					detail: `ID: ${result.id}`,
					life: 3000,
				});
			} catch (err) {
				log.trace(err);
			}
		},
		async activateKey(id: string) {
			try {
				const result = await updateAPIKey(id, {
					status: 'active',
				});
				this.apiKey.status = 'active';
				this.$toast.add({
					severity: 'success',
					summary: 'Activated Conversion Key',
					detail: `ID: ${result.id}`,
					life: 3000,
				});
			} catch (err) {
				log.trace(err);
			}
		},
	},
	mounted() {
		this.getConversionKey();
	},
	computed: {
		account_id() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
		mpid() {
			return this.$route.params.mpid ? this.$route.params.mpid : this.$root.appStore.mpid;
		},
	},
};
</script>

<style lang="less" scoped>
.existing-key {
	border: 1px solid var(--gray-15);
	border-radius: 3px;
	box-shadow: 0 2px 0 var(--gray-10);
	font-size: var(--font-size-sm);
	line-height: 1.5;
	padding: 1rem;
}

.key-value {
	color: var(--orange-500);
}

.data-point {
	margin-bottom: 0.5rem;

	.label {
		color: var(--gray-50);
		font-size: var(--font-size-sm);
	}

	.value {
		color: var(--orange-500);
		margin: 0.5rem 0 1rem;
		background-color: var(--gray-05);
		border: 1px solid var(--gray-10);
		border-radius: 3px;
		display: inline-block;
		font-family: var(--font-mono);
		font-size: var(--font-size-sm);
		padding: 3px 8px;
	}
}
</style>
