<template>
	<div id="account-manager-workstation-wrapper">
		<row>
			<div class="page-heading">
				<h1>Account Manager Workstation {{ manager_name !== '' ? `: ${manager_name}` : '' }}</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active_tab" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)" class="account-tab">
							<router-link :to="`${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
					<template v-else>
						<div
							v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)"
							class="account-tab"
							@click.stop
						>
							<div class="tab-submenu-wrapper" @click="toggleSubMenu($event, item.ref)">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
								<div class="tab-submenu-toggle">
									<alt-icon type="expand_more" />
								</div>
							</div>
							<p-menu :ref="item.ref" class="account-submenu" :model="item.items" :popup="true">
								<template #item="{ item }">
									<template v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)">
										<router-link :to="`/${item.route}`">
											<div class="tab-label">
												{{ item.label }}
											</div>
										</router-link>
									</template>
								</template>
							</p-menu>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="amw-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script lang="ts">
import pTabMenu from 'primevue/tabmenu';
import pMenu from 'primevue/menu';
import { getUser } from '@GQL';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'AccountManagerWorkstationWrapper',
	components: {
		pTabMenu,
		pMenu,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			active_tab: 0,
			tabs: [
				{
					label: 'New Accounts',
					icon: 'insert_chart',
					route: 'pending-follow-ups',
				},
				{
					label: 'Existing Accounts',
					icon: 'insert_chart',
					route: 'existing-accounts',
				},
				{
					label: 'Recent Sign Ups',
					icon: 'insert_chart',
					route: 'recent-signups',
				},
			],
			breadcrumb_items: [],
			manager_name: '',
		};
	},
	computed: {},
	watch: {
		$route: {
			handler(new_value) {
				this.active_tab = this.tabs.findIndex((tab) => {
					if (tab.items) {
						return tab.items.some((sub_item) => {
							return new_value.path.indexOf(sub_item.route) > 0;
						});
					}
					return new_value.path.indexOf(tab.route) > 0;
				});
			},
			immediate: true,
		},
	},
	async mounted() {
		// get the account info for name etc
		let manager_id = this.sessionStore.user.id;
		if ('account_manager_id' in this.$route.params) {
			manager_id = this.$route.params.account_manager_Id;
			const user = await getUser(this.$route.params.account_manager_id);
			this.manager_name = user.first_name + ' ' + user.last_name;
		} else {
			this.manager_name = this.sessionStore.user.first_name + ' ' + this.sessionStore.user.last_name;
		}

		this.breadcrumb_items.push(
			{
				label: 'AMW',
				route: '/reports/amw-overview',
			},
			{
				label: this.manager_name,
			}
		);
	},
	methods: {
		toggleSubMenu(event, sub_menu_ref) {
			this.$refs[sub_menu_ref].toggle(event);
		},
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#account-manager-workstation-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.amw-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 30%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 40px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.amw-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}
</style>

<style lang="less">
.account-submenu {
	width: 240px;

	a {
		align-items: center;
		color: var(--gray-50);
		display: flex;
		height: 40px;
		line-height: 40px;
		padding: 0 1.5em;

		&:hover {
			text-decoration: none;
		}
	}
}
</style>
