<template>
	<div class="view-content">
		<div id="privacy-policy">
			<h1>PRIVACY POLICY</h1>
			<h2>1. Introduction</h2>
			<p>
				This Privacy Policy describes the information practices of NextGen Leads, LLC. (“NextGen”) on the NextGen
				website at <a href="https://www.nextgenleads.com">https://www.nextgenleads.com</a> (along with all subdomains,
				collectively, the “Site”). This Privacy Policy is only applicable to the Site and not to any other websites that
				you may be able to access from the Site, each of which may have data collection, storage, and use practices and
				policies that differ materially from this Privacy Policy.
			</p>
			<h2>2. Information Collection Practices</h2>
			<h3>Types of Information Collected</h3>
			<p>
				<strong>
					No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All
					other categories exclude text messaging originator opt-in data and consent; this information will not be
					shared with any third parties.
				</strong>
			</p>
			<p>
				Personal Information Collected. In order for you to access and purchase data offered via the Site, we may
				require you to provide information that personally identifies you (“Personal Information”). Personal Information
				may include the following categories of information: (1) Contact Data (such as your name, title, company or
				organization name, mailing address, and e-mail address); (2) Financial Data (such as your account or credit card
				number); and (3) Demographic Data (such as your zip code and age). If you communicate with us by email, post
				messages to any of our chat groups, bulletin boards, or forums, or otherwise complete online forms, surveys, or
				contest entries, any information provided may be collected as Personal Information.
			</p>
			<p>
				Traffic Data Collected. We may automatically track and collect the following categories of information when you
				visit our Site: (1) IP addresses; (2) domain servers; (3) types of computers accessing the Site; and (4) types
				of web browsers used to access the Site (collectively “Traffic Data”). Traffic Data is anonymous information
				that does not personally identify you but is helpful for improving your experience on the Site.
			</p>
			<h3>Uses of Information Collected</h3>
			<p>
				We may use your Personal Information to send you information about our company and our products or services or
				to contact you when necessary. We may also share your Personal Information with our partners who may contact you
				about goods and services they offer. We use your Financial Data only to verify your qualifications for certain
				data or services and to bill you for data and services. We do not use your Financial Data for any other purpose.
			</p>
			<p>
				<strong>
					No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All
					other categories exclude text messaging originator opt-in data and consent; this information will not be
					shared with any third parties.
				</strong>
			</p>
			<h3>Cookies and Web Beacons</h3>
			<p>
				Cookies and web beacons are small text files that are stored on your computer when you visit certain web pages.
				NextGen collects information through cookies and web beacons about your web browsing activities such as the
				address of the page you are visiting, the address of the referrer page you previously visited, the time you are
				viewing the page, your browsing environment, and your display settings. We may use cookies and web beacons on
				our Site for the following purposes:
			</p>
			<ul role="list">
				<li>
					Understanding traffic patterns and the number of visitors to the Site and other websites that we partner with;
				</li>
				<li>Understanding how you use and interact with the Site;</li>
				<li>Improving NextGen products and services;</li>
				<li>Optimizing your experience on the Site;</li>
				<li>
					Providing anonymous individual or aggregate auditing, research, modeling, and reporting for our advertisers
					and other partners;
				</li>
				<li>Storing your password so you do not have to re-enter it each time you visit the Site;</li>
				<li>Providing you with relevant advertising and content; and</li>
				<li>Managing the Site.</li>
			</ul>
			<p>
				Please note that cookies may be placed by a third party service provider who performs these functions for us.
				These third parties may have their own privacy policies.
			</p>
			<h3>Sharing of Personal Information</h3>
			<p>
				We may share Personal Information with advertisers and other third parties. The other parties with whom we share
				Personal Information may send information to you about products or services. We do not share your Financial Data
				with these third parties. We may share Financial Data with business partners who assist us by performing core
				services (such as hosting, billing, fulfillment, or data storage and security) related to our operation of the
				Site. Those business partners agree to uphold the same standards of security and confidentiality described in
				this Privacy Policy, and they will only use your Financial Data to carry out their specific business obligations
				to NextGen.
			</p>
			<p>
				If you do not want us to share your Personal Information with any third parties, please email us at
				<a href="mailto:privacy@nextgenleads.com">privacy@nextgenleads.com</a> or select the “opt out” box on our online
				forms. In addition, we maintain a procedure for you to review and request changes to your Personal Information,
				described below.
			</p>
			<p>
				<strong>
					No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All
					other categories exclude text messaging originator opt-in data and consent; this information will not be
					shared with any third parties.
				</strong>
			</p>
			<h3>Internet Based Ads</h3>
			<p>
				We may also share Traffic Data with advertisers and other third parties who may use it for advertising purposes,
				including to serve targeted advertising on non-affiliated third party sites. Some of those ads may be
				personalized, meaning that they are intended to be relevant to you based on information about your online
				activities on the Site or other websites over time. For example, anonymous information collected across multiple
				sites may enable the ad network to predict your preferences and show you ads that are likely to be of interest
				to you. Please note that we do not share any information that identifies you personally with the third party
				service providers who serve ads on our behalf. To opt out of these types of ads, please visit the Network
				Advertising Initiative for information about how to opt-out of interest-based advertising by their members. See
				<a href="https://www.networkadvertising.org/">www.networkadvertising.org</a> for general information about the
				NAI and
				<a href="https://www.networkadvertising.org/managing/opt_out.asp"
					>www.networkadvertising.org/managing/opt_out.asp</a
				>
				for the opt-out page. You may also visit
				<a href="http://www.aboutads.info/consumers/">www.aboutads.info/consumers/</a> to learn about interest based
				advertising and how to opt-out from online behavioral ads served by some or all participating companies.
			</p>
			<p>
				If we or some or all of our assets are acquired by another company, including through a sale in connection with
				a bankruptcy, that company will possess the information collected by us, and it will assume the rights and
				obligations regarding your Personal Information as described in this Privacy Policy.
			</p>
			<p>
				We may disclose Personal Information or other data to comply with law or legal requirements, enforce or apply
				our Terms of Use and other agreements, or protect our rights, property, the safety of our users, or others.
			</p>
			<h3>Email Marketing</h3>
			<p>
				By submitting your email address via the Site, you are expressly consenting to receive emails from NextGen,
				including from NextGen’s affiliates, and from third parties concerning offers and advertisements unrelated to
				NextGen. To opt-out of receiving email messages from us, from our affiliates or from other third parties, click
				on the “Unsubscribe” link contained in each email.
			</p>
			<h3>Contests, Sweepstakes, Surveys</h3>
			<p>
				NextGen may conduct or co-sponsor contests, sweepstakes, or other offerings. In such circumstances, the
				information we obtain from you may be shared with our co-sponsor. Our co-sponsors will have the right to use
				your information for their own purposes, in accordance with their own privacy policies. If you do not wish for
				the information we obtain from you to be shared with our co-sponsor, please do not participate in these
				offerings.
			</p>
			<h2>3. Confidentiality and Security of Personal Information</h2>
			<h3>User Ability to Access, Update, and Correct Personal Information</h3>
			<p>
				At any time you may contact NextGen via email at
				<a href="mailto:privacy@nextgenleads.com">privacy@nextgenleads.com</a> to update your Personal Information or
				request that your Personal Information no longer be shared by NextGen. In the case that your credit card, user
				name, or password is lost, stolen, or used without permission, promptly contact NextGen at
				privacy@nextgenleads.com to update the Personal Information we have on file for you.
			</p>
			<h3>Your California Privacy Rights</h3>
			<p>
				NextGen may make your Personal Information available to third parties for their marketing purposes as described
				in this Privacy Policy. If you do not wish to share your Personal Information in this way, send your request to
				<a href="mailto:unsubscribe@nextgenleads.com">unsubscribe@nextgenleads.com</a>.
			</p>
			<h3>Security</h3>
			<p>
				NextGen uses reasonable security measures to protect against the loss, misuse, and alteration of the information
				under NextGen’s control.
			</p>
			<h2>4. Public Information</h2>
			<p>
				The Site contains links to websites not operated by NextGen. We are not responsible for the privacy practices or
				the content of such websites. Please review the privacy policies of each site you visit. We may also make chat
				rooms, forums, message boards, and news groups available to you. Please understand that any information that is
				disclosed in these areas becomes accessible to the public. You should exercise caution when deciding to disclose
				your Personal Information in a public forum.
			</p>
			<h2>5. Updates and Changes to Privacy Policy</h2>
			<p>
				We reserve the right, at any time and without notice, to update or modify this Privacy Policy, simply by posting
				such update or modification on the Site. Any such change, update, or modification will be effective immediately
				upon posting on the Site. Your consent to such changes through your continued use of the Site.
			</p>
			<p>
				If you have questions about this Privacy Policy or want to contact us, please send an email to
				<a href="mailto:privacy@nextgenleads.com">privacy@nextgenleads.com</a> or write to us at:
			</p>
			<p>NextGen Leads LLC<br />701 B Street, Suite 1255<br />San Diego, CA 92101</p>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'Privacy-Policy',
	data() {},
};
</script>

<style lang="less" scoped>
#privacy-policy {
	margin: 0 auto;
	max-width: 800px;
}

h1 {
	font-size: var(--font-size-h2);
	margin: 2rem 0;
}

h2 {
	font-size: var(--font-size-h4);
}

h3 {
	font-size: var(--font-size-sm);
	font-weight: bold;
}

p {
	font-size: clamp(0.8rem, 0.15vw + 0.7rem, 0.85rem);
	line-height: 1.5;
}

ul {
	list-style: disc;
	padding-left: 1.5rem;

	li {
		font-size: clamp(0.75rem, 0.15vw + 0.7rem, 0.8rem);
		line-height: 1.5;
		margin-bottom: 0.25rem;
	}
}
</style>
