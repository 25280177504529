<template>
	<p-card class="email-template-card">
		<template #content>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Email Template Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="local_email_template.name" />
							<div v-if="v$.local_email_template.name.$error" class="validation-error">
								{{ v$.local_email_template.name.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Email Template Subject:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="local_email_template.subject" />
							<div v-if="v$.local_email_template.subject.$error" class="validation-error">
								{{ v$.local_email_template.subject.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label req" for="from_name">From Name:</label>
							<p-input-text
								id="from-name"
								v-model="local_email_template.from_name"
								class="p-inputtext"
								placeholder="First"
							/>
							<div v-if="v$.local_email_template.from_name.$error" class="validation-error">
								{{ v$.local_email_template.from_name.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label req" for="from_email">From Email:</label>
							<p-input-text
								id="from-email"
								v-model="local_email_template.from_email"
								class="p-inputtext"
								placeholder="Last"
							/>
							<div v-if="v$.local_email_template.from_email.$error" class="validation-error">
								{{ v$.local_email_template.from_email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label req" for="reply-to-name">Reply-To Name:</label>
							<p-input-text
								id="reply-to-name"
								v-model="local_email_template.reply_to_name"
								class="p-inputtext"
								placeholder="First"
							/>
							<div v-if="v$.local_email_template.reply_to_name.$error" class="validation-error">
								{{ v$.local_email_template.reply_to_name.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label req" for="reply-to-email">Reply-To Email:</label>
							<p-input-text
								id="reply-to-email"
								v-model="local_email_template.reply_to_email"
								class="p-inputtext"
								placeholder="Last"
							/>
							<div v-if="v$.local_email_template.reply_to_email.$error" class="validation-error">
								{{ v$.local_email_template.reply_to_email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label" for="cc">CC:</label>
							<p-chips
								add-on-blur
								v-model="local_email_template.cc"
								separator=","
								placeholder="Separate by comma or by hitting enter"
							/>
						</div>
						<div class="field">
							<label class="control-label" for="bcc">BCC:</label>
							<p-chips
								add-on-blur
								v-model="local_email_template.bcc"
								separator=","
								placeholder="Separate by comma or by hitting enter"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="body">Body:</label>
					<div class="controls">
						<div class="field">
							<code-editor
								id="body"
								v-model="local_email_template.body"
								:settings="{ mode: 'html', theme: 'sqlserver', wrap: true }"
							/>
						</div>
					</div>
					<div v-if="v$.local_email_template.body.$error" class="validation-error">
						{{ v$.local_email_template.body.$errors[0].$message }}
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label" for="wrapper-id">Wrapper ID:</label>
							<p-input-text
								id="wrapper-id"
								v-model="local_email_template.wrapper_id"
								class="p-inputtext"
								placeholder="Wrapper ID"
							/>
							<div class="sub-value">SendGrid ID of the template wrapper to use with this email</div>
						</div>
						<div class="field">
							<label class="control-label" for="asm-group-id">ASM Group ID:</label>
							<p-input-number
								id="asm-group-id"
								:useGrouping="false"
								v-model="local_email_template.asm_group_id"
								placeholder="Group ID"
							/>
							<div class="sub-value">The subscription group of the email template from the email provider</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label for="throttle">Throttle:</label>
							<div class="faded">The amount of days that must pass to receive this email again</div>
						</div>
						<div class="field max-w-6rem">
							<p-input-number id="throttle" v-model="local_email_template.throttle" placeholder="Days" />
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="local_email_template.bypass_unsubscribes" />
						</div>
						<gutter size="10px" />
						<div class="field caption">
							<span class="label">Bypass Unsubscribes</span>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<label class="control-label" for="tags">Tags:</label>
					<div class="controls">
						<div class="field">
							<p-chips
								add-on-blur
								id="tags"
								v-model="local_email_template.tags"
								separator=","
								placeholder="Separate by comma or by hitting enter"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="show_pdf_template" />
						</div>
						<gutter size="10px" />
						<div class="field caption">
							<span class="label">Attach a PDF Template</span>
						</div>
					</div>
				</div>
			</div>

			<div v-if="show_pdf_template" class="control-group">
				<div class="inner">
					<label class="control-label req">PDF filename:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="local_email_template.pdf_name" />
							<div v-if="v$.local_email_template.pdf_name.$error" class="validation-error">
								{{ v$.local_email_template.pdf_name.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="show_pdf_template" class="control-group">
				<div class="inner">
					<label class="control-label req" for="body">PDF Body (create a PDF from HTML):</label>
					<div class="controls">
						<div class="field">
							<code-editor
								id="body"
								v-model="local_email_template.pdf_content"
								:settings="{ mode: 'html', theme: 'sqlserver', wrap: true }"
							/>
							<div v-if="v$.local_email_template.pdf_content.$error" class="validation-error">
								{{ v$.local_email_template.pdf_content.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="local_email_template.status"
								:options="status_options"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
			</div>
			<gutter size="20px" />
			<p-dialog
				v-model:visible="test_modal_open"
				class="send-email-modal"
				:modal="true"
				:dismissable-mask="true"
				:style="{ width: '80vw' }"
			>
				<template #header>
					<div class="flex gap-2 align-content-center align-items-center">
						<icon type="send" size="24px" style="color: var(--color-b)" />
						<strong>Test Email Template</strong>
					</div>
				</template>
				<p>Remember to save your template to see changes</p>
				<div class="sub-value">Template ID: {{ this.$route.params.email_template_id }}</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="recipients">Recipients:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="recipients" v-model.parse="recipients" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="mapper-params">Mapper Params:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="mapper-params" v-model.parse="mapper_params" />
							</div>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="flex justify-content-end">
						<p-button label="Cancel" text @click="test_modal_open = false" />
						<p-button label="Send Email" @click="sendEmailTemplate()" />
					</div>
				</template>
			</p-dialog>
		</template>
		<template #footer>
			<div class="flex justify-content-between w-full flex-wrap gap-4">
				<p-button label="Cancel" text @click="$router.back()" />
				<div class="actions flex gap-3 flex-wrap justify-content-end">
					<p-button v-if="$route.params.email_template_id" label="Test Template" @click="test_modal_open = true">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="send" size="24px" />
							</div>
						</template>
					</p-button>
					<p-button label="Save Template" @click="saveEmailTemplate">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="check" size="24px" />
							</div>
						</template>
					</p-button>
				</div>
			</div>
		</template>
	</p-card>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { insertEmailTemplate, updateEmailTemplate, sendEmail } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import customUpload from '@/components/forms/CustomUpload.vue';
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';

const status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Archived', value: 'archived' },
];

export default {
	name: 'EmailTemplateForm',
	components: {
		customUpload,
		pChips,
		pDialog,
		pMessage,
	},
	props: {
		template: {
			type: Object,
			default() {
				return {
					id: null,
				};
			},
		},
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			test_modal_open: false,
			recipients: [
				{
					name: 'Developer Test',
					email: 'developers@nextgenleads.com',
				},
			],
			status_options,
			mapper_params: {
				token: 'test',
			},
			local_email_template: {
				id: '',
				name: '',
				subject: '',
				from_name: '',
				from_email: '',
				reply_to_name: '',
				reply_to_email: '',
				cc: null,
				bcc: null,
				body: '',
				throttle: null,
				wrapper_id: null,
				asm_group_id: null,
				bypass_unsubscribes: false,
				pdf_name: null,
				pdf_content: null,
				tags: null,
				status: 'active',
			},
			show_pdf_template: false,
		};
	},
	watch: {
		template(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				this.local_email_template = Object.assign(this.local_email_template, new_value);
				if (this.local_email_template.pdf_name || this.local_email_template.pdf_content) {
					this.show_pdf_template = true;
				}
			}
		},
		show_pdf_template(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				if (this.show_pdf_template === false) {
					this.local_email_template.pdf_content = null;
					this.local_email_template.pdf_name = null;
				}
			}
		},
	},
	methods: {
		async sendEmailTemplate() {
			const email_params = {
				recipients: this.recipients,
				template: {
					id: this.$route.params.email_template_id,
					data: this.mapper_params,
				},
				sender_user_id: this.$root.sessionStore.user.id,
			};
			try {
				const result = await sendEmail(email_params);
				this.$toast.add({
					severity: 'success',
					summary: 'Sent Email',
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error Sending Email',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
		},
		async saveEmailTemplate() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) {
				setTimeout(() => {
					document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
				}, 100);
			} else {
				try {
					let save_result;
					if (this.local_email_template.id) {
						save_result = await updateEmailTemplate(this.local_email_template.id, this.local_email_template);
					} else {
						save_result = await insertEmailTemplate(this.local_email_template);
					}

					this.$toast.add({
						severity: 'success',
						summary: 'Email Template Saved',
						life: 5000,
					});
					this.$router.back();
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the email template',
						message: err,
						life: 6000,
					});
				}
			}
		},
	},
	validations() {
		return {
			local_email_template: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				subject: {
					required: helpers.withMessage('A subject is required.', required),
				},
				from_name: {
					required: helpers.withMessage('A from name is required.', required),
				},
				from_email: {
					required: helpers.withMessage('A from email is required.', required),
				},
				reply_to_name: {
					required: helpers.withMessage('A reply-to name is required.', required),
				},
				reply_to_email: {
					required: helpers.withMessage('A reply-to email is required.', required),
				},
				body: {
					required: helpers.withMessage('Email body is required.', required),
				},
				pdf_name: {
					requiredIf: helpers.withMessage(
						'A PDF filename is required if you are attaching a pdf.',
						requiredIf(this.show_pdf_template)
					),
				},
				pdf_content: {
					requiredIf: helpers.withMessage(
						'A PDF HTML template is required if you are attaching a pdf.',
						requiredIf(this.show_pdf_template)
					),
				},
				status: {
					require: helpers.withMessage('Status is required', required),
				},
			},
		};
	},
};
</script>

<style lang="less" scoped>
.email-template-card {
	max-width: 960px;
}
</style>
