import { $GQL } from '@GQL/index';

export async function getAccountById(id: string, refresh?: boolean): Promise<Partial<Account>> {
	const query = `
        query GetAccountById($id: ID) {
            account(id: $id) {
                support {
                  account_manager_id
                  status
                  category
                  date_of_return
                }
                settings {
                  timezone
				  allow_advanced_mode
				  budgets {
					enabled
					vertical_id
					product
					type
					limit
					period
				  }
				  usha {
					agent_id
					enable_validation
				  }
				  bulk_returns {
				  	enabled
				  }
                }
                tags
                status
                primary_user_id
                contact {
                  state
                  phone
                  email
                }
                created_by
                display_name
                group_ids
                id
                signup_source {
                  source_id
                  internal_referral
                }
                mpid
                name
                internal
                verified
                is_parent
                parent_account_id
              }
        }
    `;

	const no_cache = refresh ? ['account'] : undefined;
	const result = await $GQL.request(query, { id, no_cache });
	return result.account;
}
