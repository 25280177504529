import { $GQL } from '@GQL/index';
import { type Account } from '@nextgenleads/marketplace-driver';

export async function getAccountSettings(account_id: string, refresh?: boolean): Promise<Partial<Account>> {
	const request = `
			query GetAccountSettings($account_id: ID!) {
				account(id: $account_id) {
					name
					mpid
					display_name
					is_parent
					parent_account_id
					group_ids
					verified
					contact {
						state
						phone
						email
					}
					settings {
						timezone
						budgets {
							period
							vertical_id
							product
							type
							limit
							enabled
						}
						usha {
							agent_id
							enable_validation
						}
					}
					support {
						account_manager_id
						status
						category
						date_of_return
						matching_accounts {
							id
							name
							account_manager_id
							created_at
						}
					}
					tags
					status
				}
			}
		`;

	const no_cache = refresh ? ['account'] : undefined;
	return await $GQL.request(request, { account_id, no_cache });
}
