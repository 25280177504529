import AccountOverview from '@/views/Reports/AccountReports/Overview/Page.vue';
import MarketplaceOverview from '@/views/Reports/MarketplaceReports/Overview/Page.vue';
import MarketplaceDataLeads from '@/views/Reports/MarketplaceReports/Data/Page.vue';
import MarketplaceCalls from '@/views/Reports/MarketplaceReports/Calls/Page.vue';
import MarketplaceLiveTransfers from '@/views/Reports/MarketplaceReports/LiveTransfers/Page.vue';
import AccountAllLeads from '@/views/Reports/AccountReports/All/Page.vue';
import AccountActivity from '@/views/Reports/AccountReports/Overview/AccountActivity.vue';
import AccountPerformance from '@/views/Reports/AccountReports/Overview/AccountPerformance.vue';
import AccountDataLeads from '@/views/Reports/AccountReports/Data/Page.vue';
import AccountCallLeads from '@/views/Reports/AccountReports/Calls/Page.vue';
import AccountLiveTransfers from '@/views/Reports/AccountReports/LiveTransfers/Page.vue';
import { useSessionStore } from '@/stores/session';
import { useAppStore } from '@/stores/app';
import type { RouteLocation } from 'vue-router';
import AccountConversionsBySourceReport from '@/views/Reports/AccountReports/ConversionsBySource/Page.vue';
import MarketplaceConversionsBySourceReport from '@/views/Reports/MarketplaceReports/ConversionsBySource/Page.vue';
import LegacyV3PurchasesPage from '@/views/LegacyData/Purchases/List/PurchasesPage.vue';

export default [
	{
		path: '',
		name: 'Home',
		redirect: (to: RouteLocation) => {
			const appStore = useAppStore();
			const sessionStore = useSessionStore();

			const mpid = appStore.mpid;

			const isParentAccountAdmin: boolean =
				sessionStore.isParentAccount ||
				sessionStore.isParentAccountAdmin ||
				sessionStore.user.role_id[mpid] === 'parent_account_admin';

			let path = '/campaigns';

			if (sessionStore.isMarketplaceUser) {
				path = '/reports/marketplace-overview';
			}

			if (to.params.parent_account_id || isParentAccountAdmin) {
				path = '/reports/parent-account-overview';
			}

			if (to.meta.admin) {
				path = (to.path + '/' + path).replace('//', '/');
			}

			return {
				path: path,
			};
		},
	},
	{
		path: 'reports/account-overview',
		name: 'AccountOverview',
		component: AccountOverview,
		meta: {
			title: 'Account Overview',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/account-activity',
		name: 'AccountActivity',
		component: AccountActivity,
		meta: {
			title: 'Account Activity',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/account-performance',
		name: 'AccountPerformance',
		component: AccountPerformance,
		meta: {
			title: 'Account Performance',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/marketplace-overview',
		name: 'MarketplaceOverview',
		component: MarketplaceOverview,
		meta: {
			title: 'Marketplace Overview',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/marketplace-conversions-source',
		name: 'MarketplaceConversionsBySource',
		component: MarketplaceConversionsBySourceReport,
		meta: {
			title: 'Conversions',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/conversions-source',
		name: 'AccountConversionsBySource',
		component: AccountConversionsBySourceReport,
		meta: {
			title: 'Conversions',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/data-leads',
		name: 'MarketplaceDataLeads',
		component: MarketplaceDataLeads,
		meta: {
			title: 'Marketplace Data Leads',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/calls',
		name: 'MarketplaceCalls',
		component: MarketplaceCalls,
		meta: {
			title: 'Marketplace Calls',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/live-transfers',
		name: 'MarketplaceLiveTransfers',
		component: MarketplaceLiveTransfers,
		meta: {
			title: 'Marketplace Live Transfers',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'legacy-purchases',
		name: 'Legacy Purchase',
		component: LegacyV3PurchasesPage,
		meta: {
			title: 'Purchases - Legacy',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases',
		name: 'AllLeads',
		component: AccountAllLeads,
		meta: {
			title: 'Purchases - All Leads',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/data-leads',
		name: 'DataPurchases',
		component: AccountDataLeads,
		meta: {
			title: 'Data Lead Purchases',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/call-leads',
		name: 'CallPurchases',
		component: AccountCallLeads,
		meta: {
			title: 'Call Lead Purchases',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/live-transfers',
		name: 'LiveTransferPurchases',
		component: AccountLiveTransfers,
		meta: {
			title: 'Live Transfer Purchases',
			permissions: 'account_reports',
		},
	},
];
