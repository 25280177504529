<template>
	<div class="view-content">
		<div class="page-heading">
			<h2>Recent Sign Ups</h2>
		</div>

		<div class="card padded">
			<query-form v-model:query="query" :loading="loading" @apply="getData">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}
						</div>
					</div>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range v-model="form.query.date_range" hide-labels :show-time="false" />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>

			<p-data-table ref="dt" scrollable data-key="account_id" :loading="loading" :value="rows">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>

				<p-column field="created_at" header="Created">
					<template #body="row">
						{{ formatDate(row.data.created_at, 'ddd, MMM D, YYYY') }}
						<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
					</template>
				</p-column>

				<p-column field="account_manager_name" header="Account Manager">
					<template #body="row">
						<div v-tooltip.top="{ value: contactInfo(row.data), escape: false }">
							{{ row.data.account_manager_name }}
						</div>
					</template>
				</p-column>

				<p-column field="account_name" header="Account">
					<template #body="row">
						<router-link
							:to="`/marketplaces/manage/${row.data.account_id.slice(0, 3)}/accounts/manage/${row.data.account_id}/reports/account-activity`"
						>
							{{ row.data.account_name }}
						</router-link>
						<div class="sub-value">{{ row.data.account_id }}</div>
					</template>
				</p-column>

				<p-column field="source_id" header="Source" class="align-center">
					<template #body="row">
						<div v-tooltip="row.data.s1">
							{{ row.data.source_id }}
						</div>
					</template>
				</p-column>

				<p-column field="vertical_id" header="Vertical" class="align-center">
					<template #body="row">
						<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
					</template>
				</p-column>

				<p-column field="balance" header="Balance">
					<template #body="row">
						{{ currency(row.data.balance || 0) }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { reportRecentSignUps } from '@GQL';
import { startOfWeek, endOfDay } from 'date-fns';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { formatDate, currency, timeAgo } from '@/lib/Filters';

export default {
	name: 'RecentSignUps',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			total_rows: 0,
			rows: [],
			query: {
				date_range: [startOfWeek(new Date()), endOfDay(new Date())],
				pagination: {
					page_size: 25,
					page: 0,
				},
				refresh: false,
			},
		};
	},
	async mounted() {
		await this.getData();
	},
	methods: {
		currency,
		formatDate,
		timeAgo,
		contactInfo(data) {
			let output = '';

			output += `<div>Completed Follow Ups: ${data.completed_follow_ups}</div>`;
			output += `<div>Call Count: ${data.call_count}</div>`;
			output += `<div>SendGrid Email Count: ${data.email_count}</div>`;

			return output;
		},
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getData(true);
		},
		async getData(refresh?: boolean) {
			this.loading = true;
			if (refresh) {
				this.query.refresh = true;
			}
			try {
				const result = await reportRecentSignUps(this.query);
				this.total_rows = result.row_count;
				this.rows = result.rows;
			} catch (err) {
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
