<template>
	<div>
		<p-button label="Test Shipper with Data Object" @click="openModal">
			<template #icon>
				<div class="icon-wrapper button-icon">
					<icon type="cube-send" size="24px" />
				</div>
			</template>
		</p-button>
		<p-dialog
			id="test-shipper-modal"
			:style="{ width: '50vw' }"
			v-model:visible="test_shipper_modal"
			:modal="true"
			:dismissable-mask="true"
		>
			<loader :show="response_loading" />
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="cube-send" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Test Shipper with Data Object</span>
				</div>
			</template>

			<div v-if="!test_response && !response_loading">
				<p>
					In order to emulate production, you must select one of the Account's existing campaigns. This selection also
					determines what lead is pulled into the test.
				</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Campaign:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="campaign_id"
									:options="campaign_options"
									option-label="label"
									option-value="value"
									placeholder="Select a Campaign"
								/>

								<div v-if="campaign_id_missing" class="validation-error">A campaign must be selected</div>
							</div>
						</div>
					</div>
				</div>
				<p><strong>Override Usage:</strong></p>
				<p>
					Use this to hide any personal identifiable information (PII), when sending test leads you want to send test
					names, emails, etc. Use these defaults as a starter:
				</p>

				<div v-if="campaign_id !== ''" class="control-group">
					<div class="inner">
						<p>
							If you want to specify a real lead_id, enter it here. The lead override below will still change some of
							the values
						</p>
						<label class="control-label">Lead ID Overrider:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="real_lead_id" placeholder="Optional real lead ID" />
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label">Override Campaign Object:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="test-json" v-model.parse="local_campaign_override" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Override Lead Object:</label>
						<p class="sub-value">
							This is the lead object for the mapping I.E.
							<code>{{ '{' + '{lead.first_name}' + '}' }}</code>
						</p>
						<p class="red" v-if="real_lead_id !== ''">
							<strong>These values will override any that overlap the real lead you have entered!</strong>
						</p>
						<div class="controls">
							<div class="field">
								<code-editor id="lead-json" v-model.parse="local_lead_override" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Override Purchase Object:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="purchase-json" v-model.parse="local_purchase_override" />
							</div>
						</div>
					</div>
				</div>
				<div v-if="mapped_data" class="control-group">
					<div class="inner">
						<label class="control-label">Data Map Test:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="mapped-json" v-model.parse="mapped_data" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="test_response && test_response.return_result">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Test Response:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="test-json" v-model.parse="test_response.return_result" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Test Shipment Log:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="test-json" v-model.parse="test_response.shipment_log" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="w-full flex justify-content-between align-items-center">
					<p-button label="Cancel" text @click="test_shipper_modal = !test_shipper_modal" />
					<div class="actions">
						<p-button v-if="!test_response" label="Preview Mapping" @click="mapData">
							<template #icon>
								<div class="icon-wrapper button-icon">
									<icon type="map-check-outline" size="24px" />
								</div>
							</template>
						</p-button>
						<p-button v-if="test_response" label="Try different override" @click="test_response = null" />
						<p-button v-if="test_response === null" label="Send Test" @click="testShipper">
							<template #icon>
								<div class="icon-wrapper button-icon">
									<icon type="cube-send" size="24px" />
								</div>
							</template>
						</p-button>
					</div>
				</div>
			</template>
		</p-dialog>
	</div>
</template>
<script lang="ts">
import { mapper } from '@/lib/Utils/mapper';
import { getCampaignsAsOptions, shipByData, getRandomLead } from '@GQL';
import { ulid } from 'ulid';
import { cloneDeep } from 'lodash-es';

export default {
	name: 'TestShipperModal',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		mpid: {
			type: String,
			required: true,
		},
		shipper: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			campaign_id_missing: false,
			test_shipper_modal: false,
			response_loading: false,
			local_campaign_override: {},
			local_purchase_override: {},
			// Generate random test phone/email
			local_lead_override: {
				phone: Math.floor(3234567891 + Math.random() * 1234567891).toString(),
				email: `testlead+${Math.floor(Math.random() * 1000)}@nextgenleads.com`,
				first_name: 'TEST',
				last_name: 'LEAD',
			},
			test_response: null,
			real_lead_id: '',
			campaign_id: '',
			campaign_options: [],
			mapped_data: '',
		};
	},

	async mounted() {
		this.campaign_options = await getCampaignsAsOptions([
			[`account_id = '${this.account_id}' AND status != 'archived'`],
		]);
	},
	methods: {
		async mapData() {
			if (this.shipper.data_map) {
				this.response_loading = true;
				try {
					let vertical = 'health_insurance';
					let product = 'data';
					if (this.campaign_id) {
						vertical = this.campaign_options.find((campaign) => {
							return campaign.value === this.campaign_id;
						}).vertical;
					}
					const random_lead = await getRandomLead(vertical, product);
					const lead = { ...random_lead, ...this.local_lead_override };
					let mapped = '';
					if (this.shipper.data_map) {
						mapped = mapper.compile(this.shipper.data_map, {
							lead,
						});
					}
					this.mapped_data = JSON.parse(mapped);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Error mapping data',
						detail: err.response.errors[0]?.message || err.message,
						life: 5000,
					});
				} finally {
					this.response_loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Error mapping data',
					detail: 'Nothing to map',
					life: 5000,
				});
			}
		},
		async testShipper() {
			this.campaign_id_missing = this.campaign_id === '';
			if (this.campaign_id_missing) {
				this.$toast.add({
					severity: 'error',
					summary: 'A required field has not been set',
					life: 5000,
				});
			} else {
				this.response_loading = true;
				try {
					const test_shipper = { ...cloneDeep(this.shipper) };
					if (!('id' in test_shipper) || !('account_id' in test_shipper)) {
						test_shipper.id = ulid();
						test_shipper.account_id = this.account_id;
					}

					const req = {
						campaign_id: this.campaign_id,
						shipper: test_shipper,
						campaign_override_data: this.local_campaign_override,
						purchase_override_data: this.local_purchase_override,
						lead_override_data: this.local_lead_override,
					};
					if (this.real_lead_id !== '' && this.real_lead_id.length === 11) {
						req.lead_id = this.real_lead_id;
					}

					this.test_response = await shipByData(req);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to test the shipper',
						detail: err.message,
					});
				} finally {
					this.response_loading = false;
				}
			}
		},
		reset() {
			this.local_campaign_override.account_id = this.account_id;
			this.local_purchase_override.account_id = this.account_id;
			this.local_campaign_override.mpid = this.mpid;
			this.local_purchase_override.mpid = this.mpid;
			this.local_lead_override.mpid = this.mpid;

			this.test_response = null;
			this.campaign_id = '';
			this.real_lead_id = '';
		},
		openModal() {
			this.reset();
			// reset
			this.test_shipper_modal = true;
		},
	},
};
</script>
