import { $GQL } from '@GQL/index';

export async function reportBuyerVelocity(params: { date_range: [string, string]; compare: any }, refresh: boolean) {
	const query = `
        query ReportBuyerVelocity($params: JSONObject!) {
            reportBuyerVelocity(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportBuyerVelocity'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportBuyerVelocity;
}
