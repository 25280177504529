<template>
	<div class="content-premium-listings">
		<p style="margin-bottom: 0">
			Display a customized full page ad to every lead you purchase, significantly increasing contact and closing rate!
		</p>
		<p class="sub-value">
			Enabling this feature will cost <strong style="color: var(--green)">{{ currency(pl_price) }}</strong> per lead and
			is likely to boost your campaign performance.
		</p>
		<p-message v-if="ads.length === 0" :closable="false"
			>Please create a Premium Listing for this campaign's vertical in order to enable Premium Listings.</p-message
		>
		<div v-else class="premium-listing-switch">
			<div class="switch">
				<p-input-switch input-id="enable_premium_listing" v-model="campaignStore.campaign.ad.enable_premium_listing" />
			</div>
			<label for="enable_premium_listing">Enable Premium Listing</label>
		</div>
		<div v-if="campaignStore.campaign.ad.enable_premium_listing" class="premium-listing-options">
			<h3>Premium Listing Options</h3>
			<p-message v-if="ads.length === 0" :closeable="false"
				>Please create a Premium Listing for this campaign's vertical in order to enable Premium Listings.</p-message
			>
			<div
				v-for="pl in ads"
				:class="['premium-listing-option', { selected: campaignStore.campaign.ad.ad_id === pl.id }]"
				:key="pl.id"
				@click="this.campaignStore.campaign.ad.ad_id = pl.id"
			>
				<div class="flex align-items-center">
					<div class="radio-button">
						<p-radio-button name="ad_id" input-id="ad_id" v-model="campaignStore.campaign.ad.ad_id" :value="pl.id" />
					</div>
					<gutter size="20px" />
					<div class="name">
						<strong>{{ pl.name }}</strong
						><br />
						<div class="sub-value">{{ pl.id }}</div>
					</div>
				</div>
				<div class="preview-ad-link" @click="previewAd(pl.id)">
					<icon type="eye" size="20px" />
					<div>Preview this Listing</div>
				</div>
				<div class="price"><strong>$5 add-on</strong> per lead purchase</div>
			</div>
		</div>
		<p-dialog modal header="Premium Listing Preview" :style="{ width: '90vw' }" v-model:visible="show_preview_modal">
			<div class="text-align-center">
				<p-select-button
					v-model="preview_width"
					:options="responsive_options"
					option-label="label"
					option-value="value"
				/>
			</div>
			<gutter size="20px" />
			<div class="responsive-wrapper">
				<div :class="['ad-preview', preview_width]" v-html="parsed_ad_content"></div>
			</div>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import { getAds, getParsedAdContent, getSettings } from '@GQL';
import { useCampaignStore } from '@/stores/campaign';
import { currency } from '@/lib/Filters';
import { mapStores } from 'pinia';
import pDialog from 'primevue/dialog';

export default {
	name: 'PremiumListingContent',
	components: {
		pDialog,
	},
	data() {
		return {
			ads: [],
			parsed_ad_content: null,
			pl_price: 0,
			preview_width: 'desktop',
			responsive_options: [
				{ label: 'Desktop', value: 'desktop' },
				{ label: 'Tablet', value: 'tablet' },
				{ label: 'Mobile', value: 'mobile' },
			],
			show_preview_modal: false,
		};
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
		premiumListingEnabled() {
			return this.campaignStore.campaign.ad.enable_premium_listing || false;
		},
		...mapStores(useCampaignStore),
	},
	methods: {
		currency,
		async getAds() {
			const ads = await getAds([
				[
					`account_id = '${this.campaignStore.campaign.account_id}'`,
					`vertical_id = '${this.campaignStore.campaign.vertical_id_targeting}'`,
					`status = 'active'`,
					`approval_status = 'approved'`,
				],
			]);
			if (ads) {
				this.ads = ads;
			}
		},
		async previewAd(ad_id) {
			this.parsed_ad_content = await getParsedAdContent(ad_id);
			this.show_preview_modal = true;
		},
	},
	async mounted() {
		const pl_settings = await getSettings(['premium_listings', this.mpid]);
		if (pl_settings) {
			this.pl_price = get(
				pl_settings,
				`settings.premium_listings.${this.campaignStore.campaign.vertical_id_targeting}.price`,
				5
			);
		}
		this.getAds();
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.premium-listing-switch {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: var(--font-size-sm);
	font-weight: bold;
	gap: 10px;
	justify-content: flex-start;
	margin: 10px 0;
}

.premium-listing-options h3 {
	font-size: 1.125rem;
	font-weight: bold;
	margin-top: 1.5em;
}

.premium-listing-option {
	align-items: center;
	border: 1px solid var(--gray-25);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1em;
	padding: 20px;

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	.radio-button {
		flex: 0 0 auto;
	}

	.name {
		line-height: 1.25em;
	}

	.preview-ad-link {
		align-items: center;
		color: var(--color-b);
		cursor: pointer;
		display: flex;
		font-size: 0.875rem;
		gap: 5px;
	}
}

.responsive-wrapper {
	background-color: var(--gray-10);
	padding: 20px;
	width: 100%;

	.ad-preview {
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
		padding: 20px;
		margin: 0 auto;
	}

	.desktop {
		width: 100%;
	}

	.tablet {
		width: 768px;
	}

	.mobile {
		width: 480px;
	}
}
</style>
