<template>
	<p-card>
		<template #title>
			<div class="flex align-items-center w-full">
				<icon type="table-clock" size="var(--font-size-h4)" class="mr-2" />
				<span class="name">Auction</span>
			</div>
		</template>
		<template #content>
			<p-message :closable="false" v-if="auctions.length === 0" class="text-center"
				>This lead has not been auctioned yet</p-message
			>
			<p-timeline v-if="auctions" :value="auctions">
				<template #opposite="slotProps">
					<div style="font-size: var(--font-size-sm); line-height: 1.5">
						{{ formatDate(new Date(slotProps.item.created_at), 'YYYY-MM-DD') }}<br />
					</div>
					<div class="sub-value">
						{{ formatDate(new Date(slotProps.item.created_at), 'h:mm:ssa') }}
					</div>
				</template>
				<template #marker="slotProps">
					<div
						class="marker"
						:class="slotProps.item.status.includes(['errored', 'failed']) ? 'border-red-500' : 'border-green-500'"
					></div>
				</template>
				<template #content="slotProps">
					<div class="auction-detail line-height-2">
						<a @click="openAuctionLogModal(leadId, slotProps.item.id)" style="font-size: var(--font-size-sm)"
							>{{ slotProps.item.auction.name }}
						</a>
						<div class="sub-value">
							{{ slotProps.item.mpid === '$NG' ? 'Global' : slotProps.item.mpid }} -
							{{ capitalize(slotProps.item.status) }}
						</div>
					</div>
				</template>
			</p-timeline>
		</template>
	</p-card>
	<AuctionModal ref="auction_modal" />
</template>

<script lang="ts">
import pTimeline from 'primevue/timeline';
import pMessage from 'primevue/message';
import AuctionModal from './modals/AuctionModal.vue';
import { formatDate } from '@/lib/Filters';
import { getAuctionHistory } from '@GQL/queries';
import { useSessionStore } from '@/stores/session';
import { capitalize, cloneDeep } from 'lodash-es';
export default {
	name: 'AuctionPanel',
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	components: {
		pMessage,
		pTimeline,
		AuctionModal,
	},
	props: {
		leadId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			auctions: [],
			lead: {},
			selected_auction_log_id: null,
		};
	},
	async mounted() {
		await this.getAuctionLogs();
	},
	methods: {
		capitalize,
		formatDate,
		openAuctionLogModal(leadId, auction_log_id) {
			this.$refs.auction_modal.open(leadId, auction_log_id);
		},
		async getAuctionLogs() {
			this.loading = true;
			const filters = [`lead_id = '${this.leadId}'`];
			try {
				const result = await getAuctionHistory([filters]);
				this.auctions = cloneDeep(result);
				this.lead = { id: this.leadId };
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error fetching auction history',
					detail: `${err}`,
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.marker {
	align-items: center;
	border: 1px solid;
	border-radius: 50%;
	display: flex;
	height: 0.75rem;
	justify-content: center;
	width: 0.75rem;
}
</style>
