<template>
	<div id="sign-up-pages-list" class="view-content">
		<div class="page-heading">
			<h1>Sign-Up Pages</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Sign-Up Page" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="signup_pages" :loading="loading">
							<template #empty>
								<div class="dim">No sign-up pages found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Name" sortable sortField="name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>

							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-tooltip.top="'Duplicate'"
												aria-label="Duplicate"
												@click="duplicateSignUpPage($event, row.data.id)"
											>
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<delete-action
											message="Are you sure you want to delete this shipper?"
											@delete="deleteSignUpPage(row.data.id)"
										/>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { remove } from 'lodash-es';
import { getSignUpPages, deleteSignUpPage } from '@GQL';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SignUpPageListView',
	components: {
		deleteAction,
	},
	data() {
		return {
			loading: false,
			signup_pages: [],
			breadcrumb_items: [],
		};
	},
	computed: {
		accountId() {
			return this.$route.params.account_id || this.$root.sessionStore.account.id;
		},
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
	},
	async mounted() {
		await this.getSignUpPages();
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Sign Up Pages',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Sign Up Pages',
			});
		}
	},
	methods: {
		async getSignUpPages() {
			this.loading = true;
			try {
				const result = await getSignUpPages(`mpid = '${this.mpid}'`);
				this.signup_pages = result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get sign-up pages',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		async duplicateSignUpPage(event, id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: id,
				},
			});
		},
		async deleteSignUpPage(id) {
			const delete_result = await deleteSignUpPage(id);
			if (delete_result.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The sign-up page "${delete_result.name}" was deleted.`,
					life: 3000,
				});
				remove(this.signup_pages, (page) => {
					return page.id === id;
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to delete the sign-up page "${delete_result.name}".`,
					life: 3000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
:deep(.tools) {
	max-width: 120px;
}
</style>
