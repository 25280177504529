<template>
	<div class="settings-form">
		<loader :show="loading" />
		<h2>Auction Settings</h2>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Cached Balances" class="admin-only">
			<row>
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Available:</label>
							<div class="controls">
								<div class="field fit">
									<p-input-currency :disabled="true" v-model="balances.available" />
								</div>
							</div>
						</div>
					</div>
				</column>
				<gutter size="10px" />
				<column>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Actual:</label>
							<div class="controls">
								<div class="field fit">
									<p-input-currency :disabled="true" v-model="balances.actual" />
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<p>
				The <strong>Actual</strong> balance is the "true" balance of an account after all transactions are finalized.
				The <strong>Available</strong> balance is the used in the auctions since this balance accounts for pending
				transactions while a transaction is yet to be finalized.
			</p>
			<p>
				Since pending transactions are not long lived, the actual and available balances should almost always be synced.
				The <strong>Available</strong> balance might be lower than <strong>Available</strong> but
				<strong> Available</strong> should never be lower than <strong>Actual</strong>
			</p>

			<p-button @click="syncBalance" label="Reset Balance" />
		</p-fieldset>
		<p-fieldset legend="Buyer Rating">
			<div class="inline-block">
				<metric label="Current Buyer Rating" :value="buyer_rating" isPercentage />
			</div>
			<gutter size="20px" />
			<template v-if="$root.sessionStore.isAdminUser">
				<p>
					An account's buyer rating is calculated using the percentage of canceled purchases over the past 7 days. There
					is a minimum of 10 purchases required before we can calculate a buyer rating. An account's buyer rating will
					not go any lower than 70% and the buyer rating is used to weigh bids in any auction using the cancel rate
					qualifier. A buyer may reset their rating once every 7 calendar days in the event it is affecting their
					volume.
				</p>
				<div class="flex align-items-center gap-20">
					<p-button icon="pi pi-refresh" label="Reset Buyer Rating" @click="resetBuyerRating()" />
					<p v-if="cancel_rate_reset_countdown > 0" style="margin: 0">
						The rating was last reset {{ 7 - cancel_rate_reset_countdown }} days ago
					</p>
					<p v-else style="margin: 0">The rating has not been reset recently</p>
				</div>
			</template>
			<template v-else>
				<p>Your buyer rating can be reset once every 7 calendar days.</p>
				<div class="flex align-items-center gap-20">
					<p-button
						icon="pi pi-refresh"
						:disabled="cancel_rate_reset_countdown > 0"
						label="Reset Buyer Rating"
						@click="resetBuyerRating()"
					/>
					<p style="margin: 0">You can reset your buyer rating in {{ cancel_rate_reset_countdown }} days</p>
				</div>
			</template>
		</p-fieldset>
		<p-fieldset legend="Pending Campaign Logic">
			<p>
				When you run out of funds in your account, your active campaigns will switch to
				<b>PENDING</b> status until funds have been added to your account balance. When funds are added, your
				<b>PENDING</b> campaigns will be reactivated so you can resume purchasing leads. You can select the box below to
				keep your <b>PENDING</b> campaigns paused when you add funds.
			</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="account.settings.pause_pending_campaigns" />
						</div>
						<div class="gap" />
						<div class="field caption">
							<div class="text">Keep pending campaigns paused</div>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Bulk Returns" class="admin-only">
			<p>
				Bulk returns are completed once a month. The return rate gets multiplied by the amount of spend the account has
				over the last month. From the spend amount, we subtract the amount of the bulk returns from last time the
				returns were calculated. Allowing for bulk returns may also disable the ability for the account to return
				individual leads.
			</p>
			<p-message :closable="false">
				Bulk returns will only apply to purchases by campaigns that have the <strong>Bulk Returns</strong> flag enabled
			</p-message>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="account.settings.bulk_returns.enabled" />
						</div>
						<div class="gap" />
						<div class="field caption">
							<div class="text">Enable Bulk Returns</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="account.settings.bulk_returns.enabled" class="control-group">
				<div class="inner">
					<label class="control-label">Return Rate</label>
					<div class="controls">
						<div class="field fit">
							<p-input-percentage v-model="account.settings.bulk_returns.return_rate" />
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Data Returns" class="admin-only">
			<p>
				Data Returns are allowed for anyone by default. ONLY enable this section if you are going to be granting special
				return rates or unique introductory return rates to this account. Otherwise the account will be allowed to
				return data leads as any others based on the daily running returns job.
			</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="account.settings.data_returns.enabled" />
						</div>
						<div class="gap" />
						<div class="field caption">
							<div class="text">Use a Custom Return Rate</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="account.settings.data_returns.enabled">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Return Rate</label>
						<div class="controls">
							<div class="field fit">
								<p-input-percentage v-model="account.settings.data_returns.return_rate" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Introductory Return Rate</label>
						<div class="controls">
							<div class="field fit">
								<p-input-percentage v-model="account.settings.data_returns.introductory_return_rate" />
							</div>
						</div>
					</div>
				</div>
			</template>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Credit Terms" class="admin-only">
			<p>
				This is a very special setting. Contracts should be uploaded and payment periods should be established before
				turning this on. Another requirement is for the account to start with a zero balance. Once those conditions are
				met, the balance for this account will only be negative, signaling the amount owed by this account. If you have
				any questions contact the development team before turning this on.
			</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="credit-terms" v-model="account.settings.credit_terms.enabled" />
						</div>
						<div class="gap" />
						<div class="field caption">
							<label for="credit-terms" class="text">Enable Credit Terms</label>
						</div>
					</div>
				</div>
			</div>
			<p-fieldset v-if="account.settings.credit_terms.enabled" legend="Credit Term Settings" class="inner">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field fit">
								<p-input-switch input-id="credit-terms" v-model="account.settings.credit_terms.limit_enabled" />
							</div>
							<div class="gap" />
							<div class="field caption fit">
								<label for="credit-terms" class="text">Limit Credit Amount</label>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Credit Limit:</label>
						<div class="controls">
							<div class="field m">
								<p-input-currency
									:disabled="!account.settings.credit_terms.limit_enabled"
									v-model="account.settings.credit_terms.limit"
								/>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Advanced Campaigns" class="admin-only">
			<p>
				Allowing advanced campaign edit mode will give buyers access to create campaigns using the advanced bidding
				interface.
			</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow-advanced-mode" v-model="account.settings.allow_advanced_mode" />
						</div>
						<div class="gap" />
						<div class="field caption">
							<label for="allow-advanced-mode" class="text">Allow Advanced Campaign Edit Mode</label>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="USHA">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Agent ID:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="account.settings.usha.agent_id" @blur="v$.ushaAgentId.$touch()" />
							<div v-if="v$.ushaAgentId.$error" class="validation-error">
								{{ v$.ushaAgentId.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="usha-validation" v-model="account.settings.usha.enable_validation" />
						</div>
						<div class="gap"></div>
						<div class="field caption">
							<label for="usha-validation" class="text">Enable USHA validation on lead purchases</label>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<div class="actions">
			<p-button label="Save" icon="pi pi-check" @click="saveAccountAuctionSettings()" />
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, isNil, mergeWith } from 'lodash-es';
import { deepClean } from '@/lib/Utils/deepClean';
import pFieldset from 'primevue/fieldset';
import pInputSwitch from 'primevue/inputswitch';
import {
	getAccountAuctionSettings,
	getBalances,
	getCancelRate,
	getCancelRateResetCountdown,
	resetCancelRate,
	updateAccount,
} from '@GQL';
import { helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { percentage } from '@/lib/Filters';

const default_settings = {
	pause_pending_campaigns: false,
	bulk_returns: {
		enabled: false,
		return_rate: 0,
	},
	data_returns: {
		enabled: false,
		return_rate: 0,
	},
	credit_terms: {
		enabled: false,
		limit_enabled: false,
		limit: 0,
	},
	allow_advanced_mode: false,
	usha: {
		agent_id: null,
		enable_validation: false,
	},
};

export default {
	name: 'AccountAuctionSettings',
	components: {
		pFieldset,
		pInputSwitch,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			loading: false,
			account: {
				settings: cloneDeep(default_settings),
			},
			balances: {
				actual: 0,
				available: 0,
			},
			cancel_rate_reset_countdown: 0,
			buyer_rating: 1,
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		ushaAgentId() {
			return this.account.settings.usha.agent_id;
		},
	},
	validations() {
		return {
			ushaAgentId: {
				valid: helpers.withMessage('Please enter a valid agent ID (8 digits)', (value) => {
					if (value) {
						const pattern = /^\d{8}$/;
						return pattern.test(value);
					}
					return true;
				}),
			},
		};
	},
	async created() {
		await this.fetchAccountAuctionSettings();
	},
	methods: {
		percentage,
		async syncBalance() {
			this.loading = true;
			try {
				const res = await getBalances(this.accountId, true);
				if (res) {
					this.balances = { ...res };
					this.$toast.add({
						severity: 'success',
						summary: 'Balance has been reset',
						life: 6000,
					});
				} else {
					throw new Error();
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to reset balance',
				});
			} finally {
				this.loading = false;
			}
		},
		async fetchAccountAuctionSettings(refresh?: boolean) {
			this.loading = true;
			try {
				const result = await getAccountAuctionSettings(this.accountId, refresh);
				this.buyer_rating = await getCancelRate(this.accountId);
				this.cancel_rate_reset_countdown = await getCancelRateResetCountdown(this.accountId);
				if (result.account) {
					this.account = mergeWith(
						{ settings: cloneDeep(default_settings) },
						this.account,
						result.account,
						(target, source) => {
							if (target && isNil(source)) {
								return target;
							}
							return;
						}
					);

					if (result.balance) {
						this.balances = { ...result.balance };
					}
				} else {
					throw new Error();
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get auction settings',
				});
			} finally {
				this.loading = false;
			}
		},
		async saveAccountAuctionSettings() {
			this.loading = true;
			try {
				if (this.v$.ushaAgentId.$invalid) {
					throw new Error('Inavlid USHA agent ID');
				} else {
					this.v$.$reset();
				}

				const account_result = await updateAccount(this.accountId, deepClean(this.account));
				if (account_result.updateAccount.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved account settings',
						life: 3000,
					});
					await this.fetchAccountAuctionSettings(true);
				} else {
					throw new Error();
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					detail: err.message || 'Please contact support if the problem persists',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		async resetBuyerRating() {
			try {
				const result = await resetCancelRate(this.accountId);
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Buyer Rating was reset successfully',
						detail: 'You will need to wait 7 more days to reset it again',
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to reset the buyer rating',
						detail: 'Please see your account manager for assistance',
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to reset the buyer rating',
					detail: 'Please see your account manager for assistance',
					life: 3000,
				});
			} finally {
				this.cancel_rate_reset_countdown = 7;
			}
		},
	},
};
</script>

<style scoped lang="less">
:deep(*) {
	.p-inputnumber.short {
		max-width: 100px;
	}

	.p-inputnumber-input {
		text-align: right;
	}

	.field.caption {
		align-items: center;
		display: flex;
		height: 40px;

		label {
			font-weight: normal;
		}
	}
}

.field {
	margin-bottom: 0;
}
</style>
@/lib/GQL/mutations/updateAccount
