import { $GQL } from '@GQL/index';

export async function reportBuyerVelocityByCampaignId(
	account_id: string,
	params: { date_range: [string, string]; compare: any },
	refresh: boolean
) {
	const query = `
        query ReportBuyerVelocityByCampaignId($account_id: String!, $params: JSONObject!) {
            reportBuyerVelocityByCampaignId(account_id: $account_id, params: $params)
        }
    `;

	const no_cache = refresh ? ['reportBuyerVelocityByCampaignId'] : undefined;
	const results = await $GQL.request(query, { account_id, params, no_cache });
	return results.reportBuyerVelocityByCampaignId;
}
