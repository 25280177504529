<template>
	<div class="content-location-targeting">
		<!-- <div v-if="v$.locationTargeting.$error" class="validation-error top">
			{{ v$.locationTargeting.$errors[0].$message }}
		</div> -->
		<p v-if="$root.sessionStore.isAdminUser">
			Target your campaign by state or zip code; you can only target states or zip codes, not both. The map shows the
			states you're currently targeting.
		</p>
		<p v-else>Target your campaign by state. The map shows the states you're currently targeting.</p>

		<div v-if="$root.sessionStore.isAdminUser" class="mb-3">
			<div for="location-type" style="font-size: var(--font-size-sm); font-weight: bold">Location Targeting Type:</div>
			<div class="flex flex-wrap gap-3 align-items-center">
				<div class="flex align-items-center">
					<p-radio-button v-model="location_bid_mode" input-id="state" name="state" value="state" />
					<label for="state" class="ml-2">State</label>
				</div>
				<div class="flex align-items-center m-2">
					<p-radio-button v-model="location_bid_mode" input-id="zipcode" name="zip" value="zip" />
					<label for="zipcode" class="ml-2">Zipcode</label>
				</div>
			</div>
		</div>

		<div class="basic-interface">
			<div v-if="location_bid_mode === 'state'" class="state-select">
				<div class="map">
					<StateSelector v-model="location_bid_states" />
				</div>
				<div class="selected-states">Total selected states: {{ location_bid_states.length }}</div>
				<div class="state-options">
					<div class="line-height-3">
						<a class="cursor-pointer" @click.prevent="selectAll">Select All</a>
						| <a class="cursor-pointer" @click.prevent="selectNone">Select None</a>
					</div>
					<div v-if="v$.location_bid_states.$error" class="validation-error">
						{{ v$.location_bid_states.$errors[0].$message }}
					</div>
					<ul class="options">
						<li v-for="state of state_options" :key="state.value">
							<div class="flex align-items-center">
								<p-checkbox v-model="location_bid_states" :input-id="state.value" name="state" :value="state.value" />
								<label :for="state.value">{{ state.label }}</label>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div v-if="location_bid_mode === 'zip'" class="zip-select flex flex-column">
				<template v-if="$root.sessionStore.isAdminUser">
					<div class="mb-2">
						Enter 5 digit zipcodes below separated by commas. Non-numerical values will be removed.
					</div>
					<gutter size="20px" />
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<p-textarea v-model="locationBidZips" />
									<div v-if="v$.location_bid_zips.$error" class="validation-error">
										{{ v$.location_bid_zips.$errors[0].$message }}
										{{ invalid_zips }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="selected-zips">Total zip codes: {{ location_bid_zips.length }}</div>
				</template>
				<template v-else>
					<p-message severity="warn" :closable="false"
						>Please contact your account manager to make changes to ZIP code targeting!</p-message
					>
					<p-message severity="info" :closable="false">
						Targeted Zips ({{ location_bid_zips.length }}):
						<div class="mt-2 mb-2">
							{{ show_all_zips ? location_bid_zips.slice(0, 150).join(', ') : locationBidZips }}
						</div>
						<div>
							<a v-if="location_bid_zips.length >= 150" @click.prevent="show_all_zips = !show_all_zips">
								{{ show_all_zips ? 'Show All' : 'Show Less' }}
							</a>
						</div>
					</p-message>
				</template>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import STATES from '@/lib/Data/states.json';
import { compact, without } from 'lodash-es';
import StateSelector from '@/components/widgets/StateSelector.vue';
import pDialog from 'primevue/dialog';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pBlockUi from 'primevue/blockui';
import CheckIcon from 'primevue/icons/check';
import pChips from 'primevue/chips';
import pTextarea from 'primevue/textarea';
import pMessage from 'primevue/message';
import { useCampaignStore } from '@/stores/campaign';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
	name: 'LocationContent',
	components: {
		CheckIcon,
		deleteAction,
		pBlockUi,
		pDialog,
		pTextarea,
		pChips,
		pMessage,
		StateSelector,
	},
	setup() {
		return {
			campaignStore: useCampaignStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			location_bid_mode: 'state',
			location_bid_states: [],
			location_bid_zips: [],
			state_options: STATES,
			show_all_zips: false,
		};
	},
	computed: {
		locationBid: {
			get() {
				return this.campaignStore.campaign.bids.find((custom_bid) => {
					return custom_bid.type === 'location';
				});
			},
			set(new_value) {
				const location_bid = this.campaignStore.campaign.bids.find((custom_bid) => {
					return custom_bid.type === 'location';
				});
				location_bid.match = new_value.match;
			},
		},
		locationBidZips: {
			get() {
				return this.location_bid_zips.join(', ');
			},
			set(new_value) {
				this.location_bid_zips = compact(new_value.split(/\D+/).map((zip) => zip.trim()));
			},
		},
		locationTargeting() {
			return this.location_bid_states.length + this.location_bid_zips.length;
		},
		invalid_zips() {
			const zips = this.locationBidZips.split(', ');
			return zips.filter((zip) => zip.length !== 5);
		},
	},
	watch: {
		location_bid_mode() {
			this.switchMode();
		},
		location_bid_states(new_value, old_value) {
			this.locationBid.match[0].comparator.value = new_value;
			this.campaignStore.campaign.state_targeting = new_value;
		},
		location_bid_zips(new_value, old_value) {
			this.locationBid.match[0].comparator.value = new_value;
			this.campaignStore.campaign.zip_targeting = new_value;
		},
	},
	validations() {
		return {
			// locationTargeting: {
			// 	required: helpers.withMessage('Targeting for at least 1 state or ZIP code must be active', minValue(1)),
			// },
			location_bid: {
				name: {
					required: helpers.withMessage('This location group requires a name', required),
					isUnique: helpers.withMessage('A location group with this name already exists', (value) => {
						return this.campaignStore.isUniqueCustomBid(value, 'location', this.custom_bid_id);
					}),
					$lazy: true,
				},
			},
			location_bid_states: {
				required: helpers.withMessage('Please select at least one state to target', (v) => {
					if (this.location_bid_mode === 'state') {
						if (v.length === 0) return false;
					}
					return true;
				}),
			},
			location_bid_zips: {
				required: helpers.withMessage('Please enter at least one ZIP code to target', (v) => {
					if (this.location_bid_mode === 'zip') {
						if (v.length === 0) return false;
					}
					return true;
				}),
				fivedigits: helpers.withMessage(`Invalid zipcodes found`, (value: string) => {
					const zips = value.split(' ,');
					return zips.every((v) => {
						return v.length === 5;
					});
				}),
			},
			$validationGroups: {
				campaign: ['locationTargeting'],
				custom_bid: ['location_bid', 'location_bid_states', 'location_bid_zips'],
			},
		};
	},
	methods: {
		without,
		switchMode() {
			this.campaignStore.campaign.location_targeting_mode = this.location_bid_mode;
			if (this.location_bid_mode === 'state') {
				this.locationBid.match = [
					{
						target: {
							path: 'lead.state',
							transformer: null,
						},
						comparator: {
							value: this.location_bid_states,
						},
						strategy: 'one_of',
					},
				];
				this.locationBid.amount = 1;
			} else {
				this.locationBid.match = [
					{
						target: {
							path: 'lead.zip',
						},
						comparator: {
							value: this.location_bid_zips,
						},
						strategy: 'one_of',
						invert: true,
					},
				];
				this.locationBid.amount = 0;
			}
		},
		selectAll() {
			const all_states = [
				'AK',
				'AL',
				'AR',
				'AZ',
				'CA',
				'CO',
				'CT',
				'DC',
				'DE',
				'FL',
				'GA',
				'HI',
				'IA',
				'ID',
				'IL',
				'IN',
				'KS',
				'KY',
				'LA',
				'MA',
				'MD',
				'ME',
				'MI',
				'MN',
				'MO',
				'MS',
				'MT',
				'NC',
				'ND',
				'NE',
				'NH',
				'NJ',
				'NM',
				'NV',
				'NY',
				'OH',
				'OK',
				'OR',
				'PA',
				'RI',
				'SC',
				'SD',
				'TN',
				'TX',
				'UT',
				'VA',
				'VT',
				'WA',
				'WI',
				'WV',
				'WY',
			];
			this.location_bid_states = all_states;
		},
		selectNone() {
			this.location_bid_states = [];
		},
	},
	mounted() {
		// This logic add a default location bid if one is missing on basic campaigns
		const start_time = Date.now();
		const mount_interval = setInterval(() => {
			const location_bid = this.campaignStore.campaign.bids.find((custom_bid) => {
				return custom_bid.type === 'location';
			});

			if (this.campaignStore.campaign.mode === 'advanced' || location_bid) {
				clearInterval(mount_interval);

				if (this.campaignStore.campaign.mode === 'basic') {
					// WE UPDATE the local ones first since they should ultimately be overrwritten by their correct mode bid
					// this is done to preserve the toggle between state + zips from before
					if (this.campaignStore.campaign.zip_targeting.length > 0) {
						this.location_bid_zips = this.campaignStore.campaign.zip_targeting;
					}

					if (this.campaignStore.campaign.state_targeting.length > 0) {
						this.location_bid_states = this.campaignStore.campaign.state_targeting;
					}

					// now we load from the bid itself....
					if (this.campaignStore.campaign.location_targeting_mode === 'state') {
						this.location_bid_mode = 'state';
						this.location_bid_states = location_bid.match[0].comparator.value;
					} else {
						this.location_bid_mode = 'zip';
						this.location_bid_zips = location_bid.match[0].comparator.value;
					}
				}
			} else {
				// Just create it if it's been more than 2 seconds
				if (Date.now() - start_time > 2000) {
					this.campaignStore.campaign.bids.push({
						name: 'Basic Location Targeting',
						type: 'location',
						match: [
							{
								target: {
									path: 'lead.state',
								},
								strategy: 'one_of',
								comparator: {
									value: [],
								},
							},
						],
						method: 'multiply',
						amount: 1,
						status: 'active',
						finalize: false,
						custom_minimum_bid: false,
						minimum_bid: 0,
						dq_reason: 'Location excluded from targeting',
					});
				}
			}
		}, 50);
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.basic-interface {
	background-color: white;
	border: 1px solid var(--gray-20);
	border-radius: 4px;
	box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
	padding: 20px;
}

.map {
	margin: 1rem auto;

	.tablet-up({
		max-width: 90%;
	});
}

.selected-states {
	margin-bottom: 5px;
}

.selected-zips {
	font-size: 0.875rem;
}

.options {
	columns: 3;
	list-style: none;
	margin: 1rem auto;
	padding: 0;

	li {
		font-size: var(--font-size-sm);
	}

	.mobile({
		columns: 2;
	});
}

.state-options {
	font-size: var(--font-size-sm);
}

:deep(.state.highlight) {
	fill: var(--color-b-dark) !important;
}
</style>
