<template>
	<div class="ad-form">
		<p class="req">Required Fields</p>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="ad_template.name" />
						<div v-if="v$.ad_template.name.$error" class="validation-error">
							{{ v$.ad_template.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Vertical:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="ad_template.vertical_id"
							:options="vertical_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.ad_template.vertical_id.$error" class="validation-error">
							{{ v$.ad_template.vertical_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">HTML:</label>
				<div class="controls">
					<div class="field">
						<code-editor
							id="body"
							v-model="ad_template.html"
							:settings="{ mode: 'html', theme: 'sqlserver', wrap: true }"
						/>
						<div v-if="v$.ad_template.html.$error" class="validation-error">
							{{ v$.ad_template.html.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Styles:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model="ad_template.styles" :settings="{ mode: 'less' }" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Scripts:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model="ad_template.scripts" :settings="{ mode: 'javascript' }" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Meta:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse="ad_template.meta" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="ad_template.status"
							:options="status_options"
							option-label="label"
							option-value="value"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="form-actions">
			<p-button label="Cancel" text @click="this.$router.go(-1)" />
			<p-button label="Save Premium Listing" @click="save()">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="check" size="24px" />
					</div>
				</template>
			</p-button>
		</div>
		<p-dialog modal header="Premium Listing Preview" :style="{ width: '90vw' }" v-model:visible="show_preview_modal">
			<div class="text-align-center">
				<p-select-button
					v-model="preview_width"
					:options="responsive_options"
					option-label="label"
					option-value="value"
				/>
			</div>
			<gutter size="20px" />
			<div class="responsive-wrapper">
				<div :class="['ad-preview', preview_width]" v-html="ad_preview"></div>
			</div>
		</p-dialog>
	</div>
</template>

<script>
import { cloneDeep, defaultsDeep, omit, sortBy } from 'lodash-es';
import { getAdTemplate, insertAdTemplate, previewAdTemplate, updateAdTemplate } from '@GQL';
import { email, helpers, required } from '@vuelidate/validators';
import { ulid } from 'ulid';
import { useVuelidate } from '@vuelidate/core';
import { vertical_options } from '@/lib/Options';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';
import codeEditor from '@/components/forms/CodeEditor.vue';
import wysiwyg from '@/components/forms/Wysiwyg.vue';
import pDialog from 'primevue/dialog';

const default_ad_template = {
	id: ulid(),
	mpid: null,
	name: '',
	type: 'premium_listing',
	vertical_id: null,
	html: '',
	styles: '',
	scripts: '',
	meta: {
		conversion_id: '',
		init_button_text: '',
	},
	status: 'active',
};

export default {
	name: 'AdForm',
	components: {
		codeEditor,
		pDialog,
		wysiwyg,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			ad_preview: null,
			ad_template: cloneDeep(default_ad_template),
			preview_width: 'desktop',
			responsive_options: [
				{ label: 'Desktop', value: 'desktop' },
				{ label: 'Tablet', value: 'tablet' },
				{ label: 'Mobile', value: 'mobile' },
			],
			show_preview_modal: false,
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Paused', value: 'paused' },
				{ label: 'Archived', value: 'archived' },
			],
			vertical_options,
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	validations() {
		return {
			ad_template: {
				name: {
					required: helpers.withMessage('A name for this ad is required', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical selection is required', required),
				},
				html: {
					required: helpers.withMessage('HTML content for this ad is required', required),
				},
			},
		};
	},
	methods: {
		async getAdTemplate() {
			if (this.$route.meta.new && this.$route.query.id) {
				const ad_template = await getAdTemplate(this.$route.query.id);
				delete ad_template.id;
				ad_template.name += ' (Copy)';
				this.ad_template = defaultsDeep(cloneDeep(ad_template), cloneDeep(default_ad_template));
			} else if (this.$route.params.ad_template_id) {
				const ad_template = await getAdTemplate(this.$route.params.ad_template_id);
				this.ad_template = defaultsDeep(cloneDeep(ad_template), cloneDeep(default_ad_template));
			}
		},
		async previewAd() {
			this.ad_preview = await previewAdTemplate(
				this.ad_template.html,
				this.ad_template.styles,
				this.ad_template.scripts,
				{
					agent_name: 'John Doe',
					company_name: 'ABC Insurance',
					pronouns: ['he', 'him'],
					phone: '6195551234',
					email: 'john.doe@abcinsurance.com',
					bio: 'With a passion for helping individuals and families secure their health and financial well-being, I am dedicated to providing personalized guidance and support in navigating the complex world of health insurance. As a licensed health insurance agent with [X years/months] of experience in the industry, I specialize in assisting clients in finding the most suitable coverage options tailored to their unique needs and budgets.',
					photo_url: 'https://placekeanu.com/400/400',
					logo_url:
						'https://pictures.alignable.com/eyJidWNrZXQiOiJhbGlnbmFibGV3ZWItcHJvZHVjdGlvbiIsImtleSI6ImJ1c2luZXNzZXMvYmFubmVycy9vcmlnaW5hbC8yMjg0MDQ0LzE1MTU2MDc3MzRfMTAzMzM2MjFfNjQ5NjE3OTM4NDU1MDI4XzQ0MDUyMzM5ODM1MTc0ODg1MTFfby5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjExMjAsImhlaWdodCI6MzA0fX19',
					...this.ad_template.meta,
				}
			);

			this.show_preview_modal = true;
		},
		async save() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) {
				setTimeout(() => {
					document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
				}, 100);
			} else {
				try {
					const new_ad_template = cloneDeep(omit(this.ad_template, ['created_at', 'modified_at']));

					let save_result;
					if (!this.$route.meta.new) {
						save_result = await updateAdTemplate(this.ad_template.id, new_ad_template);
					} else {
						// make sure the account_id is set
						new_ad_template.mpid = this.mpid;
						save_result = await insertAdTemplate(new_ad_template);
					}

					this.$toast.add({
						severity: 'success',
						summary: 'The premium listing template was saved successfully',
						life: 3000,
					});
					this.$router.go(-1);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the premium listing template',
						detail: err.response.errors[0].message,
						life: 6000,
					});
				}
			}
		},
	},
	async mounted() {
		await this.getAdTemplate();
	},
};
</script>

<style scoped lang="less">
.responsive-wrapper {
	background-color: var(--gray-10);
	padding: 20px;
	width: 100%;

	.ad-preview {
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
		padding: 20px;
		margin: 0 auto;
	}

	.desktop {
		width: 100%;
	}

	.tablet {
		width: 768px;
	}

	.mobile {
		width: 480px;
	}
}

.form-actions {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
</style>
