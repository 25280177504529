import { $GQL } from '@GQL/index';
import { type PaymentMethod } from '@nextgenleads/banker-driver';
import { formatDate } from '@/lib/Filters';

export async function getPaymentMethodsAsOptions(
	account_id: string,
	refresh?: boolean
): Promise<{ label: string; value: string }[]> {
	const query = `query GetPaymentMethods($filters: [[Any]]) {
        paymentMethods(filters: $filters) {
             id
             type
             last_four
			 expiration_date
        }
    }`;

	const filters = [[`account_id = '${account_id}'`, `status <> 'archived'`]];
	const no_cache = refresh ? ['paymentMethods'] : undefined;
	const result = await $GQL.request(query, { filters, no_cache });

	// Filter out the expired cards
	const payment_methods = result.paymentMethods.filter((payment_method) => {
		const exp_date_int = parseInt(payment_method.expiration_date.replace('-', ''));
		const date_now_int = parseInt(formatDate(new Date(), 'YYYYMM'));
		return exp_date_int >= date_now_int;
	});

	// Format for options
	return payment_methods.map((payment_method) => {
		return {
			label: `${payment_method.type} (${payment_method.last_four})`,
			value: payment_method.id,
		};
	});
}
