<template>
	<p-button icon="pi pi-plus" label="Add Payment Method" @click="openPaymentMethodModal" />
	<p-dialog
		v-model:visible="payment_modal_open"
		class="add-payment-method-modal"
		:modal="true"
		:dismissable-mask="true"
		@keydown.meta.ctrl="keypressHandler"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="credit-card-plus" size="24px" style="color: var(--color-b)" />
				<strong>Add Payment Method</strong>
			</div>
		</template>
		<p>Enter your card information below to add it to your account as a new payment method.</p>
		<div class="card-section">
			<div class="header">Card Details</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20 align-items-start">
						<div class="field">
							<label class="control-label req">Card Number:</label>
							<p-input-mask
								v-model="payment_method.card_number"
								name="card_number"
								mask="9999 9999 9999 999?9"
								unmask
								slot-char=" "
								:auto-clear="false"
								placeholder="Card Number"
								class="w-full"
							/>
							<div v-if="v$.payment_method.card_number.$error" class="validation-error">
								{{ v$.payment_method.card_number.$errors[0].$message }}
							</div>
						</div>
						<div class="field s">
							<label class="control-label req">Expiration Date:</label>
							<p-input-mask
								v-model="expiration_date"
								name="expiration"
								mask="99/99"
								unmask
								slot-char="MM/YY"
								placeholder="MM/YY"
							/>
							<div v-if="v$.expiration_date.$error" class="validation-error">
								{{ v$.expiration_date.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20 align-items-start">
						<div class="field">
							<label class="control-label req">Name on Card:</label>
							<p-input-text v-model="payment_method.name_on_card" name="name_on_card" placeholder="Cardholder Name" />
							<div v-if="v$.payment_method.name_on_card.$error" class="validation-error">
								{{ v$.payment_method.name_on_card.$errors[0].$message }}
							</div>
						</div>
						<div class="field s">
							<label class="control-label req">CVV:</label>
							<p-password
								v-model="payment_method.card_validation_number"
								:feedback="false"
								name="expiration"
								placeholder="CVV"
							/>
							<div v-if="v$.payment_method.card_validation_number.$error" class="validation-error">
								{{ v$.payment_method.card_validation_number.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card-section">
			<div class="header">Billing Address</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Address Line 1:</label>
					<div class="controls">
						<div class="field">
							<p-input-text
								v-model="payment_method.street_1"
								name="street_1"
								placeholder="Address Line 1"
								class="w-full"
							/>
							<div v-if="v$.payment_method.street_1.$error" class="validation-error">
								{{ v$.payment_method.street_1.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Address Line 2:</label>
					<div class="controls">
						<div class="field">
							<p-input-text
								v-model="payment_method.street_2"
								name="street_2"
								placeholder="Address Line 2 (optional)"
								class="w-full"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20 align-items-start">
						<div class="field">
							<label class="control-label req">City:</label>
							<p-input-text v-model="payment_method.city" name="city" placeholder="City" />
							<div v-if="v$.payment_method.city.$error" class="validation-error">
								{{ v$.payment_method.city.$errors[0].$message }}
							</div>
						</div>
						<div class="field xs">
							<label class="control-label req">State:</label>
							<p-dropdown
								v-model="payment_method.state"
								name="state"
								:options="state_options"
								option-label="value"
								option-value="value"
								placeholder="State"
							/>
							<div v-if="v$.payment_method.state.$error" class="validation-error">
								{{ v$.payment_method.state.$errors[0].$message }}
							</div>
						</div>
						<div class="field xs">
							<label class="control-label req">ZIP Code:</label>
							<p-input-mask v-model="payment_method.zip" mask="99999" slot-char=" " name="zip" placeholder="Zipcode" />
							<div v-if="v$.payment_method.zip.$error" class="validation-error">
								{{ v$.payment_method.zip.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field flex justify-content-start align-items-center gap-2">
							<label class="control-label req" for="accept-terms-conditions"
								>I agree to the <router-link :to="{ path: '/terms' }">Terms and Conditions</router-link></label
							>
							<p-checkbox id="accept-terms-conditions" v-model="payment_method.accepted_terms" :binary="true" />
						</div>
					</div>
					<div v-if="v$.payment_method.accepted_terms.$error" class="validation-error">
						{{ v$.payment_method.accepted_terms.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button v-if="testMode" label="Test Card" text @click="setTestCard" />
				<p-button label="Cancel" text @click="payment_modal_open = false" />
				<p-button :loading="loading" label="Add Payment Method" @click="addPaymentMethod" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs, minLength, helpers, maxLength } from '@vuelidate/validators';
import STATES from '@/lib/Data/states.json';
import pDialog from 'primevue/dialog';
import { useSessionStore } from '@/stores/session';
import { insertPaymentMethod } from '@GQL';
import pInputMask from 'primevue/inputmask';
import { isAfter } from 'date-fns';
import { getIPAddress, getIP } from '@/lib/Utils/getIPAddress';
import { useAppStore } from '@/stores/app';

const default_payment_method = {
	account_id: null,
	card_number: '',
	card_validation_number: '',
	street_1: '',
	street_2: '',
	state: '',
	city: '',
	zip: '',
	expiration_date: '', // pattern YYYY-MM
	name_on_card: '',
	accepted_terms: false,
};

const test_payment_method = {
	account_id: null,
	ip_address: null,
	card_number: '4457010000000009',
	card_validation_number: '349',
	street_1: '1 Main St.',
	street_2: '',
	state: 'MA',
	city: 'Burlington',
	zip: '01803-3747',
	expiration_date: '', // pattern YYYY-MM
	name_on_card: 'John & Mary Smith',
	accepted_terms: false,
};

export default {
	name: 'AddPaymentMethodModal',
	components: {
		pDialog,
		pInputMask,
	},
	emits: ['refresh'],
	setup() {
		return {
			sessionStore: useSessionStore(),
			appStore: useAppStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			state_options: [...STATES],
			payment_modal_open: false,
			payment_method: cloneDeep(default_payment_method),
			expiration_date: '',
		};
	},
	validations() {
		return {
			payment_method: {
				card_number: {
					required: helpers.withMessage('Card number required.', required),
				},
				name_on_card: {
					required: helpers.withMessage('Name required.', required),
				},
				card_validation_number: {
					required: helpers.withMessage('CVV required.', required),
					max_length: helpers.withMessage('Max length of 4', maxLength(4)),
				},
				street_1: {
					required: helpers.withMessage('Street address required.', required),
				},
				state: {
					required: helpers.withMessage('State required.', required),
				},
				city: {
					required: helpers.withMessage('City required.', required),
				},
				zip: {
					required: helpers.withMessage('Zip required.', required),
					minLength: minLength(5),
				},
				accepted_terms: {
					sameAs: helpers.withMessage('You must read and accept the terms and conditions.', sameAs(true)),
				},
			},
			expiration_date: {
				required,
				valid: helpers.withMessage('Please enter a valid expiration date in the future', (value: string) => {
					const year = parseInt(value.substring(2, 4));
					const month = parseInt(value.substring(0, 2));
					const exp = new Date(2000 + year, month);
					const now = new Date();
					if (month > 12 || month < 1) {
						return false;
					}
					if (isAfter(now, exp)) {
						return false;
					}
					return true;
				}),
			},
		};
	},
	watch: {
		expiration_date(new_value, old_value) {
			if (new_value !== old_value && new_value.length === 4) {
				this.payment_method.expiration_date = `20${new_value.substring(2, 4)}-${new_value.substring(0, 2)}`;
			}
		},
	},
	computed: {
		testMode() {
			return window.location.origin.indexOf('localhost') > -1;
		},
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.sessionStore.user.account_id ||
				('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.appStore.mpid}-000-000`)
			);
		},
	},
	methods: {
		setTestCard() {
			this.payment_method = cloneDeep(test_payment_method);
			this.expiration_date = '0150';
		},
		reset() {
			this.payment_method = cloneDeep(default_payment_method);
			this.expiration_date = '';
			this.v$.$reset();
		},
		openPaymentMethodModal(event) {
			this.reset();
			this.payment_modal_open = true;
		},
		async addPaymentMethod() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.loading = true;
				const new_payment_method = cloneDeep(this.payment_method);
				new_payment_method.account_id = this.accountId;
				try {
					new_payment_method.ip_address = (await getIPAddress()).ip;
				} catch (err) {
					// do nothing, the IP may just be missing in this case
					new_payment_method.ip_address = (await getIP()).getIPAddress;
				}
				try {
					const res = await insertPaymentMethod(new_payment_method);
					if (res.status === 'failed' || res.status === 'error') {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to register card',
							detail: res.message,
						});
					} else {
						this.$toast.add({
							severity: 'success',
							summary: 'Payment Methods registered',
							life: 3000,
						});

						this.payment_modal_open = false;
						this.$emit('refresh');
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to register card',
						detail: 'Reach out to account manager',
						life: 5000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to register card',
					detail: 'A field is not valid',
					life: 5000,
				});
			}
		},
		keypressHandler(event) {
			if (event.metaKey && event.key === 'k') {
				this.payment_method = cloneDeep(test_payment_method);
				this.expiration_date = '0150';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.card-section {
	max-width: 600px;
}

.header {
	font-size: var(--font-size-base);
	font-weight: bold;
	margin-bottom: 1rem;
}

.input-group {
	label {
		color: var(--gray-50);
	}
}
</style>
@/lib/GQL/mutations/insertPaymentMethod
