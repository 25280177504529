<template>
	<div
		:style="{ maxHeight }"
		:class="['ui ui-code form-control control-border control-focus', { disabled }]"
		@focusout="
			touched = true;
			$emit('validate');
		"
	>
		<slot name="panel"></slot>
		<div :id="id" ref="editor">
			<slot>{{ local_value }}</slot>
		</div>
	</div>
</template>

<script>
import ace from 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/less';
import 'brace/mode/javascript';
import 'brace/theme/sqlserver';
import isNil from 'lodash/isNil';
import log from '@/lib/Log';
import { isEqual } from 'lodash-es';

export default {
	props: {
		disabled: Boolean,
		maxHeight: String,
		name: String,
		settings: {
			type: Object,
			default() {
				return {
					mode: 'json',
					theme: 'sqlserver',
				};
			},
		},
		modelModifiers: {
			default: () => ({}),
		},
		modelValue: [String, Number, Array, Object, Boolean],
	},
	data() {
		return {
			editor: null,
			local_value: this.modelValue,
			ready: false,
		};
	},
	computed: {
		id() {
			if (this.name) {
				return this.name;
			}

			let id = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for (let n = 0; n < 8; n++) {
				id += characters.charAt(Math.floor(Math.random() * characters.length));
			}
			return id;
		},
		mergedSettings() {
			const settings = {};

			if (this.ready) {
				// Trigger
			}

			// Option defaults
			settings.highlightActiveLine = this.settings.highlightActiveLine || false;
			settings.highlightGutterLine = this.settings.highlightGutterLine || false;
			settings.minLines = this.settings.minLines || 8;
			settings.maxLines = this.settings.maxLines || Infinity;
			settings.printMargin = this.settings.printMargin || false;
			settings.tabSize = this.settings.tabSize || 2;
			settings.fontSize = this.settings.fontSize || '13px';

			// Set fonts
			if (this.settings.fontFamily) {
				settings.fontFamily = this.settings.fontFamily;
			}

			return settings;
		},
	},
	watch: {
		modelValue(new_value, old_value) {
			if (new_value === null) {
				new_value = '';
			}
			if (this.modelModifiers.parse) {
				try {
					let stringified_new_value = JSON.stringify(new_value, null, 2);
					if (stringified_new_value !== this.local_value) {
						this.local_value = stringified_new_value;
						this.editor.setValue(stringified_new_value, 1);
					}
				} catch (err) {
					log.warn(`Unable to stringify value for ${this.id}`);
				}
			} else {
				if (new_value !== old_value) {
					if (this.local_value !== new_value) {
						this.local_value = new_value;
						this.editor.setValue(new_value, 1);
					}
				}
			}
			if (this.touched) {
				this.$emit('validate');
			}
		},
		mergedSettings: {
			handler(new_value) {
				if (this.editor) {
					// Set mode and theme
					this.editor.getSession().setMode(`ace/mode/${this.settings.mode || 'json'}`);
					this.editor.setTheme(`ace/theme/${this.settings.theme || 'sqlserver'}`);
					this.editor.getSession().setUseWrapMode(`${this.settings.wrap || 'false'}`);

					// Load settings
					this.editor.setOptions(new_value);
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.editor = ace.edit(this.id);

		// Fix for deprecation
		this.editor.$blockScrolling = Infinity;

		// Ignore doctype warnings
		const session = this.editor.getSession();
		session.on('changeAnnotation', () => {
			const a = session.getAnnotations();
			const b = a.slice(0).filter((item) => item.text.indexOf('DOC') === -1);
			if (a.length > b.length) {
				session.setAnnotations(b);
			}
		});

		// Set the model value
		if (isNil(this.modelValue)) {
			this.$emit('update:modelValue', this.editor.getValue());
		} else {
			if (this.modelModifiers.parse) {
				try {
					let stringified_model_value = JSON.stringify(this.modelValue, null, 2);
					this.editor.setValue(stringified_model_value, 1);
				} catch (err) {
					log.warn(`Unable to stringify value for ${this.id}`);
				}
			} else {
				this.editor.setValue(this.modelValue, 1);
			}
		}

		if (this.disabled) {
			this.editor.setReadOnly(true);
		} else {
			this.editor.on('blur', () => {
				const new_value = (this.local_value = this.editor.getValue());

				if (this.modelModifiers.parse) {
					try {
						// Only trigger change when the parsed value is different
						const parsed_new_value = JSON.parse(new_value);

						if (!isEqual(parsed_new_value, this.modelValue)) {
							this.$emit('update:modelValue', parsed_new_value);
						}
					} catch (err) {
						// The JSON was probably unparsable
					}
				} else {
					this.$emit('update:modelValue', new_value);
				}
			});
		}

		// Hide cursor
		if (this.settings.cursor === 'none' || this.settings.cursor === false) {
			this.editor.renderer.$cursorLayer.element.style.display = 'none';
		}

		if (this.$attrs.onFocus) {
			this.editor.on('focus', this.$attrs.onFocus);
		}
		if (this.$attrs.onBlur) {
			this.editor.on('blur', this.$attrs.onBlur);
		}

		this.ready = true;
	},
	methods: {
		focus() {
			this.editor.focus();
		},
	},
};
</script>

<style lang="less" scoped>
.disabled {
	opacity: 0.65;
}

.ui-code {
	border: 1px solid var(--gray-10);
	border-radius: 3px;
	overflow-y: auto;
}
</style>
