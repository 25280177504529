<template>
	<div class="build-step normalize-source">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Normalize Source</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Properly format and sanitize the source information in the lead.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label class="control-label">Normalization Scripts:</label>
							<p-chips
								add-on-blur
								v-model="modelValue.scripts"
								separator=","
								placeholder="Add scripts to /service/src/build/normalization_scripts"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NormalizeSource',
	emits: ['remove'],
	props: {
		modelValue: {
			type: Object,
			default: {
				scripts: [],
			},
		},
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
