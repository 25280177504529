<template>
	<div id="data-leads-report" class="view-content">
		<div class="page-heading">
			<h1>Live Transfers</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<List />
	</div>
</template>

<script lang="ts">
import List from './List.vue';
export default {
	name: 'DataLeads',
	components: {
		List,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.path.split('/').includes('reports')) {
			this.breadcrumb_items.push(
				{
					label: 'Reports',
					route: '/reports/leads',
				},
				{
					label: 'Data Leads',
					route: '/reports/data-leads',
				}
			);
		} else {
			this.breadcrumb_items.push(
				{
					label: 'Purchases',
					route: '/purchases',
				},
				{
					label: 'Data Leads',
					route: '/purchases/data-leads',
				}
			);
		}
	},
};
</script>
