<template>
	<div class="qualifier explicit-campaign-match">
		<div class="title">
			<div class="name">Explicit Campaign Match</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign if it doesn't explicitly match one of the specified campaign IDs.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Campaign IDs:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-chips add-on-blur v-model="modelValue.campaign_ids" separator="," />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pChips from 'primevue/chips';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'explicitCampaignMatchQualifier',
	emits: ['remove'],
	components: {
		pChips,
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					campaign_ids: [],
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
