<template>
	<div>
		<div class="content">
			<p-card class="network-card">
				<template #content>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="control-label req" for="network">Network Name:</label>
									<div class="p-inputgroup">
										<p-input-text v-model="network.name" />
									</div>
									<div v-if="v$.network.name.$error" class="validation-error">
										{{ v$.network.name.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="control-label req" for="network">Network URL:</label>
									<div class="p-inputgroup">
										<p-input-text v-model="network.url" />
									</div>
									<div v-if="v$.network.url.$error" class="validation-error">
										{{ v$.network.url.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<p-fieldset legend="Contacts">
						<div class="contacts">
							<p-dialog v-model:visible="show_new_contact_modal" modal header="Select Contact Type">
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Contact Type:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="new_contact_type"
													:options="contact_type_options"
													option-label="label"
													option-value="value"
													placeholder="Select Contact Type"
												/>
											</div>
										</div>
									</div>
								</div>
								<template #footer>
									<p-button text label="Cancel" @click="show_new_contact_modal = false" /><p-button
										icon="pi pi-plus"
										label="Add Contact"
										@click="addContact"
									/>
								</template>
							</p-dialog>
							<p-message v-if="!sortedContacts.length" :closable="false">
								Add a contact for this network so we know who to reach out to with questions.
							</p-message>
							<div v-for="contact in sortedContacts" class="contact" :key="contact.id">
								<div class="contact-header">
									<div class="contact-type">{{ startCase(contact.type) }}</div>
									<delete-action
										class="delete-button"
										message="Are you suyre you want to remove this contact?"
										rounded
										@delete="removeContact(contact.id)"
									/>
								</div>
								<div class="contact-body">
									<div class="control-group">
										<div class="inner">
											<label class="control-label req">Full Name:</label>
											<div class="controls">
												<div class="field">
													<p-input-text v-model="contact.name" />
												</div>
											</div>
										</div>
									</div>
									<div class="control-group">
										<div class="inner">
											<div class="controls gap-20">
												<div class="field">
													<label class="control-label">Email:</label>
													<p-input-text v-model="contact.email" />
												</div>
												<div class="field">
													<label class="control-label">Phone:</label>
													<p-input-mask v-model="contact.phone" mask="(999) 999-9999" unmask />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p-button icon="pi pi-plus" label="Add Contact" @click="show_new_contact_modal = true" />
					</p-fieldset>
				</template>
				<template #footer>
					<div class="flex justify-content-between align-items-center">
						<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
						<p-button icon="pi pi-check" label="Save Network" @click.prevent="save" />
					</div>
				</template>
			</p-card>
		</div>
	</div>
</template>

<script lang="ts">
import debounceAsync from 'debounce-async';
import { cloneDeep, snakeCase, sortBy, startCase } from 'lodash-es';
import { ulid } from 'ulid';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import { helpers, required, url } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { checkExistingNetworkId, getNetworkById, insertNetwork, updateNetwork } from '@GQL';
import { contact_type_options } from '@/lib/Options';
import deleteAction from '@/components/widgets/DeleteAction.vue';

const default_network = {
	id: null,
	name: '',
	url: '',
	contacts: [],
};

export default {
	name: 'NetworkForm',
	components: {
		pDialog,
		pMessage,
		deleteAction,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			network: cloneDeep(default_network),
			sources: [],
			new_contact_type: null,
			contact_type_options,
			show_new_contact_modal: false,
		};
	},
	computed: {
		networkId: {
			get() {
				return this.network.id;
			},
			set(new_value) {
				this.network.id = snakeCase(new_value);
			},
		},
		sortedContacts() {
			return sortBy(this.network.contacts, 'type');
		},
	},
	validations() {
		return {
			network: {
				id: {
					required,
					uniqueId: helpers.withMessage('A network with this ID already exists', this.uniqueId),
				},
				name: { required },
				url: { required, url },
			},
		};
	},
	async mounted() {
		try {
			if (!this.$route.meta.new) {
				this.loading = true;
				this.network = await getNetworkById(this.$route.params.network_id);

				// Add IDs to contacts
				this.network.contacts = this.network.contacts.map((contact) => {
					contact.id = ulid();
					return contact;
				});

				this.loading = false;
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get network',
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		startCase,
		openNewContactModal() {
			this.new_contact_type = null;
			this.show_new_contact_modal = true;
		},
		addContact() {
			this.network.contacts.push({
				id: ulid(),
				name: '',
				email: '',
				phone: '',
				type: this.new_contact_type,
			});
			this.show_new_contact_modal = false;
		},
		removeContact(contact_id) {
			const index = this.network.contacts.findIndex((contact) => {
				return contact.id === contact_id;
			});

			if (index > -1) {
				this.network.contacts.splice(index, 1);
			}
		},
		uniqueId() {
			return debounceAsync(async function (id) {
				const id_exists = await checkExistingNetworkId(id);
				return !id_exists;
			}, 500);
		},
		async save() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				const new_network = cloneDeep(this.network);

				// Remove IDs from contacts
				new_network.contacts = new_network.contacts.map((contact) => {
					delete contact.id;
					return contact;
				});

				try {
					let result;
					if (this.$route.meta.new) {
						new_network.created_at = new Date();
						result = await insertNetwork(new_network);
					} else {
						new_network.modified_at = new Date();
						result = await updateNetwork(this.network.id, new_network);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.$route.meta.new ? 'inserted new' : 'updated'} network`,
							detail: `${new_network.id}`,
							life: 3000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} network`,
							detail: `${new_network.id}`,
							life: 3000,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} network`,
						detail: `${new_network.id}`,
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
label {
	font-size: var(--font-size-sm);
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.sub-value {
	color: var(--gray-35);
	display: inline-block;
	font-size: var(--font-size-sm);
	font-style: italic;
}

.network-card {
	max-width: 800px;
}

.contact {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	margin-bottom: 1rem;
	overflow: hidden;

	.contact-header {
		align-items: center;
		background-color: var(--color-b-lightest);
		border-bottom: 1px solid var(--gray-20);
		display: flex;
		font-weight: bold;
		height: 60px;
		justify-content: space-between;
		padding: 20px;
	}

	.contact-body {
		padding: 20px;
	}

	.delete-button :deep(.p-button) {
		background: transparent !important;
		border: 0;

		.mdi-icon {
			color: var(--gray-35);
		}
	}
}
</style>
