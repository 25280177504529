<template>
	<p-dialog
		v-model:visible="disposition_modal_open"
		class="disposition-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
		style="max-width: 500px"
	>
		<template #header>
			<div class="flex align-items-center w-full">
				<icon type="list-status" size="1.5rem" style="margin-right: 1rem" />
				<span class="font-bold">{{ local_conversion ? 'Update' : 'Create' }} Disposition</span>
			</div>
		</template>
		<div class="conversion-content">
			<ul v-if="local_conversion" class="table-list">
				<div class="heading pt-0">Purchase Details</div>
				<li>
					<div class="label">Purchase ID</div>
					<div class="value">
						{{ local_conversion.purchase_id }}
					</div>
				</li>
				<li>
					<div class="label">Lead ID</div>
					<div class="value">
						{{ local_conversion.lead_id }}
					</div>
				</li>
				<li>
					<div class="label">Current Status:</div>
					<div class="value">
						{{ local_conversion.disposition }}
					</div>
				</li>
			</ul>
			<p-fieldset legend="Update Disposition">
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="disposition-reason">Disposition:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="disposition-reason"
									v-model="disposition"
									:options="disposition_options"
									optionLabel="label"
									optionValue="value"
									placeholder="Select a Disposition"
								></p-dropdown>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-content-end mt-2">
					<p-button v-if="local_conversion" label="Update Disposition" @click="updateDisposition()">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="check" size="24px" />
							</div>
						</template>
					</p-button>
					<p-button v-else label="Create Disposition" @click="createDisposition()">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="check" size="24px" />
							</div>
						</template>
					</p-button>
				</div>
			</p-fieldset>
		</div>
	</p-dialog>
</template>

<script lang="ts">
import pMessage from 'primevue/message';
import pDialog from 'primevue/dialog';
import { getConversion, insertConversion, updateConversion } from '@GQL';
import type { Conversion } from '@nextgenleads/marketplace-driver';
import { useSessionStore } from '@/stores/session';
import { useVuelidate } from '@vuelidate/core';
import log from '@/lib/Log';

export default {
	name: 'Disposition',
	components: {
		pDialog,
		pMessage,
	},
	emits: ['update'],
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		const disposition_options = [
			{
				label: 'Sold',
				value: 'Sold',
			},
			{
				label: 'Quoted',
				value: 'Quoted',
			},
			{
				label: 'Attempting Contact',
				value: 'Attempting Contact',
			},
			{
				label: 'Contacted - Working',
				value: 'Contacted - Working',
			},
			{
				label: 'Contacted - Unqualified / Unsold',
				value: 'Contacted - Unqualified / Unsold',
			},
			{
				label: 'Invalid Contact Info',
				value: 'Invalid Contact Info',
			},
		];

		return {
			disposition_modal_open: false,
			purchase_id: '',
			lead_id: '',
			disposition: '',
			disposition_options,
			local_conversion: null as Conversion,
		};
	},
	computed: {
		account_id() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
	},
	methods: {
		async open(purchase_id, lead_id) {
      this.purchase_id = purchase_id;
      this.lead_id = lead_id;
      this.local_conversion = null;
      this.disposition = '';

      try {
        const response = await this.getConversionData(purchase_id);

        if (response) {
          this.local_conversion = response;
          this.disposition = this.local_conversion.disposition;
        }

        this.disposition_modal_open = true;
      }
      catch(err) {
        log.error(err, 'From DispoModal');
      }
		},
		async getConversionData(purchase_id) {
			try {
				const result: Conversion = await getConversion(purchase_id);
				if (result) {
					return result;
				}
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Conversion Info',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
		},
		async createDisposition() {
			try {
				const res = await insertConversion({
					purchase_id: this.purchase_id,
					lead_id: this.lead_id,
					disposition: this.disposition,
					source: 'UI',
					created_by: this.sessionStore.user.id,
					account_id: this.account_id,
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Created Disposition',
					life: 3000,
				});
				this.$emit('update', {
					purchase_id: this.purchase_id,
					disposition: this.disposition,
				});
				this.disposition_modal_open = false;
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Conversion Info',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
		},
		async updateDisposition() {
			try {
				await updateConversion(this.purchase_id, {
					disposition: this.disposition,
					source: 'UI',
					created_by: this.sessionStore.user.id,
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Updated Disposition',
					life: 3000,
				});
				this.$emit('update', {
					purchase_id: this.purchase_id,
					disposition: this.disposition,
				});
				this.disposition_modal_open = false;
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update Conversion Info',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.table-list {
	.value {
		font-size: 0.8rem;
	}
}
</style>
