<template>
	<div class="content-campaign-schedule">
		<div v-if="v$.noActiveSchedules.$error" class="validation-error top">
			{{ v$.noActiveSchedules.$errors[0].$message }}
		</div>
		<p>
			Set a schedule for your campaign or adjust the bid depending on the time of day and/or day of week. If the
			campaign schedule is enabled, the campaign will only bid during periods that are included in the schedule below.
		</p>

		<div class="schedule-switch">
			<div class="switch">
				<p-input-switch v-model="campaignStore.campaign.schedule.enabled" />
			</div>
			Enable Custom Campaign Schedule
		</div>
		<p-fieldset v-if="campaignStore.campaign.schedule.enabled" class="purchasing-schedule" legend="Schedule Settings">
			<p-message v-if="campaignStore.campaign.mode === 'advanced'" :closable="false">
				If you have schedules that overlap, the highest bid will be used.
			</p-message>
			<div class="timezone"><strong>Timezone:</strong> {{ accountTimezone }}</div>
			<template v-if="noActiveSchedules">
				<p-message severity="warn" :closable="false"
					>You do not have any active schedules. Disable scheduling or add at least one active schedule to your
					campaign.</p-message
				>
			</template>
			<div class="custom-bids">
				<template v-for="custom_bid in sortedCustomBids" :key="custom_bid.id">
					<custom-bid
						:custom-bid="custom_bid"
						:mode="campaignStore.campaign.mode"
						type="schedule"
						none-option
						@edit="editCustomBid"
						@delete="deleteCustomBid"
					>
						<strong>{{ customBidTime(custom_bid) }}</strong>
						<div class="sub-value">
							{{ customBidDays(custom_bid) }}
						</div>
					</custom-bid>
				</template>
			</div>
			<div class="actions justify-content-between flex gap-2 flex-wrap">
				<p-button icon="pi pi-plus" label="Add Schedule" @click="addNewCustomBid" />
			</div>
		</p-fieldset>
	</div>
	<p-dialog id="location-modal" v-model:visible="show_bid_modal" :modal="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="calendar-clock" size="24px" style="color: var(--color-b)" />
				<strong>Create Schedule</strong>
			</div>
		</template>
		<div v-if="v$.$validationGroups.custom_schedule_bid.$error" class="validation-error">
			{{ v$.$validationGroups.custom_schedule_bid.$errors[0].$message }}
		</div>
		<p-fieldset legend="Choose Days of the Week">
			<p>These are the days your campaign will be active.</p>
			<div class="days-options">
				<a
					v-for="(day, day_index) in days"
					:key="day"
					class="filter-toggle"
					:class="{ active: custom_bid_days.includes(day_index + 1) }"
					@click="handleLocalDay(day_index + 1)"
				>
					{{ day }}
				</a>
			</div>
			<div class="quick-select">
				<a @click.prevent="selectNone">Select None</a> | <a @click.prevent="selectAll">Select All</a> |
				<a @click.prevent="selectWeekdays">Select Weekdays</a> |
				<a @click.prevent="selectWeekend">Select Weekend</a>
			</div>
		</p-fieldset>
		<p-fieldset legend="Choose Hours of the Day">
			<p>During the days above, your campaign will be active between these hours.</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label">Start Time:</label>
							<p-dropdown
								v-model="custom_bid_start"
								style="min-width: 200px"
								class="max-w-20rem"
								placeholder="Select Start Time"
								:options="start_times_options"
								option-label="label"
								option-value="value"
								name="start_time"
							/>
						</div>
						<div class="field">
							<label class="control-label">End Time:</label>
							<p-dropdown
								v-model="custom_bid_end"
								style="min-width: 200px"
								class="max-w-20rem"
								placeholder="Select End Time"
								:options="availableEndTimes"
								option-value="value"
								option-label="label"
								name="end_time"
							/>
						</div>
						<div class="field">
							<label class="control-label">Timezone:</label>
							<p-input-text :value="accountTimezone" disabled />
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<template #footer>
			<p-button label="Cancel" text @click="show_bid_modal = false" />
			<p-button label="Save Schedule" icon="pi pi-check" @click="saveBid" />
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { cloneDeep, get, merge, sortBy } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import STARTTIMES from '@/lib/Data/times.json';
import ENDTIMES from '@/lib/Data/end_times.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import { useAccountStore } from '@/stores/account';
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';
import customBid from '@/components/widgets/CustomBid.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const default_schedule_bid = {
	id: null,
	name: '',
	type: 'schedule',
	match: [],
	method: 'none',
	amount: 1,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Schedule is not active',
};

export default {
	name: 'ScheduleContent',
	components: {
		customBid,
		deleteAction,
		pDialog,
		pMessage,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			schedule_bid: cloneDeep(default_schedule_bid),
			show_bid_modal: false,
			show_paused: false,
			custom_bid_days: [],
			custom_bid_start: 600,
			custom_bid_end: null,
			start_times_options: STARTTIMES,
			end_times_options: ENDTIMES,
			days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		};
	},
	computed: {
		...mapStores(useAccountStore, useCampaignStore),
		accountTimezone() {
			// SINCE TIMEZONE CAN BE NULL in the acccount store....
			const value =
				get(this.accountStore, 'account.settings.timezone') ||
				get(this.$root.sessionStore, 'account.settings.timezone') ||
				'America/Los_Angeles';

			const tz = TIMEZONES.find((tz) => {
				return tz.id === value;
			});

			if (tz) return tz.label;
		},
		availableEndTimes() {
			return this.end_times_options.filter((time) => time.value > this.custom_bid_start);
		},
		sortedCustomBids() {
			return sortBy(
				this.campaignStore.campaign.bids.filter((custom_bid) => custom_bid.type === 'schedule'),
				(custom_bid) => {
					return get(custom_bid, 'match[0].comparator.value[0]', 0);
				}
			);
		},
		noActiveSchedules() {
			return (
				this.sortedCustomBids.length === 0 ||
				this.sortedCustomBids.filter((custom_bid) => {
					return custom_bid.status === 'active';
				}).length === 0
			);
		},
	},
	validations() {
		return {
			noActiveSchedules: {
				isFalse: helpers.withMessage(
					'You must have at least one active schedule or disable the campaign schedule',
					(value) => {
						const activeSchedules = this.campaignStore.campaign.bids.filter((custom_bid) => {
							return custom_bid.type === 'schedule' && custom_bid.status === 'active';
						});

						return !this.campaignStore.campaign.schedule.enabled || activeSchedules.length > 0;
					}
				),
				$lazy: true,
			},
			custom_bid_days: {
				required: helpers.withMessage('Please select at least one day to run your campaign', required),
				$lazy: true,
			},
			custom_bid_start: {
				required: helpers.withMessage('A schedule start time is required', required),
				$lazy: true,
			},
			custom_bid_end: {
				required: helpers.withMessage('A schedule end time is required', required),
				$lazy: true,
			},
			$validationGroups: {
				campaign: ['noActiveSchedules'],
				custom_schedule_bid: ['custom_bid_days', 'custom_bid_start', 'custom_bid_end'],
			},
		};
	},
	methods: {
		customBidDays(custom_bid) {
			const day_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'date.day_of_week';
			});

			if (day_match) {
				return day_match.comparator.value
					.sort()
					.map((day_int) => {
						return this.days[day_int - 1];
					})
					.join(', ');
			}
			return 'None selected';
		},
		customBidTime(custom_bid) {
			const time_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'date.time';
			});

			if (time_match) {
				const start_time = STARTTIMES.find((time) => {
					return time.value === time_match.comparator.value[0];
				});

				const end_time = ENDTIMES.find((time) => {
					return time.value === time_match.comparator.value[1];
				});

				if (start_time && end_time) {
					return start_time.label.replace(' ', '') + ' - ' + end_time.label.replace(' ', '');
				}
			}
			return 'Schedule Error';
		},
		resetModalState() {
			this.v$.$reset();
			this.custom_bid_days = [];
			this.custom_bid_start = 600;
			this.custom_bid_end = null;
			this.schedule_bid = cloneDeep(default_schedule_bid);
		},
		addNewCustomBid() {
			this.resetModalState();
			this.show_bid_modal = true;
		},
		editCustomBid(bid_id: string) {
			this.resetModalState();

			// Load the bid data
			const custom_bid = this.campaignStore.campaign.bids.find((custom_bid) => custom_bid.id === bid_id);

			if (custom_bid) {
				this.schedule_bid = merge({}, default_schedule_bid, custom_bid);

				const days_match = custom_bid.match.find((condition) => condition.target.path === 'date.day_of_week');
				if (days_match) {
					this.custom_bid_days = days_match.comparator.value;
				}

				const time_match = custom_bid.match.find((condition) => condition.target.path === 'date.time');
				if (time_match) {
					this.custom_bid_start = time_match.comparator.value[0];
					this.custom_bid_end = time_match.comparator.value[1];
				}
			}

			this.show_bid_modal = true;
		},
		deleteCustomBid(bid_id: string) {
			return this.campaignStore.deleteBid(bid_id);
		},
		async saveBid() {
			await this.v$.$validate();
			const is_valid = !this.v$.$validationGroups.custom_schedule_bid.$invalid;

			if (is_valid) {
				const custom_bid = cloneDeep(this.schedule_bid);
				custom_bid.match = [
					{
						target: {
							path: 'date.day_of_week',
						},
						strategy: 'one_of',
						comparator: {
							value: this.custom_bid_days,
						},
					},
					{
						target: {
							path: 'date.time',
						},
						strategy: 'in_range',
						comparator: {
							value: [this.custom_bid_start, this.custom_bid_end],
						},
					},
				];

				if (custom_bid.id) {
					this.campaignStore.saveBid(custom_bid);
				} else {
					this.campaignStore.addBid(custom_bid);
				}

				// Reset the modal state
				this.show_bid_modal = false;
			}
		},
		handleLocalDay(day) {
			if (this.custom_bid_days.includes(day)) {
				this.custom_bid_days = this.custom_bid_days.filter((item) => item !== day);
			} else {
				this.custom_bid_days.push(day);
			}
		},
		selectNone() {
			this.custom_bid_days = [];
		},
		selectAll() {
			this.custom_bid_days = [1, 2, 3, 4, 5, 6, 7];
		},
		selectWeekdays() {
			this.custom_bid_days = [2, 3, 4, 5, 6];
		},
		selectWeekend() {
			this.custom_bid_days = [1, 7];
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

:deep(.p-message:first-child) {
	margin-top: 0;
}

.content-campaign-schedule {
	font-size: var(--font-size-sm);
}

.schedule-switch {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: var(--font-size-sm);
	font-weight: bold;
	gap: 10px;
	justify-content: flex-start;
	margin: 10px 0;
}

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.purchasing-schedule {
	font-size: var(--font-size-sm);
}

.days-options {
	.days-label {
		.mobile({
			flex-basis: 100%;
		});
	}
}

.quick-select {
	font-size: var(--font-size-sm);
	margin: 1rem 0;

	a {
		cursor: pointer;
	}
}

.time-options {
	font-size: var(--font-size-sm);
	padding: 1rem 0;

	.timezone .name {
		font-weight: bold;
		padding: 0.75rem 0;
	}
}

.days {
	display: flex;
	flex: 1 0 auto;
	flex-flow: row wrap;

	.active-day {
		background: var(--color-b);
		border-radius: 16px;
		color: white;
		display: inline-block;
		padding: 0.5rem 0.75rem;
	}
}
</style>
