<template>
	<div class="date-picker">
		<label>
			<span v-if="label" class="form-label">{{ label }}</span>
			<p-calendar
				v-model="localValue"
				:selection-mode="mode"
				:manual-input="true"
				date-format="yy-mm-dd"
				show-time
				show-seconds
				:show-icon="showIcon"
				icon-display="input"
				@hide="updateTimeValues"
			>
				<template v-if="showTime" #footer>
					<div class="p-datepicker-footer">
						<div class="timepicker">
							<div class="time-segment hours">
								<p-text
									v-model="time.hours"
									class="p-inputtext-sm"
									@keydown="updateTimeValue($event, time, 'hours', 23)"
									@input="limitTimeLength($event, time, 'hours')"
									@blur="sanitizeValue($event, time, 'hours', 23)"
								/>
							</div>
							<div class="separator">:</div>
							<div class="time-segment minutes">
								<p-text
									v-model="time.minutes"
									class="p-inputtext-sm"
									@keydown="updateTimeValue($event, time, 'minutes')"
									@input="limitTimeLength($event, time, 'minutes')"
									@blur="sanitizeValue($event, time, 'minutes')"
								/>
							</div>
							<div class="separator">:</div>
							<div class="time-segment seconds">
								<p-text
									v-model="time.seconds"
									class="p-inputtext-sm"
									@keydown="updateTimeValue($event, time, 'seconds')"
									@input="limitTimeLength($event, time, 'seconds')"
									@blur="sanitizeValue($event, time, 'seconds')"
								/>
							</div>
							<gutter size="20px" />
							<div class="p-buttonset">
								<p-button v-tooltip.top="'Start of Day'" icon="pi pi-step-backward" @click="setTimeTo(time, 'start')" />
								<p-button v-tooltip.top="'Current Time'" icon="pi pi-stopwatch" @click="setTimeTo(time, 'now')" />
								<p-button v-tooltip.top="'End of Day'" icon="pi pi-step-forward" @click="setTimeTo(time, 'end')" />
							</div>
						</div>
					</div>
				</template>
			</p-calendar>
		</label>
	</div>
</template>

<script lang="ts">
import { padStart } from 'lodash-es';
import pText from 'primevue/inputtext';
import pCalendar from 'primevue/calendar';
import dayjs from 'dayjs';

export default {
	name: 'DatePicker',
	components: {
		pCalendar,
		pText,
	},
	props: {
		label: {
			type: String,
			default: 'Form Field',
		},
		modelValue: {
			type: [String, Date, null],
			required: true,
		},
		mode: {
			type: String,
			default: 'single',
		},
		showTime: Boolean,
		showIcon: {
			type: Boolean,
			default: true,
		},
		defaultTime: {
			type: Object,
			default() {
				return {
					hours: '00',
					minutes: '00',
					seconds: '00',
				};
			},
		},
	},
	data() {
		return {
			time: {
				hours: this.defaultTime.hours,
				minutes: this.defaultTime.minutes,
				seconds: this.defaultTime.seconds,
			},
		};
	},
	computed: {
		localValue: {
			get() {
				let parsed_value = this.modelValue;
				if (this.modelValue && !(this.modelValue instanceof Date)) {
					parsed_value = dayjs(this.modelValue).toDate();
					this.$emit('update:modelValue', parsed_value);
					return parsed_value;
				}

				if (this.showTime) {
					this.time.hours = padStart(dayjs(parsed_value).hour().toString(), 2, '0');
					this.time.minutes = padStart(dayjs(parsed_value).minute().toString(), 2, '0');
					this.time.seconds = padStart(dayjs(parsed_value).second().toString(), 2, '0');
				}

				return parsed_value;
			},
			set(new_value) {
				if (new_value !== this.modelValue) {
					this.$emit(
						'update:modelValue',
						dayjs(new_value).hour(+this.time.hours).minute(+this.time.minutes).second(+this.time.seconds).toDate()
					);
				}
			},
		},
	},
	methods: {
		updateTimeValue(event, value, segment, max = 59) {
			let number_pattern = new RegExp('[0-9]|Tab|Backspace|ArrowLeft|ArrowRight|Delete');
			if (!number_pattern.test(event.key) && !event.metaKey && !event.ctrlKey) {
				event.preventDefault();
			}

			let new_value = parseInt(value[segment]);
			if (event.key === 'ArrowUp') {
				if (event.shiftKey) {
					new_value += 10;
				} else {
					new_value++;
				}
				if (new_value > max) new_value = 0;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
			if (event.key === 'ArrowDown') {
				if (event.shiftKey) {
					new_value -= 10;
				} else {
					new_value--;
				}
				if (new_value < 0) new_value = max;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
		},
		limitLength(event, value, segment) {
			if (event.target.value.length > 2) {
				value[segment] = event.target.value.slice(-2);
			}
		},
		sanitizeValue(event, value, segment, max = 59) {
			if (value[segment] > max) {
				value[segment] = max;
			}
		},
		setTimeTo(value, when) {
			if (when === 'start') {
				value.hours = '00';
				value.minutes = '00';
				value.seconds = '00';
			}
			if (when === 'now') {
				let now = new Date();
				value.hours = padStart(now.getHours().toString(), 2, '0');
				value.minutes = padStart(now.getMinutes().toString(), 2, '0');
				value.seconds = padStart(now.getSeconds().toString(), 2, '0');
			}
			if (when === 'end') {
				value.hours = '23';
				value.minutes = '59';
				value.seconds = '59';
			}
		},
		updateTimeValues() {
			if (this.localValue && this.showTime) {
				const value = dayjs(this.localValue)
					.hour(+this.time.hours)
					.minute(+this.time.minutes)
					.second(+this.time.seconds)
					.toDate();
				this.$emit('update:modelValue', value);
			}
		},
	},
};
</script>

<style scoped lang="less">
.date-picker {
	width: 100%;

	.form-label {
		display: block;
		margin-bottom: 0.25em;
	}

	label,
	.p-calendar {
		width: 100%;
	}
}
</style>

<style lang="less">
.p-datepicker {
	> .p-timepicker {
		display: none;
	}

	.p-datepicker-footer {
		padding: 20px;
		text-align: center;
	}

	.timepicker {
		align-items: center;
		display: flex;

		> .time-segment {
			max-width: 50px;

			.p-inputtext {
				max-width: 100%;
				text-align: center;
			}
		}

		> .separator {
			text-align: center;
			width: 10px;
		}
	}
}
</style>
