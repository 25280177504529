<template>
	<div class="content-location-targeting">
		<!-- <div v-if="v$.locationTargeting.$error" class="validation-error top">
			{{ v$.locationTargeting.$errors[0].$message }}
		</div> -->
		<p>
			Create state or ZIP code groups to target below. The map shows which states are being targeted by this campaign.
			Bidding can be adjusted at the group level. ZIP code bids will take priority over state-level bids.
		</p>
		<div class="map">
			<StateSelector name="totalTargetedStates" v-model="totalTargetedStates" disabled :groups="stateGroups" />
		</div>
		<div class="totals">
			<div>Total Targeted States: {{ totalTargetedStates.length }}</div>
			<div>Total Targeted Zipcodes: {{ totalTargetedZips.length }}</div>
		</div>
		<div class="custom-bids">
			<template v-for="custom_bid in sortedCustomBids" :key="custom_bid.id">
				<custom-bid
					:custom-bid="custom_bid"
					:mode="campaignStore.campaign.mode"
					type="source"
					none-option
					@edit="editCustomBid"
					@delete="deleteCustomBid"
					@mouseover="addHoverClass(custom_bid.id)"
					@mouseout="removeHoverClass()"
				>
					<strong>{{ custom_bid.name }}</strong>
					<div class="sub-value">
						<template v-if="custom_bid.match[0].target.path === 'lead.state'">
							{{ custom_bid.match[0].comparator.value.length }} state(s) targeted
						</template>
						<template v-else>{{ custom_bid.match[0].comparator.value.length }} ZIP code(s) targeted</template>
					</div>
				</custom-bid>
			</template>
		</div>
		<p-message v-if="sortedCustomBids.length === 0" :closable="false"
			>Add location groups so you only receive leads from certain states.</p-message
		>
		<div class="actions justify-content-between flex gap-2 flex-wrap">
			<p-button icon="pi pi-plus" label="Add Location Group" @click="addNewCustomBid" />
		</div>
		<p-dialog id="location-modal" v-model:visible="show_bid_modal" style="width: 760px" :modal="true">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="map-marker-circle" size="24px" style="color: var(--color-b)" />
					<strong>Location Bid</strong>
				</div>
			</template>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Location Group Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text
								id="location-group"
								ref="custom_bid_name_input"
								v-model="location_bid.name"
								aria-describedby="location-group-help"
								autofocus
								placeholder="Enter a group name: I.E. Large States"
							/>
							<div v-if="v$.location_bid.name.$error" class="validation-error">
								{{ v$.location_bid.name.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 mb-3">
				<div for="location-type" style="font-size: var(--font-size-sm); font-weight: bold">Location Type:</div>
				<div class="flex flex-wrap gap-3 align-items-center">
					<div class="flex align-items-center">
						<p-radio-button v-model="custom_bid_mode" input-id="state" name="state" value="state" />
						<label for="state" class="ml-2">State</label>
					</div>
					<div class="flex align-items-center m-2">
						<p-radio-button v-model="custom_bid_mode" input-id="zipcode" name="zip" value="zip" />
						<label for="zipcode" class="ml-2">Zipcode</label>
					</div>
				</div>
			</div>
			<div v-if="custom_bid_mode === 'state'" class="state-select">
				<div class="modal-map">
					<StateSelector v-model="custom_bid_states" :disabledStates="disabledStates" />
				</div>
				<div class="state-options">
					<div class="line-height-3">
						<a class="cursor-pointer" @click.prevent="selectAllAvailable">Select All Available</a>
						| <a class="cursor-pointer" @click.prevent="selectNone">Select None</a>
					</div>
					<div v-if="v$.custom_bid_states.$error" class="validation-error">
						{{ v$.custom_bid_states.$errors[0].$message }}
					</div>
					<ul class="options">
						<li v-for="state of state_options" :key="state.value">
							<div v-if="disabledStates.includes(state.value)" class="flex align-items-center">
								<p-checkbox
									v-model="custom_bid_states"
									:input-id="state.label"
									name="state"
									:value="state.value"
									disabled
									:checked="true"
								>
									<template #icon>
										<CheckIcon class="icon" />
									</template>
								</p-checkbox>
								<label :for="`state-option-${state.label}`" style="color: var(--gray-40)">{{ state.label }}</label>
							</div>
							<div v-else class="flex align-items-center">
								<p-checkbox
									v-model="custom_bid_states"
									:input-id="`state-option-${state.label}`"
									name="state"
									:value="state.value"
								/>
								<label :for="`state-option-${state.label}`">{{ state.label }}</label>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div v-if="custom_bid_mode === 'zip'" class="zip-select flex flex-column">
				<div class="mb-2">Enter 5 digit zipcodes below separated by commas. Non-numerical values will be removed.</div>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<p-textarea v-model="customBidZips" />
								<div v-if="v$.custom_bid_zips.$error" class="validation-error">
									{{ v$.custom_bid_zips.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
			</div>
			<template #footer>
				<p-button label="Cancel" text @click="show_bid_modal = false" />
				<p-button label="Save Location Group" icon="pi pi-check" @click="saveBid" />
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import STATES from '@/lib/Data/states.json';
import { cloneDeep, flatten, merge, sortBy, without, xor } from 'lodash-es';
import StateSelector from '@/components/widgets/StateSelector.vue';
import customBid from '@/components/widgets/CustomBid.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import CheckIcon from 'primevue/icons/check';
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import pTextarea from 'primevue/textarea';
import pMessage from 'primevue/message';
import { type CustomBidWithId, useCampaignStore } from '@/stores/campaign';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import type { CustomBid } from '@nextgenleads/marketplace-driver';
import type { CustomFilterCondition } from '@nextgenleads/custom-filters';
import { nextTick } from 'vue';

const default_location_bid = {
	id: null,
	name: '',
	type: 'location',
	match: [],
	method: 'none',
	amount: 1,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Location is excluded from targeting',
};

export default {
	name: 'LocationContent',
	components: {
		CheckIcon,
		customBid,
		deleteAction,
		pDialog,
		pTextarea,
		pChips,
		pMessage,
		StateSelector,
	},
	setup() {
		return {
			campaignStore: useCampaignStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			custom_bid_id: null,
			custom_bid_states: [],
			original_custom_bid_states: [],
			custom_bid_zips: [],
			custom_bid_mode: 'state',
			location_bid: cloneDeep(default_location_bid),
			show_bid_modal: false,
			state_options: STATES,
			show_paused: false,
		};
	},
	computed: {
		customBidZips: {
			get() {
				return this.custom_bid_zips.join(', ');
			},
			set(new_value) {
				const regex = /\d{5}/g;
				const matches = new_value.match(regex);
				if (matches) {
					this.custom_bid_zips = matches;
				} else this.custom_bid_zips = [];
			},
		},
		// Used for highlighting states on hover
		stateGroups() {
			const stateGroups = this.campaignStore.campaign.bids.filter((custom_bid: CustomBid) => {
				// is targetting state (see matches' target path)
				return (
					custom_bid.type === 'location' &&
					custom_bid.status === 'active' &&
					custom_bid.match[0].target.path.indexOf('state') > -1
				);
			});

			const groups = {};
			stateGroups.forEach((state_group) => {
				groups[`states-${state_group.id}`] = state_group.match[0].comparator.value;
			});

			return groups;
		},
		// Used for displaying the total number of targeted states
		totalTargetedStates() {
			const states = this.campaignStore.campaign.bids
				.filter((custom_bid: CustomBid) => {
					// is targetting state (see matches' target path)
					return (
						custom_bid.status === 'active' &&
						custom_bid.type === 'location' &&
						custom_bid.match[0].target.path.indexOf('state') > -1
					);
				})
				.reduce((a, b) => {
					return a.concat(b.match[0].comparator.value || []);
				}, []);

			return states;
		},
		// Used for displaying the total number of targeted zips
		totalTargetedZips() {
			const zips = this.campaignStore.campaign.bids
				.filter((custom_bid: CustomBid) => {
					// Is targetting state (see matches' target path)

					return (
						custom_bid.status === 'active' &&
						custom_bid.type === 'location' &&
						custom_bid.match[0].target.path.indexOf('zip') > -1
					);
				})
				.reduce((a, b) => {
					return a.concat(b.match[0].comparator.value || []);
				}, []);

			return zips;
		},
		sortedCustomBids() {
			return sortBy(
				this.campaignStore.campaign.bids.filter((custom_bid) => custom_bid.type === 'location'),
				(custom_bid) => {
					return custom_bid.name;
				}
			);
		},
		disabledStates() {
			const states = this.campaignStore.campaign.bids
				.filter((custom_bid: CustomBidWithId) => {
					// is targetting state (see matches' target path)
					return (
						custom_bid.id !== this.location_bid.id &&
						custom_bid.status === 'active' &&
						custom_bid.type === 'location' &&
						custom_bid.match[0].target.path.indexOf('state') > -1
					);
				})
				.reduce((a, b) => {
					return a.concat(b.match[0].comparator.value || []);
				}, []);

			return states;
		},
		locationTargeting() {
			return this.totalTargetedStates.length + this.totalTargetedZips.length;
		},
	},
	validations() {
		return {
			// locationTargeting: {
			// 	required: helpers.withMessage('Targeting for at least 1 state or ZIP code must be active', minValue(1)),
			// },
			location_bid: {
				name: {
					required: helpers.withMessage('This location group requires a name', required),
					isUnique: helpers.withMessage('A location group with this name already exists', (value) => {
						return this.campaignStore.isUniqueCustomBid(value, 'location', this.custom_bid_id);
					}),
					$lazy: true,
				},
			},
			custom_bid_states: {
				required: helpers.withMessage('Please select at least one state to target', (v) => {
					if (this.custom_bid_mode === 'state') {
						if (v.length === 0) return false;
					}
					return true;
				}),
			},
			custom_bid_zips: {
				required: helpers.withMessage('Please enter at least one ZIP code to target', (v) => {
					if (this.custom_bid_mode === 'zip') {
						if (v.length === 0) return false;
					}
					return true;
				}),
			},
			$validationGroups: {
				custom_location_bid: ['location_bid.name', 'custom_bid_states', 'custom_bid_zips'],
			},
		};
	},
	methods: {
		without,
		addHoverClass(id) {
			document.querySelectorAll(`.states-${id}`).forEach((el) => el.classList.add('highlight'));
		},
		removeHoverClass() {
			document.querySelectorAll(`.state.highlight`).forEach((el) => el.classList.remove('highlight'));
		},
		resetModalState() {
			this.v$.$reset();
			this.location_bid = cloneDeep(default_location_bid);
			this.custom_bid_id = null;
			this.custom_bid_mode = 'state';
			this.custom_bid_states = [];
			this.custom_bid_zips = [];
		},
		addNewCustomBid() {
			this.resetModalState();
			this.show_bid_modal = true;
			nextTick(() => {
				this.$refs.custom_bid_name_input.$el.focus();
			});
		},
		editCustomBid(bid_id: string) {
			this.resetModalState();
			this.custom_bid_id = bid_id;

			// Load the bid data
			const custom_bid = this.campaignStore.campaign.bids.find((custom_bid) => custom_bid.id === bid_id);
			if (custom_bid) {
				this.location_bid = merge({}, default_location_bid, custom_bid);
				if (custom_bid.match[0].target.path === 'lead.state') {
					this.custom_bid_mode = 'state';
					this.custom_bid_states = custom_bid.match[0].comparator.value;
					this.original_custom_bid_states = this.custom_bid_states.slice();
				} else {
					this.custom_bid_mode = 'zip';
					this.custom_bid_zips = custom_bid.match[0].comparator.value; // incoming value is an array of strings...
				}
			}
			this.show_bid_modal = true;
			nextTick(() => {
				this.$refs.custom_bid_name_input.$el.focus();
			});
		},
		deleteCustomBid(bid_id: string) {
			return this.campaignStore.deleteBid(bid_id);
		},
		async saveBid() {
			await this.v$.$validate();
			this.v$.custom_bid_states.$touch();
			const is_valid = !this.v$.$invalid;

			if (is_valid) {
				let match = [];
				if (this.custom_bid_mode === 'state') {
					match = [
						{
							target: {
								path: 'lead.state',
							},
							strategy: 'one_of',
							comparator: {
								value: this.custom_bid_states,
							},
						},
					];
				} else {
					match = [
						{
							target: {
								path: 'lead.zip',
							},
							strategy: 'one_of',
							comparator: {
								value: this.custom_bid_zips,
							},
						},
					];
				}

				const custom_bid = {
					id: this.location_bid.id,
					name: this.location_bid.name,
					type: 'location',
					match,
					method: 'none',
					amount: this.location_bid.amount,
					custom_minimum_bid: false,
					minimum_bid: 0,
					finalize: false,
					status: this.location_bid.status,
					dq_message: 'Location excluded from targeting',
				};
				if (this.location_bid.id) {
					this.campaignStore.saveBid(custom_bid);
				} else {
					this.campaignStore.addBid(custom_bid);
				}

				// Reset the modal state
				this.show_bid_modal = false;
				this.location_bid = cloneDeep(default_location_bid);
			}
		},
		printStates(bid: CustomBid) {
			const state_array: string[] = this.getTargetingFromBid(bid);
			let states = '';
			if (state_array.length < 6) {
				state_array.forEach((state, index) => {
					if (index === state_array.length - 1) {
						states += `${state}`;
					} else {
						states += `${state}, `;
					}
				});
			} else {
				states = `${state_array.length} states`;
			}
			return states;
		},
		printZips(bid: CustomBid) {
			const zip_array: string[] = this.getTargetingFromBid(bid);
			let zips = '';
			if (zip_array.length < 4) {
				zip_array.forEach((zip, index) => {
					if (index === zip_array.length - 1) {
						zips += `${zip}`;
					} else {
						zips += `${zip}, `;
					}
				});
			} else {
				zips = `${zip_array.length} Zipcodes`;
			}
			return zips;
		},
		selectAllAvailable() {
			const all_states = [
				'AK',
				'AL',
				'AR',
				'AZ',
				'CA',
				'CO',
				'CT',
				'DC',
				'DE',
				'FL',
				'GA',
				'HI',
				'IA',
				'ID',
				'IL',
				'IN',
				'KS',
				'KY',
				'LA',
				'MA',
				'MD',
				'ME',
				'MI',
				'MN',
				'MO',
				'MS',
				'MT',
				'NC',
				'ND',
				'NE',
				'NH',
				'NJ',
				'NM',
				'NV',
				'NY',
				'OH',
				'OK',
				'OR',
				'PA',
				'RI',
				'SC',
				'SD',
				'TN',
				'TX',
				'UT',
				'VA',
				'VT',
				'WA',
				'WI',
				'WV',
				'WY',
			];

			const available_states = xor(xor(this.totalTargetedStates, this.original_custom_bid_states), all_states);

			if (this.original_custom_bid_states) {
				this.custom_bid_states = available_states;
			} else {
				this.custom_bid_states = all_states;
			}
		},
		selectNone() {
			this.custom_bid_states = [];
		},
		/** This Helper method allows you to get the zips or states being used in the comparator match */
		getTargetingFromBid(bid: CustomBid): string[] {
			if (Array.isArray(bid.match)) {
				// we go through the match and then pull the states out
				const values = bid.match.map((row: CustomFilterCondition) => {
					return row.comparator.value || [];
				});
				return flatten(values);
			} else {
				return flatten(bid.match.comparator.value || []);
			}
		},
		/** Helper Function that allows a bid to be identified as a state or zip one */
		getSubType(bid: CustomBid) {
			const is_location_type = bid.type === 'location';
			if (Array.isArray(bid.match)) {
				return is_location_type &&
					bid.match.some((filter: CustomFilterCondition) => {
						return filter.target.path.indexOf('state') > -1;
					})
					? 'state'
					: 'zip';
			} else {
				return is_location_type && bid.match.target.path.indexOf('state') > -1 ? 'state' : 'zip';
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.content-location-targeting {
	font-size: var(--font-size-sm);
}

.map {
	margin: 1rem auto;

	.tablet-up({
		max-width: 70%;
	});
}

.totals {
	color: var(--gray-65);
	line-height: 1.35em;
}

:deep(.state.highlight) {
	fill: var(--color-b-dark) !important;
}

.groups .active .group:hover {
	background-color: var(--color-b-lightest) !important;
}
</style>

<style lang="less">
@import (reference) '@/styles/responsive';

#location-modal.p-dialog {
	.p-dialog-content {
		.modal-map {
			margin: 1rem auto;
		}

		.options {
			columns: 3;
			list-style: none;
			margin: 1rem auto;
			padding: 0;

			li {
				font-size: var(--font-size-sm);
			}

			.mobile({
				columns: 2;
			});
		}

		.state-options {
			font-size: var(--font-size-sm);
		}
	}

	&.editing {
		button.p-dialog-header-icon.p-dialog-header-close.p-link {
			display: none !important;
		}
	}

	.desktop({
		.p-dialog-content {
			.modal-map {
				max-width: 75%;
			}
		}
	});
}
</style>
