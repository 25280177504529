<template>
	<p-dialog v-model:visible="show_modal" class="modal" :modal="true" :dismissable-mask="true">
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>{{ edit_disposition ? 'Edit Disposition + New Note' : 'New Note' }}</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div v-if="edit_disposition" class="control-group">
				<div class="inner">
					<label class="control-label req">Disposition:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="disposition"
								name="disposition"
								:options="disposition_options"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.disposition.$error" class="validation-error">
								{{ v$.disposition.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<p-text-area v-model="note.body" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button :label="edit_disposition ? 'Update Account & Create Note' : 'Add Note'" @click="submit" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import pDialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import pTextArea from 'primevue/textarea';
import { disposition_options } from '@/lib/Options';
import { insertNote, updateAccount } from '@GQL';
import { useSessionStore } from '@/stores/session';
import Gutter from '@/components/elements/Gutter.vue';

export default {
	name: 'AddNoteModal',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		edit_disposition: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	components: {
		Gutter,
		pDialog,
		pTextArea,
	},
	emits: ['refresh'],
	setup() {
		return {
			v$: useVuelidate(),
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			show_modal: false,
			disposition: '',
			note: {
				account_id: this.account_id,
				body: '',
				type: '',
				source: 'UI User created',
				created_by: '',
			},
			disposition_options,
			loading: false,
		};
	},
	methods: {
		openModal() {
			this.show_modal = true;
			this.disposition = '';
			this.note.body = '';
		},
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.loading = true;
				let to_return = null;
				if (this.edit_disposition && this.disposition !== '') {
					try {
						await updateAccount(this.account_id, {
							support: {
								disposition: this.disposition,
							},
						});
						this.$toast.add({
							severity: 'success',
							summary: 'Account disposition was updated',
							life: 3000,
						});
						to_return = { disposition: this.disposition };
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Update to disposition failed',
							detail: err.message.slice(0, 50),
							life: 7000,
						});
						return;
					}
				}

				this.note.created_by = this.sessionStore.user.id;
				try {
					const result = await insertNote(this.note);
					if (result) {
						this.$toast.add({
							summary: 'Note was created',
							life: 5000,
						});

						this.body = '';
						this.show_modal = false;
						this.$emit('refresh', to_return);
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to add note',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Validation failed',
					life: 6000,
				});
			}
		},
	},
	validations() {
		return {
			note: {
				body: {
					required: helpers.withMessage('Text is needed for note', (value) => {
						return value !== '' && value.length > 3;
					}),
				},
			},
			disposition: {
				required: helpers.withMessage('Disposition is required', (value) => {
					return this.edit_disposition === false || value !== '';
				}),
			},
		};
	},
};
</script>
