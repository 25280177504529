import { $GQL } from '@GQL/index';

export async function reportVelocity(params: { date_range: [string, string]; compare: any }, refresh: boolean) {
	const query = `
        query ReportVelocity($params: JSONObject!) {
            reportVelocity(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportVelocity'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportVelocity;
}
