import AltIcon from '@/components/elements/AltIcon.vue';
import Breadcrumb from '@/components/elements/Breadcrumb.vue';
import CodeEditor from '@/components/forms/CodeEditor.vue';
import Column from '@/components/elements/Column.vue';
import DeleteAction from '@/components/widgets/DeleteAction.vue';
import Gutter from '@/components/elements/Gutter.vue';
import Icon from '@/components/elements/Icon.vue';
import LineLoader from '@/components/elements/LineLoader.vue';
import Loader from '@/components/elements/Loader.vue';
import Metric from '@/components/widgets/Metric.vue';
import Row from '@/components/elements/Row.vue';

// Primevue most used components
import pButton from 'primevue/button';
import pButtonGroup from 'primevue/buttongroup';
import pCard from 'primevue/card';
import pCheckbox from 'primevue/checkbox';
import pChips from 'primevue/chips';
import pColumn from 'primevue/column';
import pColumnGroup from 'primevue/columngroup';
import pDataTable from 'primevue/datatable';
import pDialog from 'primevue/dialog';
import pDropdown from 'primevue/dropdown';
import pFieldset from 'primevue/fieldset';
import pInputCurrency from '@/components/forms/InputCurrency.vue';
import pInputMask from 'primevue/inputmask';
import pInputNumber from 'primevue/inputnumber';
import pInputPercentage from '@/components/forms/InputPercentage.vue';
import pInputSwitch from 'primevue/inputswitch';
import pInputText from 'primevue/inputtext';
import pMessage from 'primevue/message';
import pMultiSelect from 'primevue/multiselect';
import pPaginator from 'primevue/paginator';
import pPassword from 'primevue/password';
import pRadioButton from 'primevue/radiobutton';
import pRow from 'primevue/row';
import pSelectButton from 'primevue/selectbutton';
import pTextarea from 'primevue/textarea';

export default {
	install(app, options) {
		app
			.component('alt-icon', AltIcon)
			.component('breadcrumb', Breadcrumb)
			.component('code-editor', CodeEditor)
			.component('column', Column)
			.component('delete-action', DeleteAction)
			.component('gutter', Gutter)
			.component('icon', Icon)
			.component('line-loader', LineLoader)
			.component('loader', Loader)
			.component('metric', Metric)
			.component('p-button', pButton)
			.component('p-button-group', pButtonGroup)
			.component('p-card', pCard)
			.component('p-checkbox', pCheckbox)
			.component('p-chips', pChips)
			.component('p-column-group', pColumnGroup)
			.component('p-column', pColumn)
			.component('p-data-table', pDataTable)
			.component('p-dialog', pDialog)
			.component('p-dropdown', pDropdown)
			.component('p-fieldset', pFieldset)
			.component('p-input-currency', pInputCurrency)
			.component('p-input-mask', pInputMask)
			.component('p-input-number', pInputNumber)
			.component('p-input-percentage', pInputPercentage)
			.component('p-input-switch', pInputSwitch)
			.component('p-input-text', pInputText)
			.component('p-message', pMessage)
			.component('p-multi-select', pMultiSelect)
			.component('p-paginator', pPaginator)
			.component('p-password', pPassword)
			.component('p-radio-button', pRadioButton)
			.component('p-row', pRow)
			.component('p-select-button', pSelectButton)
			.component('p-textarea', pTextarea)
			.component('row', Row);
	},
};
