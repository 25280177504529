import { $GQL } from '@GQL/index';
import type { RunJobReq } from '@nextgenleads/job-driver';

export async function runJob(data: RunJobReq) {
	const mutation = `
        mutation InsertJob($data: JSONObject!) {
            runJob(data: $data) {
                id
                name
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.runJob;
}
