<template>
	<!-- Customer Facing Version -->
	<div id="campaign-stats" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Campaign Stats{{ campaign_stats.campaign.name ? `: ${campaign_stats.campaign.name}` : '' }}</h1>
			<breadcrumb :items="breadcrumbs" />
		</div>
		<div>
			<p-button label="Edit Campaign" @click="$router.push($route.path.replace('/reports', ''))">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="pencil-box-outline" size="20px" />
					</div>
				</template>
			</p-button>
		</div>
		<gutter size="20px" />
		<div>
			<query-form v-model:query="query" :loading="loading" @apply="getCampaignStats()">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="refreshReports"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="font-bold pb-2" style="font-size: var(--font-size-base)">Date Range:</div>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field xl">
											<date-range v-model="form.query.date_range" show-time />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<row>
				<div class="column">
					<metric label="Total Auctions" is-number :value="totalAuctions" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Disqualifications" is-number :value="totalDQs" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Qualified Auctions" is-number :value="totalAuctions - totalDQs" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Wins" is-number :value="totalWins" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Qualified Win Rate" is-percentage :value="totalWins / (totalAuctions - totalDQs)" />
				</div>
			</row>
			<gutter size="20px" />
			<row>
				<column>
					<div class="card">
						<div class="card-heading padded">
							<div class="flex align-items-center">
								<icon type="chart-line" size="20px" />
								<h2>Leads and Spend</h2>
							</div>
						</div>
						<div class="padded">
							<line-chart :chart-data="leadsAndSpendChartData" :custom-options="chart_options" />
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<column>
					<div class="card">
						<div class="card-heading padded">
							<div class="flex align-items-center">
								<icon type="sword-cross" size="20px" />
								<h2>State Competition</h2>
							</div>
						</div>
						<div class="padded">
							<p-data-table :value="campaign_stats.state_competition" sort-field="state">
								<template #empty>
									<div class="dim">No data</div>
								</template>
								<p-column field="state" sortable header="State">
									<template #body="row">
										{{ row.data.state }}
									</template>
								</p-column>
								<p-column field="auctions" header="Auctions" sortable class="column-align-right">
									<template #body="row">
										{{ row.data.auctions }}
									</template>
								</p-column>
								<p-column field="dqs" header="Disqualifications" sortable class="column-align-right">
									<template #body="row">
										{{ row.data.dqs }}
									</template>
								</p-column>
								<p-column field="wins" header="Wins" sortable class="column-align-right">
									<template #body="row">
										{{ row.data.wins }}
									</template>
								</p-column>
								<p-column field="win_rate" header="Win Rate" sortable class="column-align-right">
									<template #body="row">
										{{ percentage(row.data.win_rate, 2) }}
									</template>
								</p-column>
							</p-data-table>
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<column :span="2">
					<div class="card">
						<div class="card-heading padded">
							<div class="flex align-items-center">
								<icon type="gavel" size="20px" />
								<h2>DQ Reasons</h2>
							</div>
						</div>
						<div class="dq-reasons padded">
							<p-message v-if="campaign_stats.dq_reasons.length === 0" :closable="false"
								>This campaign has not been disqualified from any auctions during the selected date range.</p-message
							>
							<div v-else>
								<p-data-table :value="consolidatedDQReasons" sort-field="state">
									<template #empty>
										<div class="dim">No data</div>
									</template>
									<p-column field="reason" sortable header="Disqualification Reason">
										<template #body="row">
											{{ row.data.dq_reason }}
											<icon
												v-if="row.data.dq_reason === 'Available balance is less than bid'"
												type="information-outline"
												v-tooltip.top="'Add more funds or set up auto-funding'"
											/>
										</template>
									</p-column>
									<p-column field="count" sortable header="Count">
										<template #body="row">
											{{ row.data.count }}
										</template>
									</p-column>
									<p-column field="percentage" sortable header="Overall Percentage of DQs">
										<template #body="row"> {{ percentage(row.data.percentage, 2) }} </template>
									</p-column>
								</p-data-table>
							</div>
						</div>
					</div>
				</column>
				<gutter size="20px" />
				<column>
					<div class="card">
						<div class="card-heading padded">
							<div class="flex align-items-center">
								<icon type="poll" size="20px" />
								<h2>DQ Reason Graph</h2>
							</div>
						</div>
						<div class="dq-reasons padded">
							<p-message v-if="campaign_stats.dq_reasons.length === 0" :closable="false"
								>No disqualifications.</p-message
							>
							<div v-for="(row, index) in consolidatedDQReasons" class="dq-reason" :key="`reason-${index}`">
								<div class="reason">
									<strong>{{ row.dq_reason }}</strong> - {{ numberFormat(row.count) }}
									<span class="percentage">({{ percentage(row.percentage, 2) }})</span>
								</div>
								<div class="visualization">
									<div class="bar">
										<div class="progress" :style="{ width: percentage(row.percentage, 2) }"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<column>
					<div class="column">
						<div class="card" style="max-width: 500px">
							<div class="card-heading padded">
								<div class="flex align-items-center">
									<icon type="history" size="20px" />
									<h2>Recent Auctions</h2>
								</div>
							</div>
							<div class="recent-auctions padded">
								<p-message v-if="recent_auctions.length === 0" :closable="false"
									>This campaign has not participated in any recent auctions.</p-message
								>
								<p-timeline :value="recent_auctions">
									<template #opposite="row">
										<div class="opposite">
											<div class="recent-auction-date">
												{{ formatDate(row.item.created_at, 'YYYY-MM-DD') }}<br />
												{{ formatDate(row.item.created_at, 'h:mm:ss A') }}
											</div>
											<div class="time-ago">{{ timeAgo(row.item.created_at) }}</div>
										</div>
									</template>
									<template #content="row">
										<div class="content">
											<div class="lead-id">
												<router-link v-if="row.item.winner" :to="`/leads/${row.item.lead_id}`">{{
													row.item.lead_id
												}}</router-link>
												<div v-else-if="row.item.dq_reason">DISQUALIFIED</div>
												<span v-else>OUTBID</span>
											</div>
											<div class="bid">
												<template v-if="row.item.dq_reason">
													<div v-if="otherReasons.includes(row.item.dq_reason)">Other</div>
													<div v-else class="trimmed">{{ row.item.dq_reason }}</div>
												</template>
												<template v-else>Bid: {{ currency(row.item.bid) }}</template>
											</div>
											<div class="outcome">
												<div v-if="row.item.winner"><icon type="check" size="18px" /> Won Auction</div>
												<div v-else-if="row.item.dq_reason">
													<icon class="red" type="cancel" size="18px" /> Disqualified
												</div>
												<div v-else><icon class="yellow" type="close" size="18px" /> Lost</div>
											</div>
										</div>
									</template>
								</p-timeline>
							</div>
						</div>
					</div>
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import dateRange from '@/components/forms/DateRange.vue';
import lineChart from '@/components/charts/Line.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { currency, formatDate, numberFormat, percentage, timeAgo } from '@/lib/Filters';
import { getCampaignStats, getRecentAuctions } from '@GQL';
import dayjs from 'dayjs';
import { round, sumBy } from 'lodash-es';
import pTimeline from 'primevue/timeline';

const otherReasons = [
	'Sorted out of contention',
	'Premium listings are not valid in this auction',
	'Only premium listings are valid in this auction',
	'Promotion does not match current conditions',
	'Campaign promotion is not included in the auction configuration',
	'Promotion is not within start & end date',
	'Campaign promotion is no longer exists',
	'Campaign does not match source tags',
];

export default {
	name: 'AccountCampaignReport',
	components: {
		dateRange,
		lineChart,
		pTimeline,
		queryForm,
	},
	data() {
		return {
			otherReasons,
			breadcrumbs: [
				{ label: 'Campaigns', route: '/campaigns' },
				{ label: `Campaign`, route: `/campaigns/${this.$route.params.campaign_id}` },
				{ label: 'Stats' },
			],
			loading: false,
			query: {
				date_range: [dayjs().startOf('week').toDate(), dayjs().endOf('week').toDate()],
			},
			campaign_stats: {
				leads_and_spend: [],
				state_competition: [],
				dq_reasons: [],
				campaign: {
					name: '',
				},
			},
			recent_auctions: [],
			chart_options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (tooltip) {
								if (tooltip.dataset.label.indexOf(' Leads Purchased') > -1) {
									return `${tooltip.dataset.label}: ${tooltip.raw}`;
								}
								return `${tooltip.dataset.label}: ${currency(tooltip.raw)}`;
							},
						},
					},
				},
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						min: 0,
						ticks: {
							callback: function (value) {
								return value;
							},
						},
					},
					y_spend: {
						type: 'linear',
						display: true,
						position: 'right',
						min: 0,
						ticks: {
							callback: function (value) {
								return currency(value);
							},
						},
					},
				},
				layout: {
					padding: 30,
				},
			},
		};
	},
	computed: {
		totalAuctions() {
			return sumBy(this.campaign_stats.state_competition, (row) => +row.auctions);
		},
		totalWins() {
			return sumBy(this.campaign_stats.state_competition, (row) => +row.wins);
		},
		totalDQs() {
			return sumBy(this.campaign_stats.dq_reasons, (row) => +row.count);
		},
		leadsAndSpendChartData() {
			return {
				labels: this.campaign_stats.leads_and_spend.map((row) => {
					return dayjs(row.date).format('MMM D');
				}),
				datasets: [
					{
						label: ' Leads Purchased',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.leads;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y',
					},
					{
						label: ' Spend',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.spend;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y_spend',
					},
					{
						label: ' Cost Per Lead',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.rpl;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y_spend',
					},
				],
			};
		},
		consolidatedDQReasons() {
			interface DQReason {
				dq_reason: string;
				count: number;
				percentage: number;
			}
			const shown_reasons_array = [] as DQReason[];
			const other_reasons_array = [] as DQReason[];
			this.campaign_stats.dq_reasons.forEach((item) => {
				if (this.otherReasons.includes(item.dq_reason)) {
					other_reasons_array.push(item);
				} else {
					shown_reasons_array.push(item);
				}
			});
			const other_total: DQReason = {
				dq_reason: 'Other',
				count: sumBy(other_reasons_array, (item) => item.count),
				percentage: sumBy(other_reasons_array, (item) => item.percentage),
			};
			let consolidated_array = [];
			if (shown_reasons_array.length > 0) {
				consolidated_array = consolidated_array.concat(shown_reasons_array);
			}
			if (other_reasons_array.length > 0) {
				consolidated_array = consolidated_array.concat([other_total]);
			}
			return consolidated_array;
		},
	},
	async mounted() {
		await this.getCampaignStats();
		await this.getRecentAuctions();
	},
	methods: {
		currency,
		formatDate,
		numberFormat,
		percentage,
		round,
		timeAgo,
		async getCampaignStats(refresh = false) {
			this.loading = true;
			try {
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportCampaignStats'];
				}

				const campaign_stats = await getCampaignStats(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
					},
					no_cache
				);

				this.campaign_stats = campaign_stats;
				this.loading = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get campaign stats',
					detail: 'Please contact dev support',
				});
			}
			this.loading = false;
		},
		async getRecentAuctions(refresh = false) {
			try {
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportRecentAuctions'];
				}
				const recent_auctions = await getRecentAuctions(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
					},
					no_cache
				);
				this.recent_auctions = recent_auctions;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get recent auctions',
					detail: 'Please contact dev support',
				});
			}
		},
		async refreshReports() {
			await this.getCampaignStats(true);
			await this.getRecentAuctions(true);
		},
	},
};
</script>

<style lang="less" scoped>
.dq-reasons,
.recent-auctions {
	:deep(.p-message) {
		margin: 0 0;
	}
}

.dq-reason {
	margin-bottom: 1em;

	.reason {
		font-size: 0.875rem;
		margin-bottom: 5px;
	}

	.visualization {
		align-items: center;
		display: flex;
	}

	.bar {
		flex: 1 0;

		.progress {
			background-color: var(--color-b);
			height: 20px;
		}
	}

	.count {
		flex: 0 0 30px;
	}
}

.recent-auctions {
	:deep(.p-timeline-event-opposite) {
		flex: 1 0;
	}

	:deep(.p-timeline-event-content) {
		flex: 3 0;
	}

	.opposite {
		color: var(--gray-50);
		font-size: 0.875em;
		line-height: 1.35em;
	}

	.time-ago {
		color: var(--gray-35);
		font-size: 0.75em;
	}

	.content {
		font-size: 0.875em;
		line-height: 1.35em;
		max-width: 260px;
		overflow: hidden;
		text-overflow: ellipsis;

		.lead-id {
			font-weight: bold;
		}

		.outcome div {
			align-items: flex-start;
			display: flex;
			gap: 5px;

			.mdi-icon {
				color: var(--green);
			}

			.yellow {
				color: var(--yellow);
			}

			.red {
				color: var(--red);
			}
		}
	}

	.trimmed {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-wrap: nowrap;
		width: 100%;
	}
}
</style>
