<template>
	<div :class="['custom-bid', customBid.status]">
		<div class="custom-bid-content">
			<div class="status">
				<p-input-switch v-model="customBid.status" true-value="active" false-value="paused" />
			</div>
			<div class="name">
				<slot></slot>
			</div>
			<div>
				<div v-if="mode === 'advanced' || sessionStore.isAdminUser" class="bid-multiplier">
					<div class="bid-label">
						<div>Adjust Bid</div>
						<div v-if="customBid.custom_minimum_bid" class="sub-value">Min: {{ currency(customBid.minimum_bid) }}</div>
					</div>
					<div class="flex flex-row align-items-center gap-2">
						<div class="button-switch">
							<div
								v-if="noneOption"
								:class="['method-option', { selected: customBid.method === 'none' }]"
								v-tooltip.top="'Do not adjust bid'"
								@click="setBid('none', 1)"
							>
								<icon type="border-none-variant" size="20px" />
							</div>
							<div
								:class="['method-option', { selected: customBid.method === 'flat' }]"
								v-tooltip.top="'Apply exact bid'"
								@click="setBid('flat', campaignStore.campaign.default_bid)"
							>
								<icon type="equal" size="20px" />
							</div>
							<div
								:class="['method-option', { selected: customBid.method === 'add' && customBid.amount > 0 }]"
								v-tooltip.top="'Increase/decrease bid by dollar amount'"
								@click="setBid('add', 1)"
							>
								<icon type="plus-minus-variant" size="20px" />
							</div>
							<div
								:class="['method-option', { selected: customBid.method === 'multiply' && customBid.amount > 0 }]"
								v-tooltip.top="'Multiply bid by a percentage'"
								@click="setBid('multiply', 1)"
							>
								<icon type="close" size="20px" />
							</div>
							<div
								v-if="filterOption"
								:class="[
									'method-option',
									'do-not-bid',
									{ selected: customBid.method === 'multiply' && customBid.amount === 0 },
								]"
								v-tooltip.top="'Do Not Bid'"
								@click="setBid('multiply', 0)"
							>
								<icon type="currency-usd-off" size="20px" />
							</div>
						</div>

						<template v-if="customBid.method === 'multiply' && customBid.amount > 0">
							<p-input-percentage
								v-model="customBid.amount"
								class="input-amount input-align-right"
								@blur="v$.customBid.amount.touch()"
							/>
						</template>
						<template v-else-if="customBid.method === 'flat' || customBid.method === 'add'">
							<p-input-currency
								v-model="customBid.amount"
								class="input-amount input-align-right"
								@blur="v$.customBid.amount.$touch()"
							/>
						</template>
						<template v-if="$root.sessionStore.isAdminUser">
							<div
								v-if="!(customBid.method === 'multiply' && customBid.amount === 0) && customBid.method !== 'none'"
								:class="['finalize-bid', { enabled: customBid.finalize }]"
								v-tooltip.top="'Lock in this bid'"
								@click="customBid.finalize = !customBid.finalize"
							>
								<icon v-if="customBid.finalize" type="lock" size="24px" />
								<icon v-else type="lock-open" size="24px" />
							</div>
						</template>
					</div>
				</div>
				<div v-if="v$.customBid.amount.$error" class="validation-error text-align-right">
					{{ v$.customBid.amount.$errors[0].$message }}
				</div>
			</div>
		</div>
		<div class="custom-bid-actions">
			<div class="toolset">
				<div v-if="sessionStore.isAdminUser" v-tooltip.top="'Edit Minimum Bid'" class="tool">
					<p-button @click="toggleMinBidEditPanel">
						<template #icon>
							<icon type="hand-coin-outline" size="20px" />
						</template>
					</p-button>

					<p-overlay-panel ref="min_bid_op" class="min-bid-overlay-panel">
						<div class="overlay-container flex-column">
							<div class="control-group">
								<div class="inner">
									<div class="control-label">Custom Min Bid:</div>
									<div v-tooltip.left="'Enable Custom Minimum'" class="controls">
										<div class="field fit">
											<p-input-switch v-model="customBid.custom_minimum_bid" input-id="custom-min-bid-switch" />
										</div>
										<gutter size="10px" />
										<div class="field xs">
											<div class="p-inputgroup">
												<span class="p-inputgroup-addon">$</span>
												<p-input-number
													v-model="customBid.minimum_bid"
													:disabled="!customBid.custom_minimum_bid"
													class="bid-input input-align-right"
													placeholder="Set Minimum Bid"
													input-id="currency-us"
													mode="decimal"
													:min-fraction-digits="2"
													:max-fraction-digits="2"
													currency="USD"
													locale="en-US"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-overlay-panel>
				</div>
				<div v-tooltip.top="'Edit'" class="tool">
					<p-button @click="editCustomBid">
						<template #icon>
							<icon type="square-edit-outline" size="20px" />
						</template>
					</p-button>
				</div>
				<delete-action :message="`Are you sure you want to delete this ${type}?`" @delete="deleteCustomBid" />
			</div>
		</div>
	</div>
</template>

<script>
import pOverlayPanel from 'primevue/overlaypanel';
import { currency } from '@/lib/Filters';
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { mapStores } from 'pinia';

export default {
	name: 'CustomBidMethodSelector',
	components: {
		pOverlayPanel,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	emits: ['edit', 'delete'],
	props: {
		mode: {
			type: String,
			default: 'basic',
		},
		type: {
			type: String,
			default: 'custom bid',
		},
		customBid: {
			type: Object,
			default() {
				return {
					id: null,
					name: '',
					type: '',
					match: [],
					method: 'none',
					amount: 1,
					custom_minimum_bid: false,
					minimum_bid: 0,
					finalize: true,
					status: 'active',
					dq_message: null,
				};
			},
		},
		minimumBid: {
			type: Number,
			default: 0,
		},
		noneOption: {
			type: Boolean,
			default: false,
		},
		filterOption: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapStores(useCampaignStore, useSessionStore),
	},
	validations() {
		return {
			customBid: {
				amount: {
					validBid: helpers.withMessage('Bid must be greater than minimum bid', (value) => {
						const minimum_bid = this.customBid.custom_minimum_bid ? this.customBid.minimum_bid : this.minimumBid;
						return this.customBid.method !== 'flat' || value >= minimum_bid;
					}),
					dataBidMax: helpers.withMessage('Data campaigns cannot bid above $75', (value) => {
						return this.campaignStore.campaign.product_targeting !== 'data' || value <= 75;
					}),
				},
			},
		};
	},
	methods: {
		currency,
		setBid(method, amount) {
			this.customBid.method = method;
			this.customBid.amount = amount;

			if (!this.$root.sessionStore.isAdminUser) {
				if (method === 'flat') {
					this.customBid.finalize = true;
				} else {
					this.customBid.finalize = false;
				}
			}
		},
		editCustomBid() {
			this.$emit('edit', this.customBid.id);
		},
		deleteCustomBid() {
			this.$emit('delete', this.customBid.id);
		},
		toggleMinBidEditPanel(event) {
			this.$refs.min_bid_op.toggle(event);
		},
	},
};
</script>

<style scoped lang="less">
.custom-bid {
	align-items: center;
	background-color: white;
	border: 1px solid var(--gray-20);
	border-radius: 4px;
	box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 1rem;

	.name {
		flex: 1;
		font-size: 1rem;
		line-height: 1.15em;
		min-width: 0;
	}

	.bid-multiplier {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: flex-start;

		.multiplier-input {
			max-width: 100px;
			min-width: 80px;
		}
	}

	.toolset.edit {
		.p-button {
			background-color: var(--color-b-lightest);
		}
	}

	&.paused {
		background-color: var(--gray-05);
		color: var(--gray-40);
	}
}

.custom-bid-content {
	align-items: center;
	display: flex;
	flex: 1 0;
	gap: 20px;
	justify-content: space-between;
	padding: 1rem;
}

.custom-bid-actions {
	border-left: 1px solid var(--gray-10);
	padding: 1rem 0.75rem;
}

.bid-label {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	justify-content: center;

	.sub-value {
		margin-top: 5px;
	}
}

.bid-multiplier {
	align-items: center;
	display: flex;
}

.button-switch {
	display: inline-flex;
	overflow: hidden;

	.method-option {
		align-items: center;
		background-color: var(--gray-10);
		border: 1px solid var(--gray-20);
		border-left: 0;
		color: var(--gray-30);
		display: flex;
		height: 40px;
		justify-content: center;
		width: 40px;

		&:first-child {
			border-radius: 3px 0 0 3px;
			border-left: 1px solid var(--gray-20);
		}

		&:last-child {
			border-radius: 0 3px 3px 0;
		}

		&:hover {
			color: var(--color-b);
		}

		&.selected {
			background-color: var(--color-b);
			border: 0;
			color: white;

			&.do-not-bid {
				background-color: var(--red);
			}
		}
	}
}

.finalize-bid {
	align-items: center;
	border: 1px dashed var(--gray-20);
	border-radius: 3px;
	color: var(--gray-20);
	display: flex;
	height: 40px;
	justify-content: center;
	width: 40px;

	&.enabled {
		background-color: var(--orange);
		border: 0;
		color: white;
	}
}

.input-amount {
	max-width: 120px;
}

.validation-error {
	margin-top: 8px;
}
</style>
