import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';

export async function cancelPurchase(purchase_id: string) {
	const mutation = `
        mutation CancelPurchase($id: ID!) {
            cancelPurchase(id: $id)
        }
    `;

	const result = await $GQL.request(mutation, { id: purchase_id });
	return result.cancelPurchase;
}
