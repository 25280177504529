<template>
	<p-card>
		<template #title>
			<div class="flex align-items-center w-full">
				<icon type="shopping-outline" size="var(--font-size-h4)" class="mr-2" />
				<span class="name">Purchases</span>
				<a class="purchase-history-button" @click.prevent="handlePurchaseModal"
					>Table View <icon type="open-in-new" />
				</a>
			</div>
		</template>
		<template #content>
			<h3 class="heading">Purchase History</h3>
			<ul class="table-list">
				<table class="purchase-details">
					<tr v-for="purchase in filteredPurchases" :key="purchase.id">
						<td>
							{{ formatDate(purchase.created_at, 'YYYY-MM-DD') }}
							<div class="sub-value">{{ formatDate(purchase.created_at, 'h:mm:ssA') }}</div>
						</td>
						<td>
							<router-link :to="accountLink(purchase.account.id, purchase.mpid)">{{
								purchase.account.name
							}}</router-link>
							<div class="sub-value">
								<router-link :to="campaignLink(purchase.campaign.id, purchase.account.id, purchase.mpid)">{{
									purchase.campaign.name
								}}</router-link>
							</div>
						</td>
						<td class="text-align-right">
							{{ currency(purchase.price) }}
							<div class="sub-value">{{ purchaseType(purchase) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<strong>Total</strong>
						</td>
						<td>
							<strong>{{ filteredPurchases.length }}</strong>
						</td>
						<td class="text-align-right">
							<strong>{{ currency(totalRevenue) }}</strong>
						</td>
					</tr>
				</table>
			</ul>
		</template>
	</p-card>
	<p-dialog
		id="purchase-history-modal"
		v-model:visible="purchase_modal_open"
		modal
		style="max-width: 1200px; width: 100%"
	>
		<template #header>
			<div class="flex align-items-center w-full">
				<icon type="shopping-outline" size="1.5rem" style="margin-right: 1rem" />
				<span class="font-bold">Purchases</span>
			</div>
		</template>
		<p-data-table :value="filteredPurchases">
			<p-column field="created" header="Purchased On">
				<template #body="purchase">
					{{ formatDate(purchase.data.created_at, 'YYYY-MM-DD [&bull;] h:mm:ssA') }}
					<div class="sub-value">
						{{ timeAgo(purchase.data.created_at) }}
					</div>
				</template>
			</p-column>
			<p-column field="company" header="Company">
				<template #body="purchase">
					<router-link :to="accountLink(purchase.data.account.id, purchase.data.mpid)">
						{{ purchase.data.account.name }}
					</router-link>
					<div class="sub-value">{{ purchase.data.account.id }}</div>
				</template>
			</p-column>
			<p-column field="campaign" header="Campaign">
				<template #body="purchase">
					<!-- TODO: Get Correct Path for Campaign depending on view -->
					<router-link :to="campaignLink(purchase.data.campaign.id, purchase.data.account.id, purchase.data.mpid)">
						{{ purchase.data.campaign.name }}
					</router-link>
					<div class="sub-value">{{ purchase.data.campaign.id }}</div>
				</template>
			</p-column>
			<p-column field="bid_type" header="Bid Type">
				<template #body="purchase">
					{{ capitalize(purchase.data.bid_type) }}
				</template>
			</p-column>
			<p-column field="price" header="Price">
				<template #body="purchase">
					{{ currency(purchase.data.price) }}
				</template>
			</p-column>
			<p-column field="status" header="Status">
				<template #body="purchase">
					{{ capitalize(purchase.data.status) }}
				</template>
			</p-column>
			<p-column v-if="$root.sessionStore.isAdminUser" header="Purchase" class="column-align-center">
				<template #body="purchase">
					<div v-tooltip.top="'View Raw Purchase'" @click="viewRawPurchase(purchase.data)">
						<icon type="receipt-text" size="24px" />
					</div>
				</template>
			</p-column>
			<p-column v-if="$root.sessionStore.isAdminUser" header="Actions" class="column-align-center">
				<template #body="purchase">
					<div
						v-if="purchase.data.status === 'complete'"
						v-tooltip.top="'Cancel Purchase'"
						@click="cancelPurchase($event, purchase.data.id)"
					>
						<icon type="cancel" size="24px" />
					</div>
					<p-confirm-popup :group="purchase.data.id" />
				</template>
			</p-column>
			<!-- TODO: Add tools for return/cancel -->
			<!-- <p-column class="align-center">
						<template #header>
							<div style="text-align: center; width: 100%">Tools</div>
						</template>
						<template #body="purchase">
							<div class="card flex justify-content-center">
								<i
									v-tooltip.top="'See Purchase Record'"
									class="pi pi-copy p-1 cursor-pointer"
									@click="copy(purchase.data.id)"
								/>
								<i
									v-tooltip.top="'Re-Ship Lead'"
									class="pi pi-send p-1 cursor-pointer"
									@click="edit(purchase.data.id)"
								/>
								<i
									v-tooltip.top="'Cancel Purchase'"
									class="pi pi-ban p-1 cursor-pointer"
									@click="archive(purchase.data.id)"
								/>
							</div>
						</template>
					</p-column> -->
		</p-data-table>
	</p-dialog>
	<RawPurchaseModal ref="raw_purchase_modal" :purchase="selected_purchase" />
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import pDialog from 'primevue/dialog';
import { capitalize, currency, formatDate, timeAgo } from '@/lib/Filters';
import RawPurchaseModal from './modals/RawPurchase.vue';
import { cancelPurchase, getPurchaseHistory } from '@GQL';
import log from '@/lib/Log';
import pConfirmPopup from 'primevue/confirmpopup';

export default {
	name: 'PurchaseHistoryPanel',
	props: {
		leadId: {
			type: String,
			required: true,
		},
		purchases: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	components: {
		pDialog,
		RawPurchaseModal,
		pConfirmPopup,
	},
	data() {
		return {
			purchase_modal_open: false,
			show_raw_purchase_modal: false,
			selected_purchase: {},
			local_purchases: [],
			loading: false,
		};
	},
	computed: {
		totalRevenue() {
			return this.filteredPurchases.reduce((acc, obj) => {
				return acc + obj.price;
			}, 0);
		},
		filteredPurchases() {
			if (this.$root.sessionStore.isAdminUser) {
				return this.local_purchases;
			} else {
				return this.local_purchases.filter((purchase) => {
					return purchase.mpid === this.$root.appStore.mpid;
				});
			}
		},
	},
	async mounted() {
		await this.getPurchaseList();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		timeAgo,
		handlePurchaseModal() {
			this.purchase_modal_open = !this.purchase_modal_open;
		},
		async getPurchaseList() {
			if (this.purchases.length === 0) {
				this.loading = true;
				const filters = [`lead_id = '${this.leadId}'`];
				if ('account_id' in this.$route.params) {
					filters.push(`account_id = '${this.$route.params.account_id}'`);
				}

				try {
					const { rows, row_count } = await getPurchaseHistory({
						filters: [filters],
					});
					this.local_purchases = rows;
				} catch (err) {
					log.trace(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong',
						detail: 'Unable to get Purchase History',
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.local_purchases = cloneDeep(this.purchases);
			}
		},
		purchaseType(purchase) {
			if (purchase.product === 'data') {
				return capitalize(purchase.bid_type + ' Data');
			} else if (purchase.product === 'ad') {
				if (purchase.tags.includes('premium_listing')) {
					return 'Premium Listing';
				}
			}
			return capitalize(purchase.product);
		},
		accountLink(account_id, mpid) {
			if (this.$root.appStore.isAdminApp) {
				return `/marketplaces/manage/${mpid}/accounts/manage/${account_id}/reports/account-activity`;
			}
			return `/accounts/manage/${account_id}/reports/account-overview`;
		},
		campaignLink(campaign_id, account_id, mpid) {
			if (this.$root.appStore.isAdminApp) {
				return `/marketplaces/manage/${mpid}/accounts/manage/${account_id}/campaigns/${campaign_id}`;
			}
			return `/accounts/manage/${account_id}/campaigns/${campaign_id}`;
		},
		viewRawPurchase(purchase) {
			this.selected_purchase = purchase;
			this.$refs.raw_purchase_modal.show_modal = true;
		},
		cancelPurchase(event, purchase_id) {
			this.$confirm.require({
				target: event.currentTarget,
				group: purchase_id,
				message: this.message || 'Are you sure you want to cancel this purchase?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: this.acceptClass || 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: this.acceptLabel || 'Cancel Purchase',
				rejectLabel: this.rejectLabel || 'Nevermind',
				accept: async () => {
					try {
						const canceled_result = await cancelPurchase(purchase_id);
						this.local_purchases = this.local_purchases.map((local_purchase) => {
							if (local_purchase.id === purchase_id) {
								local_purchase.status = 'canceled';
							}
							return local_purchase;
						});
						this.$toast.add({
							severity: 'success',
							summary: 'The purchase was canceled',
							life: 3000,
						});
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to cancel the purchase',
							life: 5000,
						});
					}
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
#purchase-history-modal {
	width: 100%;
}

.purchase-history-button {
	align-items: center;
	color: var(--color-b);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-base);
	gap: 0.25rem;
	margin-left: auto;
}

table.purchase-details {
	font-size: 0.875rem;
	width: 100%;

	tr {
		border-top: 1px solid var(--gray-20);

		&:first-of-type {
			border-top: 0;
		}
	}

	td {
		line-height: 1.15em;
		padding: 0.75rem 0.5rem;
	}
}

.sub-value a {
	color: var(--gray-35);
}
</style>
