<template>
	<div id="campaigns-list">
		<query-form v-model:query="query" :loading="loading" @apply="getCampaigns" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getCampaigns(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="font-bold pb-2" style="font-size: var(--font-size-base)">Date Range:</div>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field xl">
										<date-range v-model="form.query.filters.created_at" show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="campaign-list-toolbar flex">
			<div class="flex-group fit">
				<p-button label="Create New Campaign" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</div>
			<gutter size="20px" />
			<div class="flex-group flex align-items-center">
				<div>
					<p-dropdown v-model="group_value" :options="group_options" option-label="label" option-value="value" />
				</div>
			</div>
			<div class="flex-group flex-fill"></div>
			<div class="flex-group fit flex align-items-center gap-2">
				<p-button rounded text label="Pause All Campaigns" @click="pauseAllCampaigns" />
				<show-archived v-model="show_archived" />
			</div>
		</div>
		<gutter size="20px" />

		<template v-if="Object.keys(groupedCampaigns).length === 0 && loading === false">
			<div class="create-new-campaign">
				<row gap="50px">
					<column>
						<h3>Complete Campaign Setup</h3>
						<p class="larger">You don't have any campaigns!</p>
						<p>
							Buying leads is easy with NextGen Leads. Just create a new campaign and add funds to your account to start
							buying data leads, calls, and live transfers that are targeted to your precise specifications. You can set
							budgets, adjust bids by various parameters, and turn on and off your campaigns whenever you want.
						</p>
						<div class="action">
							<p-button
								icon="pi pi-plus"
								label="Create Your First Campaign Now"
								size="large"
								@click="$router.push({ path: `${$route.path}/new` })"
							/>
						</div>
					</column>
					<column class="fit">
						<img src="/assets/img/illustrations/new-campaign.svg" alt="People around a large target" />
					</column>
				</row>
			</div>
		</template>

		<div
			v-for="(campaign_group, campaign_group_key) in groupedCampaigns"
			:key="campaign_group_key"
			class="campaign-group"
		>
			<h2>{{ campaign_group_key }}</h2>
			<div class="campaign-list">
				<p v-if="campaign_group.length === 0" class="text-center p-3 mt-4 mb-4 border-1 border-500 line-height-3">
					No {{ campaign_group_key }}, create a campaign to see performance.
				</p>
			</div>
			<div class="card padded">
				<p-data-table
					ref="dt"
					:loading="loading"
					scrollable
					:value="campaign_group"
					:footerRow="groupedCampaignTotals[campaign_group_key]"
					sortField="spend"
					:sortOrder="-1"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column class="column-align-center" field="status" header="Status" sortable sortField="status">
						<template #body="campaign">
							<div class="flex align-items-center justify-content-center">
								<p-select-button
									v-if="campaign.data.status !== 'archived'"
									:model-value="campaign.data.status === 'pending' ? 'paused' : campaign.data.status"
									:class="['campaign-status-switch', campaign.data.status]"
									option-label="label"
									option-value="value"
									:options="status_options"
									:unselectable="true"
									:disabled="statusDisabled(campaign.data)"
									@change="handleStatusUpdate($event, campaign.data.id)"
								/>
								<label v-if="campaign.data.status === 'archived'"> Archived. </label>
								<div v-if="isLocked(campaign.data)" v-tooltip.top="'Campaign Editing Disabled'" class="locked-icon">
									<icon type="lock" size="20px" />
								</div>
								<div
									v-if="noLocationTargeting(campaign.data)"
									v-tooltip.top="'No Locations Targeted'"
									class="locked-icon"
								>
									<icon type="alert" size="20px" />
								</div>
								<div v-if="campaign.data.status === 'pending'" v-tooltip.top="'Funds Required'" class="locked-icon">
									<icon type="currency-usd-off" size="20px" />
								</div>
								<div
									v-if="campaign.data.budgetWarning"
									v-tooltip.top="'Campaign Spend Limit Met or Nearly Met, will be DQ from auction'"
								>
									<icon type="gauge-full" size="20px" />
								</div>
							</div>
						</template>
					</p-column>
					<p-column class="align-center" field="vertical_id_targeting" header="Vertical" sortable sortField="vertical">
						<template #body="campaign">
							<vertical-icon :vertical-id="campaign.data.vertical_id_targeting" size="40px" style="margin: auto" />
						</template>
					</p-column>
					<p-column field="name" header="Campaign Info" sortable sortField="name">
						<template #body="campaign">
							<div class="name mb-2 font-bold" style="font-size: var(--font-size-base)">
								<router-link v-if="!isLocked(campaign.data)" :to="`${$route.path}/${campaign.data.id}`">{{
									campaign.data.name
								}}</router-link>
								<template v-else>{{ campaign.data.name }}</template>
							</div>
							<div class="tags flex flex-row justify-content-start gap-2">
								<p-tag
									v-if="campaign.data.promotion && campaign.data.promotion.description"
									v-tooltip.top="campaign.data.promotion.description"
									rounded
									class="promo"
									:value="'PROMO'"
								/>
								<p-tag
									rounded
									:class="kebabCase(campaign.data.product_targeting)"
									:value="upperCase(campaign.data.product_targeting)"
								/>
								<p-tag rounded :class="campaign.data.bid_type" :value="upperCase(campaign.data.bid_type)" />
								<p-tag
									class="pingpost"
									v-if="campaign.data.ping_post.enabled || campaign.data.ping_post.integration_id"
									rounded
									value="PING POST"
								/>
								<p-tag v-if="campaign.data.mode === 'advanced'" rounded :class="campaign.data.mode" value="ADVANCED" />
								<p-tag
									v-if="get(campaign.data.flags, 'lock.enabled', false)"
									icon="pi pi-lock"
									class="locked"
									value="LOCKED"
								/>
							</div>
						</template>
					</p-column>
					<p-column field="bid" header="Bid" class="align-center" sortable sortField="default_bid">
						<template #body="campaign">
							{{ currency(campaign.data.default_bid) }}
							<div class="sub-value">
								<template v-if="budgetCount(campaign.data.budgets) > 0">
									<div v-tooltip="budgetList(campaign.data.budgets)" class="budgets inline-flex align-items-center">
										<alt-icon type="local-atm" size="16px" />
										<div class="budget-count">
											{{ budgetCount(campaign.data.budgets) }}
										</div>
									</div>
								</template>
							</div>
						</template>
					</p-column>
					<p-column field="lead_count" header="Leads" class="align-center" sortable sortField="purchaseCount">
						<template #body="campaign">
							{{ campaign.data.purchaseCount }}
						</template>
					</p-column>
					<p-column field="cost" header="Spend" class="align-center" sortable sortField="spend">
						<template #body="campaign">
							{{ currency(campaign.data.spend) }}
						</template>
					</p-column>
					<p-column field="cost_per_lead" header="CPL" class="align-center">
						<template #body="campaign">
							{{ currency(campaign.data.purchaseCount !== 0 ? campaign.data.spend / campaign.data.purchaseCount : 0) }}
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="campaign">
							<div class="toolset">
								<div v-if="$root.sessionStore.isAdminUser" class="tool">
									<p-button
										v-tooltip.top="'Campaign Reports'"
										@click="
											$router.push({
												path: `${$route.path}/${campaign.data.id}/reports`,
											})
										"
									>
										<template #icon>
											<icon type="chart-box-outline" size="24px" />
										</template>
									</p-button>
								</div>
								<div v-if="$root.sessionStore.isAccountUser" class="tool">
									<p-button
										v-tooltip.top="'Campaign Stats'"
										@click="
											$router.push({
												path: `${$route.path}/${campaign.data.id}/stats`,
											})
										"
									>
										<template #icon>
											<icon type="chart-box-outline" size="24px" />
										</template>
									</p-button>
								</div>
								<div v-if="$root.sessionStore.hasPermission(`UI.purchases`)" class="tool">
									<p-button
										v-tooltip.top="'Purchases'"
										@click="
											$router.push({
												path: `${$route.path}/${campaign.data.id}/purchase-report`,
											})
										"
									>
										<template #icon>
											<icon type="receipt-clock" size="24px" />
										</template>
									</p-button>
								</div>
								<div v-if="!isLocked(campaign.data)" class="tool">
									<p-button
										v-tooltip.top="'Edit Campaign'"
										@click="
											$router.push({
												path: `${$route.path}/${campaign.data.id}`,
											})
										"
									>
										<template #icon>
											<icon type="square-edit-outline" size="24px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button
										v-tooltip.top="'Duplicate Campaign'"
										@click="
											$router.push({
												path: `${$route.path}/new`,
												query: {
													duplicate: campaign.data.id,
												},
											})
										"
									>
										<template #icon>
											<icon type="content-copy" size="24px" />
										</template>
									</p-button>
								</div>
								<template v-if="!campaign.data.canBeDeleted">
									<div class="tool">
										<p-button
											v-if="campaign.data.status !== 'archived' && !isLocked(campaign.data)"
											v-tooltip.top="'Archive Campaign'"
											aria-label="Archive"
											@click="handleStatusUpdate({ value: 'archived' }, campaign.data.id)"
										>
											<template #icon>
												<icon type="archive" size="20px" />
											</template>
										</p-button>
										<p-button
											v-else
											v-tooltip.top="'Un-Archive Campaign'"
											aria-label="Edit"
											@click="handleStatusUpdate({ value: 'paused' }, campaign.data.id)"
										>
											<template #icon>
												<icon type="archive-off" size="20px" />
											</template>
										</p-button>
									</div>
								</template>
								<template v-else>
									<delete-action
										v-if="!isLocked(campaign.data)"
										message="Are you sure you want to delete this campaign?"
										@delete="deleteCampaign(campaign.data.id)"
									/>
								</template>
							</div>
						</template>
					</p-column>
					<p-column-group type="footer">
						<p-row>
							<p-column footer="TOTALS:" :colspan="4" footerStyle="text-align:right"> </p-column>
							<p-column
								footerStyle="text-align:center"
								:footer="groupedCampaignTotals[campaign_group_key].purchaseCount"
							/>
							<p-column
								footerStyle="text-align:center"
								:footer="currency(groupedCampaignTotals[campaign_group_key].spend)"
							/>
							<p-column
								footerStyle="text-align:center"
								:footer="currency(groupedCampaignTotals[campaign_group_key].cpl)"
							/>
						</p-row>
					</p-column-group>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, sumBy, find, get, groupBy, kebabCase, map, startCase, upperCase } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, displaySelected, currency, verticalName, phoneFormat } from '@/lib/Filters';
import { asyncForEach } from 'modern-async';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import pTag from 'primevue/tag';
import pSelectButton from 'primevue/selectbutton';
import pColumnGroup from 'primevue/columngroup';
import { deleteCampaign, getCampaigns, updateCampaign } from '@GQL';
import log from '@/lib/Log';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'CampaignsList',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
		pTag,
		pSelectButton,
		pColumnGroup,
		showArchived,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
				},
			},
			local_filter_status: ['active', 'paused'],
			status_options: [
				{ label: 'Off', value: 'paused' },
				{ label: 'On', value: 'active' },
			],
			group_value: 'vertical_id_targeting',
			group_options: [
				{ label: 'Group by Vertical', value: 'vertical_id_targeting' },
				{ label: 'Group by Lead Type', value: 'product_targeting' },
				{ label: 'Group by Bid Type', value: 'bid_type' },
			],
			show_archived: false,
			campaigns: [],
		};
	},
	computed: {
		account_id() {
			if (this.$route.params.account_id) {
				return this.$route.params.account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
		route_name_prefix() {
			const split = this.$route.name.split('_');
			if (split.length > 1) {
				return split[0] + '_';
			}
			return '';
		},
		health_campaigns() {
			return this.campaigns.filter((campaign) => {
				return (
					campaign.vertical_id_targeting === 'health_insurance' &&
					this.local_filter_status.indexOf(campaign.status) > -1
				);
			});
		},
		medicare_campaigns() {
			return this.campaigns.filter((campaign) => {
				return campaign.vertical_id_targeting === 'medicare' && this.local_filter_status.indexOf(campaign.status) > -1;
			});
		},
		groupedCampaignTotals() {
			const copy = cloneDeep(this.groupedCampaigns);
			Object.keys(copy).forEach((key) => {
				const spend = sumBy(copy[key], 'spend');
				const purchaseCount = sumBy(copy[key], 'purchaseCount');
				const cpl = purchaseCount === 0 ? 0 : spend / purchaseCount;
				copy[key] = {
					cpl,
					spend,
					purchaseCount,
				};
			});

			return copy;
		},
		groupedCampaigns() {
			let campaigns = cloneDeep(this.campaigns);
			if (!this.show_archived) {
				campaigns = campaigns.filter((campaign) => {
					return campaign.status !== 'archived';
				});
			}

			const campaign_groups = groupBy(campaigns, (campaign) => {
				return startCase(campaign[this.group_value]) + ' Campaigns';
			});

			return campaign_groups;
		},
	},
	async mounted() {
		this.getCampaigns(true);
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		get,
		kebabCase,
		map,
		phoneFormat,
		startCase,
		upperCase,
		verticalName,
		statusDisabled(campaign) {
			return (
				!this.$root.sessionStore.isAdminUser &&
				(this.isLocked(campaign) || this.noLocationTargeting(campaign) || campaign.status === 'pending')
			);
		},
		isLocked(campaign) {
			return !this.$root.sessionStore.isAdminUser && get(campaign.flags, 'lock.enabled', false);
		},
		noLocationTargeting(campaign) {
			const targeting_mode = campaign.location_targeting_mode || 'state';
			if (targeting_mode === 'state') {
				if (campaign.state_targeting.length === 0) return true;
			} else {
				if (campaign.zip_targeting.length === 0) return true;
			}
			return false;
		},
		async archiveCampaign(id) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			try {
				await updateCampaign(id, { status: 'archive' });
				found.status = 'archive';
				this.$toast.add({
					severity: 'success',
					summary: 'Campaign has been archived',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
			}
		},
		budgetCount(budgets) {
			return budgets.filter((budget) => {
				return budget.enabled;
			}).length;
		},
		budgetList(budgets) {
			const active_budgets = budgets.filter((budget) => {
				return budget.enabled;
			});

			let budget_list = `${active_budgets.length} Active Budgets:\n`;
			active_budgets.forEach((budget) => {
				let amount = budget.limit;
				if (budget.type === 'spend') {
					amount = currency(budget.limit);
				}
				budget_list += [startCase(budget.type), 'per', startCase(budget.period)].join(' ') + ' (' + amount + ')\n';
			});

			return budget_list;
		},
		async unarchive(id) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			try {
				await updateCampaign(id, { status: 'paused' });
				found.status = 'paused';
				this.$toast.add({
					severity: 'success',
					summary: 'Campaign has been un-archived',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
			}
		},
		async deleteCampaign(id) {
			try {
				const deleted_campaign = await deleteCampaign(id);
				await this.getCampaigns(true);
				this.$toast.add({
					severity: 'success',
					summary: `Campaign named "${deleted_campaign.name}" was deleted`,
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete the campaign',
				});
			}
		},
		async handleStatusUpdate({ value }: { value: string }, id: string) {
			this.loading = true;
			const found = find(this.campaigns, { id });
			if (value === null) value = 'paused';
			try {
				await updateCampaign(id, { status: value });
				found.status = value;
				this.$toast.add({
					severity: 'success',
					summary: 'Status Updated',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update status',
				});
			} finally {
				this.loading = false;
			}
		},
		async pauseAllCampaigns() {
			try {
				await asyncForEach(this.campaigns, async (campaign) => {
					if (campaign.status === 'active') {
						await updateCampaign(campaign.id, { status: 'paused' });
						campaign.status = 'paused';
					}
				});
				this.$toast.add({
					severity: 'success',
					summary: 'All campaigns have been paused.',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to turn off campaigns.',
					detail: err.message,
				});
			}
		},
		clearQuery() {
			this.loading = false;
		},
		async getCampaigns(refresh?: boolean) {
			this.loading = true;
			// transformfilters and call getCampaigns
			const filters: FilterParams = [
				{
					op: 'eq',
					value: this.account_id,
					field: 'account_id',
				},
			];

			const dated_params = [
				{
					field: 'created_at',
					op: 'gt',
					value: this.query.filters.created_at[0],
				},
				{
					field: 'created_at',
					op: 'lt',
					value: this.query.filters.created_at[1],
				},
			];

			try {
				const results = await getCampaigns([filters], [dated_params], refresh);
				log.trace('Results', results);

				this.campaigns = results;
			} catch (err) {
				log.error('GetCampaign', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Campaigns',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.create-new-campaign {
	background-color: var(--gray-10);
	border-radius: 10px;
	padding: 30px;

	img {
		opacity: 0.65;
		width: 500px;
	}

	h3 {
		font-size: 2rem;
		font-weight: bold;
	}

	p {
		font-size: 1rem;
	}
}

h2 {
	font-size: 1.25rem;
	margin-bottom: 0.5em;
}

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.flex-group {
	.caption {
		font-size: 0.75rem;
		text-transform: uppercase;
	}
}

.campaign-group {
	margin-bottom: 2em;
}

:deep(.p-selectbutton.campaign-status-switch .p-button) {
	font-size: 0.625rem;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
}

.budgets {
	background-color: var(--gray-30);
	border-radius: 3px;
	color: white;
	cursor: default;
	padding: 0 5px;
}

.budget-count {
	font-size: 0.75rem;
	margin-left: 5px;
}

.locked-icon {
	align-items: center;
	cursor: no-drop;
	display: inline-flex;
	height: 30px;
	justify-content: center;
	margin-left: 5px;
	width: 20px;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.p-tag {
	font-size: 0.625rem;
	padding: 0.5em 1em;

	&.exclusive {
		background: var(--teal);
	}

	&.data {
		background: #5cadc4;
	}

	&.call {
		background: var(--purple-400);
	}

	&.pingpost {
		background: var(--orange-500);
	}

	&.night {
		background: var(--purple-600);
	}

	&.weekend {
		background: var(--red-600);
	}

	&.live-transfer {
		background: #82bd41;
	}

	&.choice {
		background: var(--gray-50);
	}

	&.premium,
	&.promo {
		background: var(--yellow-600);
	}

	&.advanced {
		background: var(--orange);
	}

	&.locked {
		background: var(--red);
	}
}
</style>
