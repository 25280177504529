<template>
	<div id="API-Keys" class="view-content">
		<div class="page-heading">
			<h1>API Keys</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="Add API Key" @click="$router.push(`${$route.path}/new`)" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="api-keys-table">
				<p-data-table ref="dt" paginator :rows="10" scrollable :value="activeKeys" :loading="loading">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column field="name" header="Name" sortable sortField="Name">
						<template #body="key">
							<div class="flex align-items-center gap-2">
								<router-link :to="`/api-keys/${key.data.id}`">
									{{ key.data.name }}
								</router-link>
								<span v-if="key.data.status === 'disabled'" class="disabled"> (disabled) </span>
							</div>
						</template>
					</p-column>
					<p-column field="key" header="Key" sortable sortField="key">
						<template #body="key">
							<div class="flex align-items-center gap-2">
								{{ key.data.key }}
								<div class="toolset">
									<div class="tool">
										<p-button
											text
											v-tooltip.top="'Copy Key'"
											aria-label="Copy Key"
											@click="copyToClipboard(key.data.key)"
										>
											<template #icon>
												<icon type="clipboard-plus-outline" size="16px" />
											</template>
										</p-button>
									</div>
								</div>
							</div>
						</template>
					</p-column>
					<p-column field="role_id" header="Role" class="align-center" sortable sortField="role_id">
						<template #body="key">
							{{ capitalize(key.data.role_id) }}
						</template>
					</p-column>
					<p-column field="status" header="Status" class="align-center" sortable sortField="status">
						<template #body="key">
							{{ capitalize(key.data.status) }}
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="key">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/api-keys/${key.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button
										v-tooltip.top="key.data.status === 'active' ? 'Archive' : 'Unarchive'"
										aria-label="Archive"
										@click.prevent="handleArchive(key.data)"
									>
										<template #icon>
											<icon v-if="key.data.status === 'active'" type="archive" size="20px" />
											<icon v-else type="archive-off" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
				<p-confirm-dialog />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize } from 'lodash-es';
import { getAPIKeys, updateAPIKey } from '@GQL';
import showArchived from '@/components/widgets/ShowArchived.vue';
import pConfirmDialog from 'primevue/confirmdialog';
import log from '@/lib/Log';

export default {
	name: 'APIKeys',
	components: {
		pConfirmDialog,
		showArchived,
	},
	data() {
		return {
			breadcrumb_items: [],
			show_copied: false,
			keys: [],
			loading: false,
			show_archived: false,
			filters: [],
		};
	},
	async mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'API Keys',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'API Keys',
				},
			];
		}
		return await this.getList();
	},
	computed: {
		activeKeys() {
			if (this.show_archived) {
				return this.keys;
			}
			return this.keys.filter((key) => key.status !== 'archived');
		},
	},
	methods: {
		capitalize,
		async getList() {
			this.loading = true;
			try {
				this.keys = await getAPIKeys([]);
			} catch (e) {
				log.trace('API Keys getList', e);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get list',
				});
			} finally {
				this.loading = false;
			}
		},
		copyToClipboard(string) {
			navigator.clipboard.writeText(string);
			this.$toast.add({
				severity: 'success',
				summary: 'Copied to Clipboard',
				detail: string,
				life: 3000,
			});
		},
		async handleArchive(key) {
			if (key.status === 'archived') {
				try {
					await updateAPIKey(key.id, { status: 'active' });
					const matching_key = this.keys.find((local_key) => {
						return local_key.id === key.id;
					});
					if (matching_key) {
						matching_key.status = 'active';
					}
					this.$toast.add({
						severity: 'success',
						summary: 'Activated',
						detail: `The API key was activated: ${key.key}`,
						life: 3000,
					});
				} catch (err) {
					log.trace(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to activate API Key',
						life: 3000,
					});
				}
			} else {
				this.$confirm.require({
					header: 'Archive API Key',
					message: 'Are you sure you want to archive this API Key?',
					icon: 'pi pi-exclamation-triangle',
					acceptClass: 'p-button-danger',
					accept: async () => {
						try {
							await updateAPIKey(key.id, { status: 'archived' });
							const matching_key = this.keys.find((local_key) => {
								return local_key.id === key.id;
							});
							if (matching_key) {
								matching_key.status = 'archived';
							}
							this.$toast.add({
								severity: 'success',
								summary: 'Archived',
								detail: `Archived key: ${key.key}`,
								life: 3000,
							});
						} catch (err) {
							log.trace(err);
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to archive API Key',
								life: 3000,
							});
						}
					},
					reject: () => {
						this.$toast.add({
							severity: 'info',
							summary: 'Cancel',
							detail: 'Cancelled Archival',
							life: 3000,
						});
					},
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.disabled {
	color: var(--gray-50) !important;
}
</style>
