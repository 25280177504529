<template>
	<div id="inner-wrapper" class="public">
		<main>
			<div v-if="this.$route.meta.show_header" class="toolbar">
				<img class="logo cursor-pointer" :src="logoUrl" alt="NextGen Leads Logo" @click="this.$router.push('/login')" />
				<div class="right">
					<!-- <div class="return" v-if="!mq.phone">
						Already have an account?
						<p-button
							rounded
							aria-label="Login"
							label="Login"
							@click="this.$router.push('/login')"
							:pt="{
								root: { class: 'bg-black-alpha-90 border-black-alpha-90' },
							}"
						/>
					</div> -->
					<div class="contact">Contact Support: <a href="tel:8587036007">(858) 703-6007</a></div>
				</div>
			</div>
			<div v-if="this.$route.meta.show_header" class="return-section">
				Already have an account?
				<p-button
					rounded
					aria-label="Login"
					label="Login"
					@click="this.$router.push('/login')"
					:pt="{
						root: { class: 'bg-black-alpha-90 border-black-alpha-90' },
					}"
				/>
			</div>
			<div id="view">
				<router-view />
			</div>

			<div v-if="this.$route.meta.show_footer" class="footer">
				<div class="flex flex-column">
					<div class="brand">&copy;{{ year }} by NextGen Leads</div>
				</div>
				<div class="legal">
					<router-link :to="{ path: '/terms' }">Terms and Conditions</router-link> |
					<router-link :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>
					<div class="contact">Contact Support: <a href="tel:8587036007">(858) 703-6007</a></div>
				</div>
			</div>
		</main>
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import { formatDate } from '@/lib/Filters';

export default {
	name: 'PublicWrapper',
	inject: ['mq'],
	computed: {
		logoUrl() {
			return get(this.$root.appStore, 'marketplace.settings.logo_url', '/assets/img/logos/ngl-logo.svg');
		},
		year() {
			return formatDate(new Date(), 'YYYY');
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.toolbar {
	align-items: center;
	background: var(--white);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-height: 120px;
	padding: 1rem;
	width: 100%;

	.logo {
		max-height: calc(60px - 2rem);
	}

	.return {
		align-items: center;
		display: flex;
		gap: 10px;
		justify-content: space-between;
	}
}

.right {
	display: flex;
	flex-direction: column;
}

.contact {
	font-size: 0.75rem;
	margin: 0.5rem 0;
}

.return-section {
	align-items: center;
	background-color: var(--surface-background);
	box-shadow: var(--shadow-default);
	display: flex;
	flex-wrap: wrap;
	font-size: var(--font-size-sm);
	gap: 1rem;
	justify-content: flex-end;
	padding: 8px;
	.mobile({
		justify-content: space-between;
		text-align: center;
	});
}

.footer {
	align-items: center;
	background: var(--gray-05);
	border-top: 1px solid var(--gray-20);
	display: flex;
	flex-wrap: wrap;
	font-size: var(--font-size-sm);
	gap: 1rem;
	justify-content: space-between;
	padding: 1rem 2rem;
	width: 100%;

	.brand,
	.legal {
		font-size: 0.75rem;

		a {
			color: var(--gray-45);
		}
	}
}
</style>
