<template>
	<div id="action-bar">
		<p-dialog modal v-model:visible="show_validation_modal">
			<template #header>
				<div class="flex align-items-center" style="gap: 10px">
					<icon type="alert" size="24px" /><strong>Your Campaign Has Errors!</strong>
				</div>
			</template>
			<p>Please fix the following errors before saving your campaign...</p>
			<ul class="bulleted">
				<li v-for="error in $parent.$refs.edit_campaign.campaignErrors" class="validation-error">
					{{ error.$message }}
				</li>
			</ul>
		</p-dialog>
		<p-confirm-dialog :group="confirm_id" style="max-width: 500px" />
		<p-button
			v-if="step !== 'select_vertical' && step !== 'edit_campaign'"
			icon="pi pi-arrow-left"
			label="Go Back"
			text
			@click="handlePrevStep"
		/>

		<p-button class="cancel" text label="Cancel" @click="handleCancel" />
		<p-button
			v-if="step !== 'edit_campaign'"
			:disabled="disableNext"
			class="right"
			label="Next"
			@click="handleNextStep"
		/>
		<p-button v-else label="Save Campaign" @click="saveCampaign" />
	</div>
</template>

<script lang="ts">
import { get, uniqueId } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pConfirmDialog from 'primevue/confirmdialog';
import log from '@/lib/Log';
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { useAppStore } from '@/stores/app';
import routeParent from '@/lib/Utils/routeParent';

export default {
	components: {
		pDialog,
		pConfirmDialog,
	},
	props: {
		step: {
			type: String,
		},
		disableNext: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	setup() {
		return {
			appStore: useAppStore(),
			campaignStore: useCampaignStore(),
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			confirm_id: uniqueId(),
			show_validation_modal: false,
			show_targeting_modal: false,
		};
	},
	computed: {
		isTemplate() {
			return this.$route.meta.template_mode;
		},
		account_id() {
			let acc = this.sessionStore.user.account_id;
			if ('account_id' in this.$route.params) {
				acc = this.$route.params.account_id;
			}
			if ('parent_account_id' in this.$route.params) {
				acc = this.$route.params.parent_account_id;
			}
			return acc;
		},
	},
	methods: {
		handleCancel() {
			this.$router.back();
		},
		handlePrevStep() {
			this.$emit('prev');
		},
		async handleNextStep() {
			if (this.step === 'edit_campaign') {
				await this.saveCampaign();
			} else {
				this.$emit('next');
			}
		},
		async saveCampaign() {
			// Or check for meta.new in the route...
			if (this.$route.meta.new) {
				// Only for createCampaign...
				await this.campaignStore.$patch({
					campaign: {
						mpid: this.account_id.slice(0, 3),
						account_id: this.account_id,
					},
				});
			}

			// Validate from the Edit Campaign component
			try {
				await this.$parent.$refs.edit_campaign.v$.$validate();
				if (this.$parent.$refs.edit_campaign.campaignErrors.length > 0) {
					throw new Error('Campaign is invalid');
				}
			} catch (err) {
				this.show_validation_modal = true;
				return;
			}

			// Check if location targeting is set
			let no_location_targeting = false;
			const location_bids = this.campaignStore.campaign.bids.filter((bid) => {
				return bid.type === 'location';
			});
			if (location_bids.length === 1) {
				if (get(location_bids[0], 'match[0].comparator.value.length', 0) === 0) {
					no_location_targeting = true;
				}
			} else if (location_bids.length === 0) {
				no_location_targeting = true;
			}

			if (no_location_targeting) {
				// Pop confirmation dialog
				this.$confirm.require({
					group: this.confirm_id,
					header: 'No Location Targeting!',
					message:
						'This campaign does not have any location targeting. You will not be able to turn on this campaign until location targeting is set.',
					icon: 'pi pi-info-circle',
					acceptClass: this.acceptClass || 'p-button-danger',
					acceptIcon: 'pi pi-check',
					acceptLabel: this.acceptLabel || 'Save Anyway',
					rejectLabel: this.rejectLabel || 'Cancel',
					reject() {
						return false;
					},
					accept: async () => {
						if (this.campaignStore.campaign.status === 'active') {
							this.campaignStore.campaign.status = 'paused';
						}
						await this.finalizeSave();
					},
				});
			} else {
				await this.finalizeSave();
			}
		},
		async finalizeSave() {
			try {
				await this.campaignStore.save();

				// change the route now...
				this.$toast.add({
					severity: 'success',
					summary: 'Campaign saved',
					life: 3000,
				});
				this.campaignStore.touched = false;
				this.$router.push({
					name: routeParent(this.$route.name, 'CampaignList'),
				});
			} catch (err) {
				log.error('Save err', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save campaign',
					detail: err.message.slice(0, 20),
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
#action-bar {
	background-color: white;
	border-top: 1px solid var(--gray-10);
	bottom: 0;
	box-shadow: 0 0 10px rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: flex-start;
	padding: 20px 40px;
	position: sticky;
	transform: translate(-40px, 40px);
	width: calc(100% + 80px);
	z-index: 25;

	.cancel {
		color: var(--gray-50);
	}
}
</style>

<style>
#cancel-modal {
	width: auto !important;
}
</style>
