<template>
	<div id="live-transfers-leads-list">
		<query-form v-model:query="query" :loading="loading" @apply="getAdminLiveTransfersReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Type:</div>
					<div class="value">
						{{ displaySelected(query.filters.source_type, source_type_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getAdminLiveTransfersReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.filters.created_at" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of appStore.verticalOptions"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Source Type:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.source_type"
											:options="source_type_options"
											option-label="label"
											option-value="value"
											placeholder="Select Source Type"
											class="source-type-select w-full md:w-20rem"
										>
										</p-multi-select>
									</div>
								</div>
							</div>
						</div>
						<gutter size="20px" />
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Query By Source Code:</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="query.filters.source_search" class="search-query" type="text" />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Live Transfer Count" :value="row_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Revenue Per Lead" :value="revenuePerLead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Revenue" :value="revenue" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="quick-filters">
			<span class="m-2">Quick Filter:</span>
			<div class="toggles">
				<quick-filter v-model="quick_filters.vertical_id" value="health_insurance" allow-multiple>
					Health Insurance
				</quick-filter>
				<quick-filter v-model="quick_filters.vertical_id" value="medicare" allow-multiple> Medicare </quick-filter>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				:rows="100"
				scrollable
				:value="filteredLeads"
				data-key="id"
				:key="refreshKey"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column header="Captured On">
					<template #body="lead">
						<div class="capture-date">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</div>
						<div class="sub-value">{{ capitalize(timeAgo(lead.data.created_at)) }}</div>
					</template>
				</p-column>
				<p-column header="Sold On">
					<template #body="lead">
						<template v-if="lead.data.first_purchase_date">
							<div class="sold-date">
								{{ formatDate(new Date(lead.data.first_purchase_date), 'YYYY-MM-DD | h:mm:ssa') }}
							</div>
							<div class="sub-value">
								{{ capitalize(timeAgo(lead.data.first_purchase_date)) }}
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="Lead Info">
					<template #body="lead">
						<router-link :to="`/leads/${lead.data.id}`">
							<strong>{{ lead.data.first_name }} {{ lead.data.last_name }}</strong>
						</router-link>
						<div class="sub-value">{{ lead.data.id }}: {{ phoneFormat(lead.data.phone) }}</div>
					</template>
				</p-column>
				<p-column header="Source">
					<template #body="lead">
						{{ lead.data.vendor_id }}: {{ lead.data.source_id }}
						<div class="sub-value">{{ lead.data.sub_id }}</div>
					</template>
				</p-column>
				<p-column header="State" class="column-align-center">
					<template #body="lead">
						{{ lead.data.state }}
					</template>
				</p-column>
				<p-column header="Vertical" class="column-align-center">
					<template #body="lead">
						<vertical-icon :vertical-id="lead.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column header="Purchases" class="column-align-center">
					<template #body="lead">
						<template v-if="lead.data.purchases && lead.data.purchases.length > 0">
							<div class="click-target" @click="openAuctionLogModal(lead.data.id, lead.data.auction_log_id)">
								<div
									class="purchase-count"
									v-tooltip.left="{ value: purchaseDetail(lead.data.purchases), escape: false }"
								>
									{{ lead.data.purchases.length }}
									<div class="inline-icon"><icon type="open-in-new" size="16px" /></div>
								</div>
							</div>
							<AuctionModal ref="auction_modal" :lead="lead.data" />
						</template>
						<template v-else> -- </template>
					</template>
				</p-column>
				<p-column header="Revenue" class="column-align-right">
					<template #body="lead">
						{{ lead.data.purchases && lead.data.purchases.length > 0 ? currency(lead.data.revenue) : '--' }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />
		</div>
		<AuctionModal ref="auction_modal" />
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { startCase, map, isEqual, filter } from 'lodash-es';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, capitalize, timeAgo } from '@/lib/Filters';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import STATES from '@/lib/Data/states.json';
import { source_type_options } from '@/lib/Options';
import { reportAdminLeads } from '@GQL';

export default {
	name: 'DataLeadsList',
	components: {
		AuctionModal,
		dateRange,
		queryForm,
		quickFilter,
		typeIcon,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					source_type: [],
					source_search: '',
					state: [],
				},
				pagination: {
					page_size: 100,
					page: 0,
				},
			},
			row_count: 0,
			revenue: 0,
			quick_filters: {
				vertical_id: [],
			},
			leads: [],
			state_options: [...STATES],
			source_type_options,
			refreshKey: 0,
		};
	},
	computed: {
		revenuePerLead() {
			if (this.row_count > 0) {
				return this.revenue / this.row_count;
			}
			return 0;
		},
		filteredLeads() {
			return this.leads.filter((lead) => {
				if (this.quick_filters.vertical_id.length) {
					return this.quick_filters.vertical_id.includes(lead.vertical_id);
				}
				return true;
			});
		},
		...mapStores(useAppStore, useSessionStore),
	},
	async created() {
		await this.getAdminLiveTransfersReport();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displaySelected,
		timeAgo,
		map,
		parseISO,
		phoneFormat,
		startCase,
		verticalName,
		clearQuery() {
			this.loading = false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page + 1;
			return this.getAdminLiveTransfersReport();
		},
		async getAdminLiveTransfersReport(refresh) {
			this.loading = true;

			const query_params = {
				date_range: this.query.filters.created_at,
				filters: [],
				product: 'live_transfer',
				page: this.query.pagination.page,
			};

			const filters = [];

			if (this.query.filters.vertical_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.source_type.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.source_type',
					value: this.query.filters.source_type,
				});
			}

			if (this.query.filters.source_search) {
				filters.push({
					op: 'eq',
					field: 'l.source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.state',
					value: this.query.filters.state,
				});
			}

			if (filters.length > 0) {
				query_params.filters.push(filters);
			}

			try {
				const report = await reportAdminLeads(query_params, refresh);
				this.revenue = report.metrics.revenue;
				this.leads = report.rows;
				this.row_count = report.row_count;
				if (refresh) {
					this.refreshKey += 1;
				}
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Data Leads',
					life: 3000,
				});
			}
			this.loading = false;
		},
		openAuctionLogModal(lead_id, auction_log_id) {
			this.$refs.auction_modal.open(lead_id, auction_log_id);
		},
		purchaseDetail(purchases) {
			let output = '';
			purchases.forEach((purchase) => {
				output += `<div>${capitalize(purchase.product)}: ${capitalize(purchase.account_name)} - ${currency(
					purchase.price
				)}${['return_pending', 'return_accepted'].includes(purchase.status) ? '* (' + currency(purchase.original_price) + ')' : ''}</div>`;
			});
			return output;
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.click-target .mdi-icon {
	color: var(--color-b);
	cursor: pointer;
}
</style>
