<template>
	<div>
		<div class="content">
			<p-card class="source-card">
				<template #content>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="control-label req" for="source">Source ID:</label>
									<div class="p-inputgroup">
										<div v-if="existing_record" class="p-inputgroup-addon">
											<icon type="lock" />
										</div>
										<p-input-text id="source" v-model="sourceId" :disabled="!$route.meta.new" />
									</div>
									<div v-if="v$.source.source_id.$error">
										<p v-for="error in v$.source.source_id.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label" for="description">Description:</label>
							<div class="controls">
								<div class="field">
									<p-text-area
										id="description"
										v-model="source.description"
										class="w-full"
										placeholder="Enter additional description"
										style="min-height: 4rem"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="biz_unit">Network:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="network_id"
										v-model="source.network_id"
										:options="network_options"
										placeholder="Select Network"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.source.network_id.$error">
										<p v-for="error in v$.source.network_id.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="biz_unit">Biz Unit:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="biz_unit"
										v-model="source.biz_unit"
										:options="biz_unit_options"
										placeholder="Select Biz Unit"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.source.biz_unit.$error">
										<p v-for="error in v$.source.biz_unit.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="source_type">Type:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="source_type"
										v-model="source.source_type"
										:options="source_type_options"
										placeholder="Select Source Type"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.source.source_type.$error">
										<p v-for="error in v$.source.source_type.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label" for="source_tags">Source Tags:</label>
							<div class="controls">
								<div class="field">
									<p-chips
										add-on-blur
										id="source_tags"
										v-model="source.source_tags"
										separator=","
										placeholder="Separate by comma or by hitting enter"
									/>
								</div>
							</div>
						</div>
					</div>
					<p-fieldset legend="Sub ID Map">
						<p>Select the attributes below to determine the structure of sub IDs for this source.</p>
						<option-picklist v-model="source.sub_id_map" :options="sub_id_map_options" height="320px" />
					</p-fieldset>
					<gutter size="20px" />
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="source_manager">Source Manager:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="source_manager"
										v-model="source.source_manager"
										:options="user_options"
										placeholder="Select Source Manager"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.source.source_manager.$error">
										<p v-for="error in v$.source.source_manager.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="status">Status:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="status"
										v-model="source.status"
										:options="source_status_options"
										placeholder="Select Status"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.source.status.$error">
										<p v-for="error in v$.source.status.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #footer>
					<div class="flex justify-content-between align-items-center">
						<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
						<p-button icon="pi pi-check" label="Save Source" @click.prevent="save" />
					</div>
				</template>
			</p-card>
		</div>
	</div>
</template>

<script lang="ts">
import { source_status_options, source_type_options, biz_unit_options, sub_id_map_options } from '@/lib/Options';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { initSourceForm, insertSource, updateSource } from '@GQL';
import pTextArea from 'primevue/textarea';
import pChips from 'primevue/chips';
import { getInternalUsersAsOptions } from '@GQL/queries';
import { camelCase, cloneDeep, merge, omit } from 'lodash-es';
import optionPicklist from '@/components/forms/OptionPicklist.vue';

const default_source = {
	source_id: '', // MAKE SURE THAT THIS IS NOT EDITABLE ON EDIT, ONLY ON INSERT
	sub_id_map: [],
	network_id: '',
	source_tags: [], // THIS SHOULD BE A CHIPS INPUT
	biz_unit: '', // DROP DOWN
	source_type: '',
	status: 'new', // the defualt
	source_manager: '',
	description: '',
};

export default {
	name: 'SourceForm',
	components: {
		pTextArea,
		pChips,
		optionPicklist,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			existing_record: false,
			source_type_options,
			biz_unit_options,
			source_status_options,
			sub_id_map_options,
			source: cloneDeep(default_source),
			network_options: [],
			user_options: [],
			loading: false,
		};
	},
	computed: {
		sourceId: {
			get() {
				return this.source.source_id;
			},
			set(new_value) {
				this.source.source_id = new_value.replace(/\W/, '_').toUpperCase();
			},
		},
	},
	validations() {
		return {
			source: {
				source_id: {
					required,
					custom: helpers.withMessage('Value must be camel case', camelCase),
				},
				network_id: { required },
				source_type: { required },
				biz_unit: { required },
				source_manager: { required },
				status: { required },
			},
		};
	},
	async mounted() {
		try {
			this.loading = true;
			const source_id = this.$route.params.source_id || this.$route.query.source_id || 'new';
			const init_data = await initSourceForm(source_id);
			if (source_id) {
				if (init_data.source) {
					this.source = merge(default_source, init_data.source);

					if (this.$route.meta.new) {
						this.source.source_id += '_COPY';
					}
				}
			}
			this.network_options = init_data.networks;
			this.user_options = await getInternalUsersAsOptions();
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async save() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				const new_source = cloneDeep(this.source);
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertSource(new_source);
					} else {
						result = await updateSource(this.source.source_id, omit(new_source, 'source_id'));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.$route.meta.new ? 'inserted new' : 'updated'} source`,
							detail: `${new_source.source_id}`,
							life: 3000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} source`,
							detail: `${new_source.source_id}`,
							life: 3000,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} source`,
						detail: `${new_source.source_id}`,
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
label {
	font-size: var(--font-size-sm);
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.sub-value {
	color: var(--gray-50);
	display: inline-block;
	font-size: var(--font-size-sm);
}

.source-card {
	max-width: 800px;
}
</style>
