<template>
	<div class="attribute-section" :class="{ active: enable_condition_filter }">
		<div class="header">
			<div class="title">
				<label class="clickable-label" for="condition-switch">Target by Medical Conditions</label>
			</div>
			<div class="switch">
				<p-input-switch v-model="enable_condition_filter" input-id="condition-switch" />
			</div>
		</div>
		<div v-if="enable_condition_filter" class="filter">
			<p>Select the medical conditions that you would like to <strong>EXCLUDE</strong> from targeting.</p>
			<ul class="options">
				<li v-for="condition of condition_options" :key="condition.value">
					<div class="flex align-items-center">
						<p-checkbox
							v-model="custom_bid_conditions"
							:input-id="condition.label"
							name="condition"
							:value="condition.value"
						/>
						<label :for="condition.label" :style="{ 'margin-bottom': 0 }">{{ condition.label }}</label>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MedicalConditionsAttribute',
	emits: ['update'],
	data() {
		return {
			condition_options: [
				{ value: 'AIDS/HIV', label: 'AIDS/HIV' },
				{ value: 'Cancer', label: 'Cancer' },
				{ value: 'Diabetes', label: 'Diabetes' },
				{ value: 'Heart Attack', label: 'Heart Attack' },
				{ value: 'Pulmonary Disease', label: 'Pulmonary Disease' },
				{ value: 'Stroke', label: 'Stroke' },
			],
			enable_condition_filter: false,
			custom_bid_conditions: null,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
					invert_basic: false,
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const conditions_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.medical_conditions';
			});
			if (conditions_match) {
				this.enable_condition_filter = true;
				this.custom_bid_conditions = conditions_match.comparator.value;
			}
		},
		saveAttribute() {
			if (this.enable_condition_filter) {
				return {
					target: {
						path: 'lead.data.medical_conditions',
					},
					strategy: 'intersects',
					comparator: {
						value: this.custom_bid_conditions,
					},
				};
			}
			return null;
		},
	},
};
</script>
