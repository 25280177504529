<template>
	<p-dialog
		v-model:visible="show_modal"
		class="action-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>{{ note_type === 'amw_action' ? `Take Action: ${account_name}` : `Edit Disposition + Note` }}</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Disposition:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="disposition"
								name="disposition"
								:options="dispositionOptions"
								option-label="label"
								option-value="value"
							/>

							<div v-if="v$.disposition.$error" class="validation-error">
								{{ v$.disposition.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="['Snoozing Alerts', 'OE/AEP'].includes(disposition)" class="control-group">
				<div class="inner">
					<label class="control-label req">Away until:</label>
					<div class="controls">
						<div class="field required">
							<p-calendar v-model="away_until" date-format="D, M d, yy" :manual-input="false" />
						</div>
					</div>
				</div>
			</div>

			<div v-if="disposition === 'Reactivation Reply'" class="control-group">
				<div class="inner">
					<label class="control-label req">Offer to Attach:</label>
					<div class="controls">
						<div class="field required">
							<p-dropdown
								v-model="offer_id"
								name="offer"
								:options="offer_options"
								option-label="label"
								option-value="value"
							/>

							<div v-if="v$.offer_id.$error" class="validation-error">
								{{ v$.offer_id.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<EmailNoteComponent
				ref="email_note_component"
				:data="{ disposition, account_id }"
				template_types="existing_accounts"
			/>
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Submit" @click.prevent="submit" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import pTextArea from 'primevue/textarea';
import { insertNote, updateAccount, getOffersAsOptions, sendGmail, insertAccountOffer } from '@GQL';
import { disposition_options } from '@/lib/Options';
import EmailNoteComponent from '@/views/FollowUps/Record/EmailNoteComponent.vue';
import pCalendar from 'primevue/calendar';
import log from '@/lib/Log';

export default {
	name: 'ActionModal',
	props: {
		account_name: {
			type: String,
			required: true,
		},
		account_id: {
			type: String,
			required: true,
		},
		note_type: {
			type: String,
			default() {
				return 'amw_action';
			},
		},
		account_support_status: {
			type: String,
		},
	},
	components: {
		pTextArea,
		EmailNoteComponent,
		pCalendar,
	},
	emits: ['refresh'],
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			show_modal: false,
			disposition: '',
			loading: false,
			away_until: new Date(),
			disposition_options,
			offer_options: [],
			offer_id: '', // used and required only when using Reactivation Reply dispo
		};
	},
	computed: {
		dispositionOptions() {
			const existing = this.disposition_options.filter((row) => {
				if (row.type) {
					return row.type !== 'new' && ((this.account_support_status || '') === row.type || row.type === 'existing');
				} else {
					return true;
				}
			});
			return existing;
		},
	},
	methods: {
		async openModal() {
			if (this.account_support_status === 'reactivation') {
				// async load options
				this.offer_options = await getOffersAsOptions([[`status = 'active'`, `'reactivation' = ANY(tags)`]]);
			}
			this.reset();
			this.show_modal = true;
		},
		reset() {
			this.disposition = '';
		},
		async submit() {
			const data = await this.$refs.email_note_component.getData();
			try {
				const is_valid = await this.v$.$validate();
				if (is_valid && data) {
					this.loading = true;
					try {
						let note_data = {
							disposition: this.disposition,
						};

						// now if there's html and subject, make the Gmail request
						if ('body' in data) {
							// we gotta send the Gmail
							try {
								note_data.email_template_id = data.template_id;
								const gmail_res = await sendGmail({
									account_id: this.account_id,
									sender_user_id: this.$root.sessionStore.user.id,
									body: data.body,
									subject: data.subject,
									from_email: this.$root.sessionStore.user.email,
									from_name: this.$root.sessionStore.user.first_name + ' ' + this.$root.sessionStore.user.last_name,
									to_email: data.to_email,
									to_name: data.to_name,
								});
							} catch (err) {
								await log.error(err);
							}
						}

						let note_type = this.note_type;
						let note_body = data.note;
						if (this.disposition === 'Reactivation Reply') {
							note_type = 'reactivation_reply';
							note_body += `\r Offer Attached: ${this.offer_id}`;
							note_data.offer_id = this.offer_id;
						}

						const account_update = {
							support: {
								disposition: this.disposition,
							},
							modified_at: new Date(),
						};

						if (this.disposition === 'Snoozing Alerts') {
							// lets add away status and also until ...
							account_update.support.date_of_return = this.away_until;
							note_data.date_of_return = this.away_until;
						}

						if (note_type == 'reactivation_reply' && this.offer_id !== '') {
							account_update.support.account_manager_id = this.$root.sessionStore.user.id;
							// we do a normal offer account insert
							try {
								await insertAccountOffer(
									{
										account_id: this.account_id,
										offer_id: this.offer_id,
										user_id: this.$root.sessionStore.user.id,
										status: 'pending',
									},
									true
								);
							} catch (err) {
								console.error(err, 'Account Offer Failure');
								this.$toast.add({
									severity: 'error',
									summary: 'Unable to attach offer selected',
									life: 7000,
								});
							}
						}

						const note_res = await insertNote({
							account_id: this.account_id,
							body: note_body,
							type: note_type,
							source: 'UI User created',
							data: note_data,
							created_by: this.$root.sessionStore.user.id,
						});

						const account_res = await updateAccount(this.account_id, account_update);

						if ('id' in note_res) {
							// close the modal & reset
							this.$toast.add({
								severity: 'success',
								summary: 'Action was completed',
								detail: 'The note and the update to the disposition were successful',
								life: 6000,
							});
							this.$emit('refresh', { account_id: this.account_id, disposition: this.disposition });
						}

						this.show_modal = false;
						this.$refs.email_note_component.reset(); // do it at the end...
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to complete action',
							detail: err.message.slice(0, 50),
							life: 6000,
						});
						await log.error(err);
					}
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Required fields may be missing',
						life: 5000,
					});
				}
			} finally {
				this.loading = false;
			}
		},
	},
	validations() {
		return {
			disposition: {
				required: helpers.withMessage('Select a valid disposition', required),
			},
			offer_id: {
				required: helpers.withMessage(
					'Offer is required for this disposition',
					requiredIf(this.disposition === 'Reactivation Reply')
				),
			},
		};
	},
};
</script>
