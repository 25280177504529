import { $GQL } from '@GQL/index';

export async function checkExistingNetworkId(network_id: string): Promise<Boolean> {
	const query = `
		query CheckExistingNetworkId($network_id: ID!) {
			network(id: $network_id) {
				id
			}
		}
	`;

	const result = await $GQL.request(query, { network_id });
	return !!result.network;
}
