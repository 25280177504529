import { inject } from 'vue';
import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { useRoute } from 'vue-router';
import { ulid } from 'ulid';
import type { Campaign, CustomBid } from '@nextgenleads/marketplace-driver';
import { diff } from 'deep-object-diff';
import {
	getCampaignById,
	getChannelById,
	getPromotionById,
	insertCampaign,
	updateCampaign,
	insertIntegration,
	getIntegrationById,
	getAccountById,
} from '@GQL';
import {
	cloneDeep,
	findIndex,
	get,
	groupBy,
	isNil,
	isPlainObject,
	max,
	mergeWith,
	min,
	omit,
	round,
	pick,
} from 'lodash-es';
import log from '@/lib/Log';
import { deepClean } from '@/lib/Utils/deepClean';

export interface CustomBidWithId extends CustomBid {
	id?: string;
}

export interface CampaignForEditing extends Campaign {
	bids: CustomBidWithId[];
}

const campaign_defaults = {
	mode: 'basic',
	mpid: '',
	name: '',
	description: '',
	version: 1,
	account_id: '',
	vendor_id_targeting: 'nextgenleads',
	vertical_id_targeting: '',
	product_targeting: '',
	location_targeting_mode: 'state', // TODO - Adjust this
	state_targeting: [],
	zip_targeting: [],
	channel_targeting: null,
	bid_type: '',
	custom_minimum_bid: false,
	minimum_bid: 0,
	default_bid: 0,
	enable_maximum_bid: false,
	maximum_bid: 0,
	bids: [],
	disable_second_pricing: false,
	budgets: [],
	filters: [],
	schedule: {
		enabled: false,
	},
	ping_post: {
		enabled: false,
		integration_id: null,
	},
	cancellations: {
		enabled: false,
		custom_threshold: {
			enabled: false,
			threshold: 0,
		},
		logic: 'pause_campaign',
		successive_cancel_limit: {
			enabled: false,
			limit: 0,
		},
	},
	shippers: [],
	forwarding_phone: {
		type: 'static',
		number: null,
		script: null,
		script_settings: null,
		concurrent_call_limit: {
			enabled: false,
			key: null,
			limit: 1,
		},
	},
	duration: 0,
	delivery_emails: [],
	ad: {
		enable_premium_listing: false,
		ad_id: null,
	},
	purchase_throttling: {
		enabled: false,
		limit: 0,
		interval: 0,
	},
	promotion_id: null,
	flags: {
		add_typ_weight: {
			enabled: false,
		},
		allow_duplicate_purchases: {
			enabled: false,
		},
		bypass_modifiers: {
			enabled: false,
			modifiers: [],
		},
		bypass_pending_logic: {
			enabled: false,
		},
		debug: {
			enabled: false,
		},
		disable_leg_buyouts: {
			enabled: false,
		},
		discount_bids: {
			enabled: false,
			multiplier: 0.85,
		},
		lock: {
			enabled: false,
			roles: {},
		},
		nullify_purchases: {
			enabled: false,
		},
		prioritize_campaign: {
			enabled: false,
			percentage: 0,
		},
		reduce_dominance: {
			enabled: false,
			percentage: 0,
		},
		smart_delay: {
			enabled: false,
		},
		bulk_returns: {
			enabled: false,
		},
		sorting_bid_multiplier: {
			enabled: false,
			amount: 0,
		},
	},
	tags: [],
	status: 'paused',
};

const deepMerge = (dest_value, src_value) => {
	if (isPlainObject(dest_value)) {
		return mergeWith({}, dest_value, src_value, deepMerge);
	}
	if (isNil(src_value)) {
		return dest_value;
	}
	return src_value;
};

export const useCampaignStore = defineStore('campaign', {
	state: () => {
		const appStore = useAppStore();
		const sessionStore = useSessionStore();
		const route = useRoute();
		const campaign_mpid = inject('nested_mpid', appStore.mpid);

		const campaign = cloneDeep(campaign_defaults);
		campaign.mpid = campaign_mpid;
		campaign.account_id = route.params.account_id || sessionStore.user?.account_id || '';

		return {
			allow_bulk_returns: false,
			touched: false,
			show_bid_info_modal: false,
			pending_integration: null,
			campaign,
			unchanged_campaign: {},
			channel: {
				name: 'None Selected',
				minimum_bid_adjustment_type: 'multiply',
				minimum_bid_adjustment_amount: 1,
				minimum_duration: 0,
				maximum_duration: 60,
			},
			promotion: {
				id: null,
				name: null,
				allow_source_filtering: true,
				allow_attribute_filtering: true,
				allow_schedule: true,
				exclusive_minimum_bid: 0,
				shared_minimum_bid: 0,
			},
			integration: null,
		};
	},
	getters: {
		isBasicMode(state) {
			return state.campaign.mode === 'basic';
		},
		isAdvancedMode(state) {
			return state.campaign.mode === 'advanced';
		},
		minimumBid(state) {
			const appStore = useAppStore();
			const vertical_settings = appStore.verticalSettings();
			let min_bid = 0;

			if (state.campaign.vertical_id_targeting && state.campaign.product_targeting && state.campaign.bid_type) {
				min_bid =
					vertical_settings[state.campaign.vertical_id_targeting][state.campaign.product_targeting][
						state.campaign.bid_type
					].minimum_bid;
			}

			if (state.channel.minimum_bid_adjustment_type && state.channel.minimum_bid_adjustment_amount) {
				switch (state.channel.minimum_bid_adjustment_type) {
					case 'multiply':
						min_bid = round(min_bid * state.channel.minimum_bid_adjustment_amount, 2);
						break;
					case 'add':
						min_bid = round(min_bid + state.channel.minimum_bid_adjustment_amount, 2);
						break;
					default:
						min_bid = state.channel.minimum_bid_adjustment_amount;
						break;
				}
			}

			if (state.promotion.id) {
				min_bid = get(state.promotion, `${state.campaign.bid_type}_minimum_bid`, min_bid);
			}

			if (state.campaign.custom_minimum_bid) {
				min_bid = state.campaign.minimum_bid;
			}

			return min_bid;
		},
		bidRange(state) {
			const default_bid = state.campaign.default_bid;
			const bids: Campaign['bids'] = state.campaign.bids.slice();

			// Check all custom bids for a lower minimum
			const min_bids = [];
			state.campaign.bids.forEach((bid) => {
				if (bid.status === 'active' && bid.custom_minimum_bid) {
					min_bids.push(bid.minimum_bid);
				}
			});

			const minimum_bid = min(min_bids.concat([this.minimumBid])) || this.minimumBid;

			// Remove all custom bid filters
			const filtered_bids = bids.filter((bid) => {
				return bid.amount > 0 && bid.status === 'active';
			});

			// Group bids by type
			const grouped_bids = Object.values(groupBy(filtered_bids, 'type'));

			// Figure out the max bid
			let max_bid = default_bid;
			grouped_bids.forEach((bid_group) => {
				const max_bids = [max_bid];
				let starting_max_bid = max_bid;
				bid_group.forEach((bid_group_bid) => {
					if (bid_group_bid.method === 'add') {
						max_bids.push(starting_max_bid + bid_group_bid.amount);
					} else if (bid_group_bid.method === 'multiply') {
						max_bids.push(starting_max_bid * bid_group_bid.amount);
					} else {
						max_bids.push(bid_group_bid.amount);
					}
				});

				max_bid = max(max_bids) || max_bid;
			});

			// Check to make sure the max bid does not exceed the set limit
			if (state.campaign.enable_maximum_bid && max_bid > state.campaign.maximum_bid) {
				max_bid = state.campaign.maximum_bid;
			}

			return [minimum_bid, max_bid];
		},
	},
	actions: {
		async fetch(id: string) {
			log.trace('Fetching Campaign', id);
			// Get campaign by ID and update state
			const campaign: CampaignForEditing = await getCampaignById(id);
			log.trace('Campaign Results', campaign);

			const without_bid_changes = cloneDeep(campaign);
			this.unchanged_campaign = mergeWith({}, cloneDeep(this.campaign), without_bid_changes, deepMerge);

			const account = await getAccountById(campaign.account_id);

			// Add IDs to each bid
			campaign.bids = campaign.bids.map((bid) => {
				if (bid.name === 'Explicit Source Targeting') {
					bid.id = 'source_targeting_bid';
				} else {
					bid.id = ulid();
				}
				return bid;
			});

			// Apply defaults
			const merged_campaign = mergeWith({}, cloneDeep(this.campaign), campaign, deepMerge);

			// Get the promotion info
			let promotion = this.promotion;
			if (campaign.promotion_id) {
				const promotion_result = await getPromotionById(campaign.promotion_id);
				if (promotion_result) {
					promotion = promotion_result;
				}
			}

			// Get the channel info
			let channel = this.channel;
			if (campaign.channel_targeting) {
				const channel_result = await getChannelById(campaign.channel_targeting);
				if (channel_result) {
					channel = channel_result;
				}
			}

			// Get pingpost details
			let integration = this.integration;
			try {
				const integration_result = await getIntegrationById(campaign.id);
				console.warn('INTEGRATION', integration_result);
				if (integration_result) {
					integration = integration_result;
				}
			} catch (err) {
				console.error(err, 'Getting Integration');
			}

			console.error('FETCHING');
			setTimeout(() => {
				this.$patch({
					campaign: merged_campaign,
					channel,
					promotion,
					integration,
					allow_bulk_returns: get(account, 'settings.bulk_returns.enabled', false),
				});
			}, 0);
		},
		async duplicate(id: string) {
			// Get campaign by ID and update state
			log.trace('Fetching Campaign for duplication', id);
			let campaign: CampaignForEditing = await getCampaignById(id);

			// Omit ID and created_at for new insert
			campaign = omit(campaign, ['id', 'created_at', 'modified_at']);
			campaign.name += ' (Copy)';

			// Add IDs to each bid
			campaign.bids = campaign.bids.map((bid) => {
				bid.id = ulid();
				return bid;
			});

			// Apply defaults
			const fetched_campaign = mergeWith({}, this.campaign, campaign, deepMerge);

			// Get the channel info
			let channel = this.$state.channel;
			if (campaign.channel_targeting) {
				const channel_result = await getChannelById(campaign.channel_targeting);
				if (channel_result) {
					channel = channel_result;
				}
			}

			this.$patch({
				campaign: fetched_campaign,
				channel,
			});
		},
		async updateCampaign(id: string, data: Partial<Campaign>) {
			return await updateCampaign(id, data);
		},
		async insertCampaign(data: Omit<Campaign, 'created_at' | 'id'>) {
			return await insertCampaign(data);
		},
		// Check if a bid has a unique name and type
		isUniqueCustomBid(name: string, type: CustomBid['type'], bid_id: string) {
			const index = findIndex(this.campaign.bids as CustomBidWithId[], (custom_bid) => {
				return custom_bid.name === name && custom_bid.type === type && custom_bid.id !== bid_id;
			});
			return index === -1;
		},
		addBid(bid: CustomBidWithId) {
			const new_bid = bid;

			if (!new_bid.id) {
				bid.id = ulid();
			}

			this.$patch((state) => {
				(state.campaign.bids as CustomBidWithId[]).push(new_bid);
			});
		},
		saveBid(bid: CustomBidWithId) {
			const bids: CustomBidWithId[] = this.campaign.bids.slice();
			const index = findIndex(bids, (custom_bid) => {
				return custom_bid.id === bid.id;
			});

			if (index > -1) {
				bids.splice(index, 1, bid);
			}

			this.$patch((state) => {
				state.campaign.bids = bids;
			});
		},
		deleteBid(bid_id: string): CustomBidWithId[] {
			const index = findIndex(this.campaign.bids as CustomBidWithId[], { id: bid_id });
			if (index > -1) {
				// splice
				return this.campaign.bids.splice(index, 1);
			} else {
				throw new Error('Bid of that name and type does not exist');
			}
		},
		async save() {
			const sessionStore = useSessionStore();

			if (sessionStore.isAdminUser || !this.$state.campaign.flags.lock.enabled) {
				// Clone the bid we want to save
				const new_campaign: CampaignForEditing = cloneDeep(this.$state.campaign);

				// Remove IDs and null values from the bids
				new_campaign.bids = new_campaign.bids.map((bid) => {
					bid.match = bid.match.map((condition) => {
						return deepClean(condition);
					});
					delete bid.id;
					return bid;
				});

				// Remove forwarding phone settings for non call/live-transfer campaigns
				if (new_campaign.product_targeting !== 'call' && new_campaign.product_targeting !== 'live_transfer') {
					new_campaign.forwarding_phone = null;
				}

				let result;

				if (this.$state.campaign.id) {
					if (this.$state.pending_integration) {
						this.$state.pending_integration.id = this.$state.campaign.id;
					}

					log.trace('Existing id');

					const diff_obj = diff(this.unchanged_campaign, new_campaign);
					const diffs = Object.keys(diff_obj);
					const changes = pick(new_campaign, diffs);

					result = await updateCampaign(this.$state.campaign.id, changes);
				} else {
					result = await insertCampaign(new_campaign);

					if (this.$state.pending_integration) {
						this.$state.pending_integration.id = result.id;
					}
				}

				// New Integration. presumes that integration id is campaign id
				if (this.$state.pending_integration) {
					try {
						const integration_id = (await insertIntegration(this.$state.pending_integration)).id;
						this.$state.integration = cloneDeep(this.$state.pending_integration);
					} catch (err) {}
				}

				return result;
			}
		},
	},
});
