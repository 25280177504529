<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>{{ is_create ? 'Create' : 'Edit' }} Notification</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="notification-form">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="message">Message:</label>
						<div class="controls">
							<div class="field">
								<Wysiwyg v-model="notification.message" />
								<div v-if="v$.notification.message.$error" class="validation-error">
									{{ v$.notification.message.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<NotificationFilterComponent v-model="notification.filters" />
								<div v-if="v$.notification.filters.$error" class="validation-error">
									<!--									{{ v$.notification.filters.$errors[0].$message }}-->
									{{
										v$.notification.filters.$errors
											.map((it) => {
												return it.$message;
											})
											.join(', ')
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field-row gap-20">
								<div class="field m">
									<label class="control-label req" for="type">Type:</label>
									<div class="faded"></div>
									<p-chips add-on-blur id="type" v-model="notification.type" separator="," />
									<div class="sub-value">Where is this notification displayed: drawer, push, etc.</div>
									<div v-if="v$.notification.type.$error" class="validation-error">
										{{ v$.notification.type.$errors[0].$message }}
									</div>
								</div>
								<div class="field m">
									<label class="control-label req" for="tags"> Tags:</label>
									<p-chips add-on-blur id="tags" v-model="notification.tags" separator="," />
									<div class="sub-value">Categorize the type of notification</div>
									<div v-if="v$.notification.tags.$error" class="validation-error">
										{{ v$.notification.tags.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="expires">Expires:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="expires" v-model.parse="expires_obj" />
								<div class="sub-value">
									Expiration Options
									<icon
										type="open-in-new"
										size="14px"
										class="cursor-pointer"
										style="color: var(--color-b)"
										@click.prevent="expires_modal = true"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="notification.status"
									:options="status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.notification.status.$error" class="validation-error">
									{{ v$.notification.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between mt-4">
					<p-button severity="secondary" text label="Cancel" @click="$router.back()" />
					<p-button v-if="is_create" label="Send Notification" @click="submit" />
					<p-button v-else label="Update Notification" @click="submit" />
				</div>
			</template>
		</p-card>
		<p-dialog
			v-model:visible="expires_modal"
			modal
			header="Expires Options"
			:style="{ width: '50vw' }"
			:dismissable-mask="true"
		>
			<p>Expires is an object of this type</p>
			<highlightjs autodetect :code="expires" />
		</p-dialog>
	</div>
</template>

<script lang="ts">
import Wysiwyg from '@/components/forms/Wysiwyg.vue';
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import pTextArea from 'primevue/textarea';
import { requiredIf, required, helpers, minLength } from '@vuelidate/validators';
import NotificationFilterComponent from './NotificationFilterComponent.vue';
import { useVuelidate } from '@vuelidate/core';
import { useSessionStore } from '@/stores/session';
import { insertNotification, updateNotification, getNotification } from '@GQL';
import { cloneDeep } from 'lodash-es';
import log from '@/lib/Log';

const status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Archived', value: 'archived' },
];

export default {
	name: 'CreateNotification',
	components: {
		pDialog,
		pChips,
		pTextArea,
		NotificationFilterComponent,
		Wysiwyg,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	async mounted() {
		this.breadcrumb_items.push({ label: this.is_create ? 'New Notification' : 'Edit Notification' });
		if (this.$route.params.notification_id) {
			const notification = await getNotification(this.$route.params.notification_id);
			this.notification = cloneDeep(notification);
		}
	},
	data() {
		return {
			status_options,
			breadcrumb_items: [
				{
					label: 'Notifications',
					route: '/notifications',
				},
			],
			expires_obj: {},
			expires_modal: false,
			notification: {
				message: '',
				filters: {
					target_type: '',
					value: null,
					mpid: null,
				},
				type: ['drawer'],
				tags: ['new'],
				status: 'active',
				created_by: this.sessionStore.user.id,
			},
			expires: `expires: z.object({
				type: z.enum(['date', 'offset']),
				value: z.date().or(z.string())
			}).nullish(),`,
		};
	},
	methods: {
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					const params =
						Object.keys(this.expires_obj).length > 0
							? { expires: this.expires_obj, ...cloneDeep(this.notification) }
							: { ...cloneDeep(this.notification) };
					if (this.$route.meta.new) {
						await insertNotification(params);
					} else {
						await updateNotification(this.$route.params.notification_id, params);
					}

					this.$toast.add({
						severity: 'success',
						summary: this.$route.meta.new ? 'Notification created' : 'Notification updated',
						life: 6000,
					});
					this.$router.back();
				} catch (err) {
					log.trace('Error submitting notification', err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to submit notification',
						detail: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	computed: {
		is_create() {
			return this.$route.meta.new === true;
		},
	},
	validations() {
		return {
			notification: {
				message: {
					required: helpers.withMessage('Enter the notification message', required),
				},
				filters: {
					target_type: {
						required: helpers.withMessage('Target type is required', required),
					},
					value: {
						requiredif: helpers.withMessage(
							'A value is required',
							requiredIf(['internal_users', 'external_users'].indexOf(this.notification.filters.target_type) === -1)
						),
					},
					mpid: {
						requiredif: helpers.withMessage(
							'A marketplace is required',
							requiredIf(this.notification.filters.target_type === 'users_by_marketplace_role')
						),
					},
				},
				type: {
					required: helpers.withMessage('Enter the notification type', required),
				},
				tags: {
					required: helpers.withMessage('Enter tags for this notification', required),
				},
				status: {
					required: helpers.withMessage('Select a status for this notification', required),
				},
			},
		};
	},
};
</script>

<style lang="less" scoped>
.notification-form {
	max-width: 800px;
}
</style>
