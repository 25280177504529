import { $GQL } from '@GQL/index';

export interface LoginParams {
	email: string;
	password: string;
	mpid: string;
}

export interface LoginRes {
	token: string;
	mpid?: string;
}

export async function login(params: LoginParams): Promise<LoginRes> {
	const req = `
        mutation Login($email: String!, $password: String!, $mpid: String!) {
            login(email: $email, password: $password, mpid: $mpid) {
                token
				mpid
            }
        }
    `;

	const result = await $GQL.request(req, params);
	return result.login;
}
