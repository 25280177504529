import { $GQL } from '@GQL/index';
import type { CaptureConfig } from '@nextgenleads/lead-driver';

export async function getCaptureConfigById(id: string): Promise<Omit<CaptureConfig, 'created_at' | 'modified_at'>> {
	const query = `
        query GetCaptureConfigById($id: ID!) {
            captureConfig(id: $id) {
                id
				mpid
                name
                description
                vendor_id
                vertical_id
                product
                method
                source_ids
                build_steps
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.captureConfig;
}
