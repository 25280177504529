<template>
	<textarea :id="textarea_id"></textarea>
</template>

<script>
import '@/lib/RedactorX/redactorx.min.js';
import { merge } from 'lodash-es';
import { ulid } from 'ulid';

export default {
	name: 'WysiwygInput',
	emits: ['update:modelValue'],
	props: {
		options: {
			type: Object,
			default() {
				return {};
			},
		},
		modelValue: {
			type: String,
		},
	},
	data() {
		return {
			redactor: null,
			textarea_id: ulid(),
		};
	},
	computed: {
		mergedOptions() {
			return merge(
				{
					content: this.modelValue,
					subscribe: {
						'editor.change': (event) => {
							this.$emit('update:modelValue', event.params.html);
						},
					},
				},
				this.options
			);
		},
	},
	watch: {
		modelValue(new_value, old_value) {
			if (new_value !== old_value) {
				this.redactor.editor.setContent({
					html: new_value,
				});
			}
		},
	},
	mounted() {
		this.redactor = RedactorX(`#${this.textarea_id}`, this.mergedOptions);
	},
};
</script>

<style lang="less">
.rx-container {
	border: 1px solid var(--gray-25);
	border-radius: 3px;
}

.rx-toolbar-container {
	border-radius: 3px 3px 0 0;
}

.rx-editor-container,
.rx-source-container {
	border-radius: 0 0 3px 3px;
	min-height: 20em;

	.rx-editor,
	.rx-source {
		border-radius: 0 0 3px 3px;
		min-height: 20em;
	}
}
</style>
