export const status_options = [
	{ label: 'Enabled', value: 'enabled' },
	{ label: 'Disabled', value: 'disabled' },
	{ label: 'Archived', value: 'archived' },
];

export const ccpa_request_type_options = [
	{ label: 'Removal', value: 'removal' },
	{ label: 'Inquiry', value: 'inquiry' },
	{ label: 'Look Up', value: 'lookup' },
];
export const ccpa_status_options = [
	{ label: 'Pending', value: 'pending' },
	{ label: 'Reviewed', value: 'reviewed' },
	{ label: 'Processed', value: 'processed' },
	{ label: 'Rejected', value: 'rejected' },
];

export const channel_status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Disabled', value: 'disabled' },
	{ label: 'Hidden', value: 'hidden' },
	{ label: 'Archived', value: 'archived' },
];

export const contact_type_options = [
	{ label: 'Account Manager', value: 'account_manager' },
	{ label: 'Technical Support', value: 'technical_support' },
	{ label: 'Billing', value: 'billing' },
	{ label: 'Other', value: 'other' },
];

export const account_status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Suspended', value: 'suspended' },
	{ label: 'Archived', value: 'archived' },
	{ label: 'Away', value: 'away' },
];

export const active_status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Disabled', value: 'disabled' },
	{ label: 'Archived', value: 'archived' },
];

export const source_status_options = [
	{ label: 'Archived', value: 'archived' },
	{ label: 'Active', value: 'active' },
	{ label: 'Deprecated', value: 'deprecated' },
	{ label: 'Paused', value: 'paused' },
	{ label: 'Experimental', value: 'experimental' },
	{ label: 'New', value: 'new' },
];

export const sub_id_map_options = [
	{ label: 'Ad Group', value: 'ad_group' },
	{ label: 'Campaign ID', value: 'campaign_id' },
	{ label: 'Campaign Name', value: 'campaign_name' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Keywords', value: 'keywords' },
	{ label: 'Medium', value: 'medium' },
	{ label: 'Publisher', value: 'publisher' },
	{ label: 'URL', value: 'url' },
	{ label: 'Sub ID', value: 'sub_id' },
	{ label: 'S1', value: 's1' },
	{ label: 'S2', value: 's2' },
	{ label: 'S3', value: 's3' },
	{ label: 'S4', value: 's4' },
];

export const biz_unit_options = [
	{ label: 'Owned & Operated', value: 'OO' },
	{ label: 'Partner Network', value: 'PN' },
	{ label: 'Calls Partner', value: 'CP' },
];

export const product_type_options = [
	{ label: 'Data Leads', value: 'data' },
	{ label: 'Calls', value: 'call' },
	{ label: 'Live Transfers', value: 'live_transfer' },
	// { label: 'Ad', value: 'ad' },
];

export const source_type_options = [
	{ label: 'Web', value: 'web' },
	{ label: 'Ping Post', value: 'pingpost' },
	{ label: 'Straight Post', value: 'straightpost' },
	{ label: 'API', value: 'api' },
];

export const dnc_type_options = [
	{ label: 'Account', value: 'account' }, // may not be an option
	{ label: 'Hard', value: 'hard' },
	{ label: 'Soft', value: 'soft' },
];

export const purchase_status_options = [
	{ label: 'Complete', value: 'complete' },
	{ label: 'Canceled', value: 'canceled' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Error', value: 'error' },
	{ label: 'Failed', value: 'failed' },
	{ label: 'Return Pending', value: 'return_pending' },
	{ label: 'Return Accepted', value: 'return_accepted' },
	{ label: 'Return Rejected', value: 'return_rejected' },
];

export const http_request_options = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'DELETE', value: 'DELETE' },
];

export const support_status_options = [
	{ label: 'New', value: 'new' },
	{ label: 'Threshold Pending', value: 'threshold_pending' },
	{ label: 'Threshold Met', value: 'threshold_met' },
	{ label: 'Tier 1', value: 'tier_1' },
	{ label: 'Tier 2', value: 'tier_2' },
	{ label: 'Reactivation', value: 'reactivation' },
];

export const disposition_options = [
	{ label: 'Emailed', value: 'Emailed' },
	{ label: 'Texted', value: 'Texted' },
	{ label: 'Voice-To-Voice', value: 'Voice-To-Voice' },
	{ label: 'Working', value: 'Working', type: 'new' },
	{ label: 'Left Voicemail', value: 'Left Voicemail' },
	{ label: 'Not Interested', value: 'Not Interested' },
	{ label: 'Disconnected Phone', value: 'Disconnected Phone' },
	{ label: 'Demote to Support', value: 'Demote to Support' },
	{ label: 'No Answer', value: 'No Answer' },
	{ label: 'Snoozing Alerts', value: 'Snoozing Alerts', type: 'existing' },
	{ label: 'OE/AEP', value: 'OE/AEP', type: 'existing' },
	{ label: 'Delete', value: 'Delete' },
	{ label: 'Reactivation Reply', value: 'Reactivation Reply', type: 'reactivation' },
	{ label: 'Unsubscribe', value: 'Unsubscribe', type: 'reactivation' },
	{ label: 'Working', value: 'Working', type: 'reactivation' },
	{ label: 'Finish Sign-Up', value: 'Finish Sign-Up', type: 'reactivation' },
];

export const transaction_type_options = [
	{
		label: 'Debit',
		value: 'debit',
	},
	{
		label: 'Refund',
		value: 'refund',
	},
	{
		label: 'Credit',
		value: 'credit',
	},
	{
		label: 'Deposit',
		value: 'deposit',
	},
	{
		label: 'Pending',
		value: 'pending',
	},
];

// TODO - change this to read from DB....
export const vertical_options = [
	{
		label: 'Medicare',
		value: 'medicare',
	},
	{
		label: 'Health Insurance',
		value: 'health_insurance',
	},
];
