<template>
	<form>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Shipper Template Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="local_shipper_template.name" />
						<div v-if="v$.local_shipper_template.name.$error" class="validation-error">A template name is required</div>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Shipper Template Label:</label>
				<p class="sub-value">This is what will be visible in the drop downs for when a new shipper is created</p>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="local_shipper_template.label" />
						<div v-if="v$.local_shipper_template.name.$error" class="validation-error">
							A template label is required
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Shipper Type:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="local_shipper_template.type"
							:options="shipper_type_options"
							option-label="label"
							option-value="value"
							placeholder="Select Shipper Type"
						/>
						<div v-if="v$.local_shipper_template.type.$error" class="validation-error">A template type is required</div>
					</div>
				</div>
			</div>
		</div>

		<p-fieldset legend="Settings">
			<p-message v-if="!local_shipper_template.type" class="temp-message" :closable="false">
				Please select a shipper type
			</p-message>
			<component
				:is="shipperSettingsComponent[local_shipper_template.type]"
				v-model="local_shipper_template.settings"
			/>
		</p-fieldset>

		<gutter size="1rem" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label">Data Map:</label>
				<div class="sub-value mb-2">Map to the data to ship here. See <VariablesHelpersModal /></div>
				<div class="controls">
					<div class="field">
						<code-editor v-model="local_shipper_template.data_map" />
					</div>
				</div>
			</div>
		</div>

		<div class="cancellations padded">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch v-model="local_shipper_template.cancellations.enabled" />
						</div>
						<gutter size="10px" />
						<div class="field caption">
							<span class="label">Allow this shipper to cancel purchases</span>
						</div>
					</div>
				</div>
			</div>
			<template v-if="local_shipper_template.cancellations.enabled">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Cancel Response Pattern:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="local_shipper_template.cancellations.pattern" />
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>

		<gutter size="1rem" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="local_shipper_template.status"
							:options="status_options"
							option-label="label"
							option-value="value"
							placeholder="Set the Shipper Status"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="form-actions">
			<p-button label="Save Template" @click="saveShipperTemplate">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="check" size="24px" />
					</div>
				</template>
			</p-button>
			<gutter size="10px" />
			<p-button label="Cancel" text @click="$router.back()" />
		</div>
	</form>
</template>

<script>
import isEqual from 'lodash/isEqual';
import customSettingsComponent from '@/views/Shippers/Record/SettingsComponents/custom.vue';
import emailSettingsComponent from '@/views/Shippers/Record/SettingsComponents/email.vue';
import httpSettingsComponent from '@/views/Shippers/Record/SettingsComponents/http.vue';
import pFieldset from 'primevue/fieldset';
import pMessage from 'primevue/message';
import VariablesHelpersModal from '@/views/Shippers/VariablesHelpersModal.vue';
import { insertShipperTemplate, updateShipperTemplate } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
	name: 'ShipperTemplateForm',
	components: {
		pFieldset,
		pMessage,
		VariablesHelpersModal,
	},
	props: {
		template: {
			type: Object,
			default() {
				return {
					id: null,
				};
			},
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			local_shipper_template: {
				cancellations: {
					enabled: false,
					pattern: '',
				},
				settings: {},
				status: 'active',
			},
			shipper_type_options: [
				{ label: 'HTTP', value: 'http' },
				// { label: 'Email', value: 'email' },
				{ label: 'Custom', value: 'custom' },
			],
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Paused', value: 'paused' },
				{ label: 'Archived', value: 'archived' },
			],
			shipper_variables_modal: false,
		};
	},
	computed: {
		shipperSettingsComponent() {
			return {
				custom: customSettingsComponent,
				email: emailSettingsComponent,
				http: httpSettingsComponent,
			};
		},
	},
	watch: {
		template(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				this.local_shipper_template = Object.assign(this.local_shipper_template, new_value);
			}
		},
	},
	methods: {
		async saveShipperTemplate() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) {
				setTimeout(() => {
					document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
				}, 100);
			} else {
				try {
					let save_result;
					if (this.local_shipper_template.id) {
						save_result = await updateShipperTemplate(this.local_shipper_template.id, this.local_shipper_template);
					} else {
						save_result = await insertShipperTemplate(this.local_shipper_template);
					}

					this.$toast.add({
						severity: 'success',
						summary: 'The shipper template was saved successfully',
						life: 5000,
					});
					this.$router.back();
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the shipper template',
						message: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	validations() {
		return {
			local_shipper_template: {
				name: { required },
				type: { required },
				label: { required },
			},
		};
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

.cancellations {
	border: 1px solid var(--gray-10);
	border-radius: 3px;
}

code {
	font-size: var(--font-size-sm);
	color: var(--teal-600);
}

.columned {
	.laptop({
		columns: 2;
	});
	.desktop-up({
		columns: 3;
	});
}

.temp-message {
	margin: 0;
}
</style>
