import MetabaseReporting from '@/views/Reporting.vue';
import VelocityReport from '@/views/Reports/Velocity/Page.vue';
import BuyerVelocityReport from '@/views/Reports/BuyerVelocity/Page.vue';
import AdminDashboard from '@/views/Reports/AdminDashboard/Page.vue';
import LeadsReport from '@/views/Reports/Leads/Page.vue';
import DataLeadsReport from '@/views/Reports/Leads/Data/Page.vue';
import LiveTransferLeadsReport from '@/views/Reports/Leads/LiveTransfers/Page.vue';
import CallLeadsReport from '@/views/Reports/Leads/Calls/Page.vue';
import AccountManagerWorkstationWrapper from '@/views/AccountManagerWorkstation/AccountManagerWorkstationWrapper.vue';
import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';
import PendingFollowUpPage from '@/views/AccountManagerWorkstation/PendingFollowUpsPage.vue';
import ExistingAccountsPage from '@/views/AccountManagerWorkstation/ExistingAccountsPage.vue';
import RecentSignUpsPage from '@/views/AccountManagerWorkstation/RecentSignUpsPage.vue';
import AMWAdminOverviewPage from '@/views/AccountManagerWorkstation/AdminOverviewPage.vue';
import { authenticateReporting } from '@GQL';

export default [
	{
		path: '',
		name: 'Home',
		redirect: () => {
			return {
				path: '/reports/velocity',
			};
		},
	},
	{
		path: 'reports/auth',
		name: 'ReportingAuth',
		beforeEnter: (to, from) => {
			authenticateReporting().then((auth_res) => {
				window.location.href = `${auth_res.url}/auth/sso?token=${auth_res.token}&return_to=${to.query.return_to}`;
			});
		},
	},
	{
		path: 'reports/metabase',
		name: 'MetabaseReporting',
		component: MetabaseReporting,
		meta: {
			title: 'Reporting',
			permissions: 'reporting',
		},
	},
	{
		path: 'reports/admin-dashboard',
		name: 'Admin Dashboard',
		component: AdminDashboard,
		meta: {
			title: 'Admin Dashboard',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/velocity',
		name: 'VelocityReport',
		component: VelocityReport,
		meta: {
			title: 'Velocity',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/buyer-velocity',
		name: 'BuyerVelocityReport',
		component: BuyerVelocityReport,
		meta: {
			title: 'Buyer Velocity',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/leads',
		name: 'LeadsReport',
		component: LeadsReport,
		meta: {
			title: 'Leads Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/data-leads',
		name: 'DataLeadsReport',
		component: DataLeadsReport,
		meta: {
			title: 'Data Leads Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/call-leads',
		name: 'CallLeadsReport',
		component: CallLeadsReport,
		meta: {
			title: 'Calls Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/live-transfer-leads',
		name: 'LiveTransferLeadsReport',
		component: LiveTransferLeadsReport,
		meta: {
			title: 'Live Transfers Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'amw',
		name: 'AccountManagerWorkstationOwn',
		component: AccountManagerWorkstationWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					redirect: () => {
						return {
							path: '/amw/pending-follow-ups',
						};
					},
				},
				{
					path: 'pending-follow-ups',
					name: 'PendingFollowUps',
					component: PendingFollowUpPage,
					meta: {
						title: 'Pending Follow-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'existing-accounts',
					name: 'ExistingAccountsPage',
					component: ExistingAccountsPage,
					meta: {
						title: 'Existing Accounts',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'recent-signups',
					name: 'RecentSignUpsPage',
					component: RecentSignUpsPage,
					meta: {
						title: 'Recent Sign-Ups',
						permissions: 'admin_am_reports',
					},
				},
			],
			'AccountManagerWorkstation:',
			{}
		),
	},
	{
		path: 'amw-manager/:account_manager_id',
		name: 'AccountManagerWorkstation',
		component: AccountManagerWorkstationWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					redirect: () => {
						return {
							path: '/amw/pending-follow-ups',
						};
					},
				},
				{
					path: 'pending-follow-ups',
					name: 'PendingFollowUps',
					component: PendingFollowUpPage,
					meta: {
						title: 'Pending Follow-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'existing-accounts',
					name: 'ExistingAccountsPage',
					component: ExistingAccountsPage,
					meta: {
						title: 'Existing Accounts',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'recent-signups',
					name: 'RecentSignUpsPage',
					component: RecentSignUpsPage,
					meta: {
						title: 'Recent Sign-Ups',
						permissions: 'admin_am_reports',
					},
				},
			],
			'AccountManagerWorkstationManager:',
			{}
		),
	},
	{
		path: 'reports/amw-overview',
		name: 'AMWAdminOverviewReport',
		component: AMWAdminOverviewPage,
		meta: {
			title: 'AMW Overview Report',
			permissions: 'admin_am_reports',
		},
	},
];
