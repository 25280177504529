<template>
	<div id="uploads" class="view-content">
		<div class="page-heading">
			<h1>File Uploads</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<gutter size="20px" />
		<row>
			<column>
				<custom-upload choose-label="Upload Files" folder="uploads" :file-limit="999" @success="getFiles" multiple />
			</column>
		</row>
		<gutter size="20px" />
		<div class="card padded">
			<div id="uploads-table">
				<p-data-table ref="dt" :rows="10" scrollable :value="files" :loading="loading">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="key" header="File Name">
						<template #body="file">
							<div>
								<a target="_blank" :href="file.data.url">{{ file.data.name }}</a>
							</div>
						</template>
					</p-column>
					<p-column field="size" header="Size">
						<template #body="file">
							<div>{{ round(file.data.size / 1000, 2) }}KB</div>
						</template>
					</p-column>
					<p-column field="last_modified" header="Last Modified">
						<template #body="file">
							<div>
								{{ formatDate(new Date(file.data.last_modified), 'LT ddd, MMM D, YYYY') }}
							</div>
						</template>
					</p-column>
					<p-column header="Tools" class="column-align-center">
						<template #body="file">
							<div class="toolset">
								<div class="tool">
									<p-button v-tooltip.top="'Copy URL'" aria-label="Copy URL" @click="copyUrl(file.data.url)">
										<template #icon>
											<icon type="clipboard-plus-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<delete-action
									message="Are you sure you want to delete this file?"
									@delete="deleteFile(file.data.key)"
								/>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import copyToClipboard from 'copy-to-clipboard';
import { round } from 'lodash-es';
import { deleteFiles, getFiles } from '@GQL';
import { formatDate } from '@/lib/Filters';
import customUpload from '@/components/forms/CustomUpload.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'Uploads',
	components: {
		customUpload,
		deleteAction,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Uploads' }],
			folders: [],
			files: [],
			loading: false,
		};
	},
	async mounted() {
		await this.getFiles();
	},
	computed: {
		folderName() {
			let folder_prefix = '';
			if (!this.$root.sessionStore.isAdminUser) {
				folder_prefix += this.$root.appStore.mpid + '/';
			} else if (this.$route.params.mpid) {
				folder_prefix += this.$route.params.mpid + '/';
			}

			if (!this.$root.sessionStore.isAccountUser && this.$route.params.account_id) {
				folder_prefix += this.$route.params.account_id + '/';
			} else if (!this.$root.sessionStore.isAccountUser && this.$route.params.parent_account_id) {
				folder_prefix += this.$route.params.parent_account_id + '/';
			} else if (this.$root.sessionStore.account.id) {
				// Default to the user's account folder
				folder_prefix += this.$root.sessionStore.account.id + '/';
			}

			return folder_prefix;
		},
	},
	methods: {
		round,
		formatDate,
		getFileName(path) {
			return path.replace(/^.*[\\/]/, '');
		},
		async getFiles() {
			const folder = `${this.folderName}/uploads`.replace(/\/\//, '/');
			const results = await getFiles(folder);
			this.folders = results.folders;
			this.files = results.files;
		},
		async deleteFile(key) {
			const keys = [key];
			const results = await deleteFiles(keys);
			if (results.length > 0) {
				this.$toast.add({
					severity: 'success',
					summary: 'File Deleted',
					detail: results[0].name,
					life: 3000,
				});
				await this.getFiles();
			}
		},
		copyUrl(key) {
			copyToClipboard(key);
			this.$toast.add({
				severity: 'success',
				summary: 'Copied to Clipboard',
				detail: key,
				life: 3000,
			});
		},
	},
};
</script>
