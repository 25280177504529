<template>
	<div id="account-wrapper">
		<row>
			<div class="page-heading">
				<h1>{{ account_name }}</h1>
				<div v-if="$root.sessionStore.isAdminUser && parent_account_id" class="sub-value mb-2">
					Sub-account of
					<router-link
						:to="`/marketplaces/manage/${this.$route.params.mpid}/parent-accounts/${parent_account_id}/reports/parent-account-overview`"
						>{{ parent_account_id }}</router-link
					>
				</div>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active_tab" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)" class="account-tab">
							<router-link :to="`${routePrefix}/${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
					<template v-else>
						<div
							v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)"
							class="account-tab"
							@click.stop
						>
							<div class="tab-submenu-wrapper" @click="toggleSubMenu($event, item.ref)">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
								<div class="tab-submenu-toggle">
									<alt-icon type="expand_more" />
								</div>
							</div>
							<p-menu :ref="item.ref" class="account-submenu" :model="item.items" :popup="true">
								<template #item="{ item }">
									<template v-if="!item.permissions || $root.sessionStore.hasPermission(item.permissions)">
										<router-link :to="`${routePrefix}/${item.route}`">
											<div class="tab-label">
												{{ item.label }}
											</div>
										</router-link>
									</template>
								</template>
							</p-menu>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="account-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script lang="ts">
import pTabMenu from 'primevue/tabmenu';
import pMenu from 'primevue/menu';
import { useAccountStore } from '@/stores/account';
import { mapStores } from 'pinia';

export default {
	name: 'AccountWrapper',
	components: {
		pTabMenu,
		pMenu,
	},
	data() {
		return {
			active_tab: 0,
			account_name: 'Account Name',
			parent_account_id: null,
			breadcrumb_items: [],
		};
	},
	computed: {
		tabs() {
			if (this.$root.appStore.isAdminApp) {
				return [
					{
						label: 'Overview',
						icon: 'insert_chart',
						ref: 'overview',
						items: [
							{
								label: 'Account Activity',
								route: 'reports/account-activity',
							},
							{
								label: 'Account Performance',
								route: 'reports/account-performance',
							},
						],
					},
					{
						label: 'Purchases',
						icon: 'demography',
						ref: 'leads',
						items: [
							{
								label: 'All Purchases',
								route: 'purchases',
							},
							{
								label: 'Data Lead Purchases',
								route: 'purchases/data-leads',
							},
							{
								label: 'Call Purchases',
								route: 'purchases/call-leads',
							},
							{
								label: 'Live Transfer Purchases',
								route: 'purchases/live-transfers',
							},
							// {
							// 	label: 'Conversions by Source',
							// 	route: 'reports/conversions-source',
							// 	permissions: 'AccountTabs.conversions',
							// },
						],
					},
					{
						label: 'Campaigns',
						icon: 'flag_circle',
						ref: 'campaigns',
						items: [
							{
								label: 'Campaigns',
								route: 'campaigns',
							},
							{
								label: 'Premium Listings',
								route: 'ads',
							},
						],
					},
					{
						label: 'Shippers',
						icon: 'send',
						ref: 'shippers',
						items: [
							{
								label: 'Shippers',
								route: 'shippers',
								permissions: 'AccountTabs.shippers',
							},
							{
								label: 'Shipment Log',
								route: 'shipment-logs',
							},
						],
					},
					{
						label: 'Billing',
						icon: 'credit_card',
						ref: 'billing',
						permissions: 'AccountTabs.billing',
						items: [
							{
								label: 'Payment Methods',
								route: 'payment-methods',
							},
							{
								label: 'Transactions',
								route: 'transactions',
							},
							{
								label: 'Billing Settings',
								route: 'billing-settings',
							},
						],
					},
					{
						label: 'Users',
						icon: 'account_circle',
						route: 'users',
					},
					{
						label: 'Files',
						icon: 'folder_open',
						route: 'files',
						permissions: 'AccountTabs.files',
					},
					{
						label: 'Settings',
						icon: 'tune',
						route: 'settings/account',
					},
				];
			} else {
				////////// SUPER JANKYYYYYYYYYYY
				const billing_routes =
					this.$root.sessionStore.user.account_id && this.$root.sessionStore.user.account_id.indexOf('000-000') > -1
						? [
								{
									label: 'Payment Methods',
									route: 'payment-methods',
								},
								{
									label: 'Transactions',
									route: 'transactions',
								},
								{
									label: 'Billing Settings',
									route: 'billing-settings',
								},
							]
						: [
								{
									label: 'Transactions',
									route: 'transactions',
								},
							];
				return [
					{
						label: 'Overview',
						icon: 'insert_chart',
						ref: 'overview',
						items: [
							{
								label: 'Overview',
								route: 'reports/account-activity',
							},
							{
								label: 'Account Performance',
								route: 'reports/account-performance',
							},
						],
					},
					{
						label: 'Purchases',
						icon: 'demography',
						ref: 'leads',
						items: [
							{
								label: 'All Purchases',
								route: 'purchases',
							},
							{
								label: 'Data Lead Purchases',
								route: 'purchases/data-leads',
							},
							{
								label: 'Call Purchases',
								route: 'purchases/call-leads',
							},
							{
								label: 'Live Transfer Purchases',
								route: 'purchases/live-transfers',
							},
							// {
							// 	label: 'Conversions by Source',
							// 	route: 'reports/conversions-source',
							// 	permissions: 'AccountTabs.conversions',
							// },
						],
					},
					{
						label: 'Campaigns',
						icon: 'flag_circle',
						ref: 'campaigns',
						items: [
							{
								label: 'Campaigns',
								route: 'campaigns',
							},
							{
								label: 'Premium Listings',
								route: 'ads',
							},
						],
					},
					{
						label: 'Shippers',
						icon: 'send',
						ref: 'shippers',
						items: [
							{
								label: 'Shippers',
								route: 'shippers',
								permissions: 'AccountTabs.shippers',
							},
							{
								label: 'Shipment Log',
								route: 'shipment-logs',
							},
						],
					},
					{
						label: 'Billing',
						icon: 'credit_card',
						ref: 'billing',
						permissions: 'AccountTabs.billing',
						items: billing_routes,
					},
					{
						label: 'Users',
						icon: 'account_circle',
						route: 'users',
					},
					{
						label: 'Files',
						icon: 'folder_open',
						route: 'files',
						permissions: 'AccountTabs.files',
					},
					{
						label: 'Settings',
						icon: 'tune',
						route: 'settings/account',
					},
				];
			}
		},
		mpid() {
			return this.$route.params.mpid || this.accountStore.mpid || this.$root.appStore.mpid;
		},
		isParentAccountAdmin() {
			return this.$root.sessionStore.user.role_id[this.mpid] === 'parent_account_admin';
		},
		breadcrumb_prefix() {
			const split = this.$route.path.split('/');
			const regexp = /[A-Z]{3}/g;
			const mpidIdx = split.findIndex((e) => regexp.test(e));
			if (split.includes('marketplaces')) {
				split.splice(mpidIdx + 1);
				return `${split.join('/')}/accounts`;
			} else {
				return this.isParentAccountAdmin ? '/child-accounts' : '/accounts';
			}
		},
		routePrefix() {
			const split = this.$route.path.split('/');
			const regexp = /[A-Z]{3}-[0-9]{3}-[0-9]{3}/g;
			while (!regexp.test(split[split.length - 1])) {
				split.pop();
			}
			return split.join('/');
		},
		...mapStores(useAccountStore),
	},
	watch: {
		$route: {
			handler(new_value) {
				this.active_tab = this.tabs.findIndex((tab) => {
					if (tab.items) {
						return tab.items.some((sub_item) => {
							return new_value.path.indexOf(sub_item.route) > 0;
						});
					}
					return new_value.path.indexOf(tab.route) > 0;
				});
			},
			immediate: true,
		},
	},
	async mounted() {
		await this.loadAccount();
	},
	async updated() {
		await this.loadAccount();
	},
	async beforeUnmount() {
		this.accountStore.$reset();
	},
	methods: {
		async loadAccount() {
			// get the account info for name etc
			if ('account_id' in this.$route.params) {
				await this.accountStore.getAccount(this.$route.params.account_id);
				this.account_name = this.accountStore.account.name;
				this.parent_account_id = this.accountStore.account.parent_account_id;

				if (this.$route.params.mpid) {
					this.breadcrumb_items = [
						{
							label: 'Marketplaces',
							route: '/marketplaces',
						},
						{
							label: this.$route.params.mpid,
							route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
						},
						{
							route: this.breadcrumb_prefix,
							label: 'Accounts',
						},
						{
							label: this.accountStore.account.name,
						},
					];
				} else {
					this.breadcrumb_items = [
						{
							route: this.breadcrumb_prefix,
							label: 'Accounts',
						},
						{
							label: this.accountStore.account.name,
						},
					];
				}
			}
		},
		toggleSubMenu(event, sub_menu_ref) {
			this.$refs[sub_menu_ref].toggle(event);
		},
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#account-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.account-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 30%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 40px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.account-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}
</style>

<style lang="less">
.account-submenu {
	width: 240px;

	a {
		align-items: center;
		color: var(--gray-50);
		display: flex;
		height: 40px;
		line-height: 40px;
		padding: 0 1.5em;

		&:hover {
			text-decoration: none;
		}
	}
}
</style>
