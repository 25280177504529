<template>
	<div id="lead" class="view-content">
		<div class="title-section">
			<div class="page-heading">
				<h1>Lead Details</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
			<Toolbar
				v-if="!loading && loaded"
				:lead="lead"
				:purchase="purchase"
				:conversion="conversion"
				@updatePurchase="purchase.status = 'return_pending'"
			/>
		</div>
		<row class="lead-info-container">
			<div class="spinner-container w-full text-center">
				<p-progress-spinner v-if="loading" style="display: block; margin: 0 auto" />
			</div>
			<p-message v-if="!loading && loaded && !lead" severity="info" :closable="false"
				>Could not find details for {{ $route.params.lead_id }}</p-message
			>
			<column>
				<p-message
					v-if="purchase && purchase.status === 'return_pending'"
					severity="warn"
					:pt="{
						root: 'mt-0 mb-4',
					}"
					>This purchase is pending a return</p-message
				>
				<p-message
					v-if="purchase && purchase.status === 'return_accepted'"
					severity="info"
					:pt="{
						root: 'mt-0 mb-4',
					}"
					>This lead has been returned</p-message
				>
				<p-message
					v-if="purchase && purchase.status === 'return_rejected'"
					severity="error"
					:pt="{
						root: 'mt-0 mb-4',
					}"
					>The return for this lead has been rejected</p-message
				>
				<Overview v-if="!loading && loaded" :lead="lead" :revenue="revenue" />
				<gutter size="2rem" />
				<LeadDetails v-if="!loading && loaded" :lead="lead" />
				<gutter size="2rem" v-if="!loading && loaded && sessionStore.isAdminUser" />
				<MetaDetails v-if="!loading && loaded" :lead="lead" />
			</column>
			<gutter size="20px" />
			<column>
				<SourceDetails v-if="!loading && loaded" :lead="lead" />
				<gutter size="2rem" />
				<PurchaseHistoryPanel v-if="loaded && sessionStore.isAdminUser" :lead-id="$route.params.lead_id" />
				<gutter size="2rem" v-if="!loading && loaded && sessionStore.isAdminUser" />
				<AuctionPanel v-if="!loading && loaded && sessionStore.isAdminUser" :lead-id="$route.params.lead_id" />
				<gutter size="2rem" v-if="!loading && loaded && sessionStore.isAdminUser" />
				<ShippingPanel v-if="!loading && loaded && sessionStore.isAdminUser" :lead-id="$route.params.lead_id" />
			</column>
		</row>
	</div>
</template>

<script lang="ts">
import Overview from './panels/Overview.vue';
import Toolbar from './LeadToolbar.vue';
import LeadDetails from './panels/Details.vue';
import SourceDetails from './panels/Source.vue';
import MetaDetails from './panels/Meta.vue';
import pMessage from 'primevue/message';
import pProgressSpinner from 'primevue/progressspinner';
import { formatDate, currency, phoneFormat } from '@/lib/Filters';
import type { Conversion } from '@nextgenleads/marketplace-driver';
import { getLeadDetail, getConversion, getPurchaseHistory } from '@GQL';
import { cloneDeep, sumBy } from 'lodash-es';
import log from '@/lib/Log';
import PurchaseHistoryPanel from '@/views/Leads/panels/PurchaseHistory.vue';
import AuctionPanel from '@/views/Leads/panels/Auction.vue';
import ShippingPanel from './panels/Shipping.vue';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'DataLeadsReport',
	components: {
		AuctionPanel,
		PurchaseHistoryPanel,
		Toolbar,
		Overview,
		SourceDetails,
		LeadDetails,
		MetaDetails,
		ShippingPanel,
		pProgressSpinner,
		pMessage,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			breadcrumb_items: [],
			loading: false,
			loaded: false,
			lead: null,
			purchase: null,
			purchases: [],
			conversion: null,
		};
	},
	async mounted() {
		await this.getLeadData();
		this.$route.path.split('/').includes('leads')
			? this.breadcrumb_items.push({ label: 'Leads', route: '/reports/leads' }, { label: this.$route.params.lead_id })
			: this.breadcrumb_items.push({ label: 'Purchases', route: '/purchases' }, { label: this.$route.params.lead_id });

		if (this.$route.path.split('/').includes('purchases')) {
			await this.getPurchaseInfo();
		}
	},
	computed: {
		account_id() {
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return this.sessionStore.account.id;
			}
		},
		revenue() {
			return sumBy(this.purchases, 'price');
		},
	},
	methods: {
		formatDate,
		currency,
		phoneFormat,
		async getLeadData() {
			this.loading = true;
			try {
				const result = await getLeadDetail(this.$route.params.lead_id);
				this.lead = cloneDeep(result.lead);
				this.purchases = result.purchases;
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get lead',
					detail: err.message,
					life: 6000,
				});
			} finally {
				this.loading = false;
				this.loaded = true;
			}
		},
		async getPurchaseInfo() {
			const filters = [`lead_id = '${this.$route.params.lead_id}'`, `account_id = '${this.account_id}'`];

			try {
				const { rows, row_count } = await getPurchaseHistory({
					filters: [filters],
				});
				this.purchase = rows[0];
				if (this.purchase) {
					try {
						const result: Conversion = await getConversion(this.purchase.id);
						if (result) {
							this.conversion = result;
						}
					} catch (err) {
						log.trace(err);
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to get Conversion Info',
							detail: err.response.errors[0]?.message || err.message,
							life: 3000,
						});
					}
				}
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Something went wrong',
					detail: 'Unable to get Purchase History',
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.lead-info-container {
	max-width: 1200px;
}

.title-section {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1rem;
	max-width: 1200px;

	h2 {
		margin: 0;
	}

	.tablet-down({
		flex-direction: column;
		align-content: flex-start;
		align-items: flex-start;

		h2 {
			margin-bottom: 0.5em;
		}
	});
}

:deep(.name) {
	font-size: var(--font-size-h4);
}

:deep(h3.heading) {
	color: var(--color-b);
	font-size: 0.875rem;
	font-weight: bold;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}
</style>
