import { $GQL } from '@GQL/index';
import { type Account } from '@nextgenleads/marketplace-driver';

export async function getAccountAuctionSettings(id: string, refresh?: boolean): Promise<Partial<Account>> {
	const request = `
			query GetAccountAuctionSettings($account_id: ID!) {
				account(id: $account_id) {
					settings {
						pause_pending_campaigns
						bulk_returns {
							enabled
							return_rate
						}
						data_returns {
							enabled
							return_rate
							introductory_return_rate
						}
						credit_terms {
							enabled
							limit_enabled
							limit
						}
						allow_advanced_mode
						usha {
							agent_id
							enable_validation
						}
					}
				}
				balance(account_id: $account_id) {
					actual
					available
				}
			}
		`;

	const no_cache = refresh ? ['account'] : undefined;
	return await $GQL.request(request, { account_id: id, no_cache });
}
