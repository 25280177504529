<template>
	<div id="data-leads-list">
		<query-form v-model:query="query" :loading="loading" @apply="getAccountDataLeadsReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div v-if="$root.sessionStore.isAdminUser" class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
				<div v-if="$root.sessionStore.isAdminUser" class="display-value">
					<div class="label">Status:</div>
					<div class="value">
						{{ displaySelected(query.filters.status, purchase_status_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getAccountDataLeadsReport(true)"
				/>
				<CSVReport v-if="data_leads.length > 0" :query="query" report="purchases" :ancestor_ids="ancestor_ids" />
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.filters.created_at" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of appStore.verticalOptions"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<template v-if="$root.sessionStore.isAdminUser">
						<gutter size="40px" />
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Query By Source ID:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="query.filters.source_search" class="search-query" type="text" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Status:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="query.filters.status"
												filter
												filter-placeholder="Filter Status"
												:options="purchase_status_options"
												option-label="label"
												option-value="value"
												placeholder="Select Status"
												class="status-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</template>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<!-- Purchase total not including ad portion -->
					<metric label="All Purchases" :value="purchase_count" is-number />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Cost Per Lead" :value="costPerLead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Cost" :value="revenue" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="flex align-items-center justify-content-between">
			<div class="quick-filters">
				<span class="m-2">Quick Filter:</span>
				<div class="toggles">
					<quick-filter
						v-model="query.filters.vertical_id"
						value="health_insurance"
						allow-multiple
						@toggle="handleQuickFilterToggle"
					>
						Health Insurance
					</quick-filter>
					<quick-filter
						v-model="query.filters.vertical_id"
						value="medicare"
						allow-multiple
						@toggle="handleQuickFilterToggle"
					>
						Medicare
					</quick-filter>
				</div>
			</div>
			<div class="flex align-items-center gap-10">
				<p-button
					v-if="mpid === 'GRF' && !$root.sessionStore.isAdminUser"
					v-tooltip.top="'Auto Refresh'"
					:loading="refreshing"
					@click="auto_refresh = !auto_refresh"
					:severity="auto_refresh ? 'primary' : 'secondary'"
				>
					<template #icon>
						<div class="icon-wrapper">
							<icon type="refresh-auto" size="20px" />
						</div>
					</template>
				</p-button>
				<table-tools v-model:show="show_table_tools">
					<p-button text v-tooltip.top="'Re-Ship Purchases'" @click="reshipPurchases()">
						<template #icon>
							<div class="icon-wrapper">
								<icon type="send-variant-outline" size="20px" />
							</div>
						</template>
					</p-button>
				</table-tools>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				:rows="100"
				scrollable
				:value="data_leads"
				:key="refreshKey"
				data-key="purchase_id"
				v-model:selection="selected_purchases"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column v-if="show_table_tools" selection-mode="multiple"></p-column>
				<p-column header="Purchased On">
					<template #body="lead">
						<div class="capture-date">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</div>
						<div class="sub-value">{{ timeAgo(lead.data.created_at) }}</div>
					</template>
				</p-column>
				<p-column header="Campaign Info">
					<template #body="lead">
						<router-link
							v-if="marketplaceStore.mpid"
							:to="`/marketplaces/manage/${marketplaceStore.mpid}/accounts/manage/${lead.data.account_id}/campaigns/${lead.data.campaign_id}`"
						>
							<strong>{{ lead.data.campaign_name }}</strong>
						</router-link>
						<router-link
							v-else-if="$route.params.parent_account_id"
							:to="`/accounts/manage/${lead.data.account_id}/campaigns/${lead.data.campaign_id}`"
						>
							<strong>{{ lead.data.campaign_name }}</strong>
						</router-link>
						<template v-else>
							<template v-if="get(lead.data, 'campaign_flags.lock.enabled', false)">
								<icon type="lock" size="14px" style="margin-top: -3px" />&nbsp;
								<strong>{{ lead.data.campaign_name }}</strong>
							</template>
							<router-link v-else :to="`/campaigns/${lead.data.campaign_id}`">
								<strong>{{ lead.data.campaign_name }}</strong>
							</router-link>
						</template>
						<div class="sub-value">{{ capitalize(lead.data.bid_type) }} | {{ lead.data.campaign_id }}</div>
						<div v-if="$route.params.parent_account_id" class="sub-value">
							{{ lead.data.account_name }} ({{ lead.data.account_id }})
						</div>
					</template>
				</p-column>
				<p-column header="Lead Info">
					<template #body="lead">
						<template v-if="!$root.sessionStore.isAdminUser && lead.data.status === 'return_accepted'">
							<strong>RETURNED LEAD</strong>
							<div class="sub-value">
								{{ lead.data.lead_id }}: {{ phoneFormat('******' + lead.data.phone.substring(6, 10)) }}
							</div>
						</template>
						<template v-else-if="!$root.sessionStore.isAdminUser && lead.data.status === 'canceled'">
							<strong>CANCELED LEAD</strong>
							<div class="sub-value">
								{{ lead.data.lead_id }}: {{ phoneFormat('******' + lead.data.phone.substring(6, 10)) }}
							</div>
						</template>
						<template v-else>
							<router-link :to="`/leads/${lead.data.lead_id}`">
								<strong>{{ lead.data.first_name }} {{ lead.data.last_name }}</strong>
							</router-link>
							<div class="sub-value">{{ lead.data.lead_id }}: {{ phoneFormat(lead.data.phone) }}</div>
						</template>
					</template>
				</p-column>
				<p-column header="Source">
					<template #body="lead">
						<template v-if="$root.sessionStore.isAdminUser">
							{{ lead.data.vendor_name }}: {{ lead.data.source_id }}
							<div class="sub-value">{{ lead.data.sub_id }}</div>
						</template>
						<template v-else>
							{{ lead.data.vendor_name }}
							<div class="sub-value">
								<div class="sub-value">{{ lead.data.source_hash }}-{{ lead.data.sub_id_hash }}</div>
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="State" class="column-align-center">
					<template #body="lead">
						{{ lead.data.state }}
					</template>
				</p-column>
				<p-column header="Vertical" class="column-align-center">
					<template #body="lead">
						<vertical-icon :vertical-id="lead.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column header="Price" class="column-align-center">
					<template #body="lead">
						<div v-if="lead.data.pl_price">
							{{ lead.data.price ? currency(lead.data.price + lead.data.pl_price) : '--' }}
							<div class="sub-value">
								Premium Listing
								<icon
									style="display: inline-table"
									type="information-outline"
									v-tooltip.top="'Includes $5 add-on fee'"
									size="1em"
								/>
							</div>
							<div v-if="lead.data.call_duration && lead.data.call_duration > 0" class="sub-value call-duration">
								Duration: {{ callDuration(lead.data.call_duration) }}
							</div>
						</div>
						<div v-else>
							{{ lead.data.price ? currency(lead.data.price) : '--' }}
						</div>
					</template>
				</p-column>
				<p-column header="Status" class="column-align-center">
					<template #body="lead">
						{{ capitalize(lead.data.status) }}
						<div v-if="lead.data.return_reason" class="sub-value">
							{{ capitalize(lead.data.return_reason) }}
							<icon
								v-if="lead.data.return_notes"
								style="display: inline-table"
								type="information-outline"
								v-tooltip.top="{ value: lead.data.return_notes }"
							/>
						</div>
					</template>
				</p-column>
				<p-column header="Disposition Status" class="column-align-center">
					<template #body="lead">{{ lead.data.disposition }}</template>
				</p-column>
				<p-column header="Disposition" class="column-align-center">
					<template #body="lead">
						<div class="toolset">
							<div class="tool">
								<p-button
									@click="openDispositionModal(lead.data.purchase_id, lead.data.lead_id)"
									v-tooltip.top="'Disposition'"
									aria-label="Disposition"
								>
									<template #icon>
										<icon type="delta" size="20px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="purchase_count" @page="handlePageChange" />
		</div>
		<AuctionModal ref="auction_modal" />
		<DispositionModal ref="disposition_modal" @update="updatePurchaseDisposition" />
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { get, find, startCase, map, padStart } from 'lodash-es';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import {
	formatDate,
	displaySelected,
	currency,
	verticalName,
	phoneFormat,
	capitalize,
	timeAgo,
	subIdHash,
} from '@/lib/Filters';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import DispositionModal from '@/views/Leads/panels/modals/DispositionModal.vue';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import tableTools from '@/components/widgets/TableTools.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import STATES from '@/lib/Data/states.json';
import { source_type_options, purchase_status_options } from '@/lib/Options';
import { getAncestors, reportAccountPurchases, shipPurchases } from '@GQL';
import type { FilterParams } from '@nextgenleads/db';
import CSVReport from '@/components/widgets/CSVReport.vue';

export default {
	name: 'AccountDataLeadPurchasesList',
	components: {
		AuctionModal,
		CSVReport,
		DispositionModal,
		dateRange,
		queryForm,
		quickFilter,
		tableTools,
		typeIcon,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					source_type: [],
					source_search: '',
					state: [],
					status: ['complete', 'return_rejected', 'return_pending', 'return_accepted'],
				},
				pagination: {
					page_size: 100,
					page: 0,
				},
			},
			revenue: 0,
			ad_revenue: 0,
			purchase_count: 0,
			ad_purchase_count: 0,
			purchase_status_options,
			state_options: [...STATES],
			source_type_options,
			data_leads: [],
			selected_purchases: [],
			refreshKey: 0,
			show_table_tools: false,
			ancestor_ids: [],
			auto_refresh: false,
			refresh_interval: null,
			refreshing: false,
		};
	},
	computed: {
		purchaseCount() {
			return this.purchase_count;
		},
		adPurchaseCount() {
			return this.ad_purchase_count;
		},
		costPerLead() {
			const total_spend = this.revenue + this.ad_revenue;
			const purchase_count = this.purchase_count;
			if (purchase_count > 0) {
				return total_spend / purchase_count;
			}
			return 0;
		},
		mpid() {
			return this.$route.params.mpid || this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		isParentAccountAdmin() {
			return this.$root.sessionStore.user.role_id[this.mpid] === 'parent_account_admin';
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	watch: {
		auto_refresh(new_value) {
			if (new_value) {
				this.refresh_interval = setInterval(async () => {
					if (this.auto_refresh) {
						try {
							this.refreshing = true;
							await this.getAccountDataLeadsReport(true);
						} catch (err) {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to refresh data',
								detail: 'Try refreshing the entire page',
								life: 3000,
							});
						} finally {
							this.refreshing = false;
						}
					}
				}, 15000);
			} else {
				clearInterval(this.refresh_interval);
			}
		},
	},
	async created() {
		if (this.$route.params.parent_account_id || this.$root.sessionStore.isParentAccount || this.isParentAccountAdmin) {
			const parent_account_id = this.$route.params.parent_account_id || this.$root.sessionStore.user.account_id;
			this.ancestor_ids = (await getAncestors(parent_account_id, ['ancestorAccountsList'])).map((account) => {
				return account.id;
			});
		}
		await this.getAccountDataLeadsReport();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displaySelected,
		get,
		map,
		parseISO,
		phoneFormat,
		startCase,
		timeAgo,
		subIdHash,
		verticalName,
		openDispositionModal(purchase_id, lead_id) {
			this.$refs.disposition_modal.open(purchase_id, lead_id);
		},
		clearQuery() {
			this.loading = false;
		},
		updatePurchaseDisposition(event) {
			const matching_lead = find(this.data_leads, { purchase_id: event.purchase_id });

			if (matching_lead) {
				matching_lead.disposition = event.disposition;
			}
		},
		callDuration(duration) {
			if (duration > 0) {
				const formatted: string[] = [];
				const hours = Math.floor(duration / 3600);
				const minutes = Math.floor((duration - 3600 * hours) / 60);
				const seconds = duration - 3600 * hours - 60 * minutes;
				if (hours > 0) formatted.push(hours.toString() + 'h');
				if (minutes > 0) formatted.push(minutes.toString() + 'm');
				formatted.push(seconds.toString() + 's');
				return formatted.join(' ');
			}
			return false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getAccountDataLeadsReport();
		},
		handleQuickFilterToggle() {
			this.$nextTick(async () => {
				await this.getAccountDataLeadsReport();
			});
		},
		async getAccountDataLeadsReport(refresh?: boolean) {
			this.loading = true;

			const query_params = {
				date_range: this.query.filters.created_at,
				filters: [],
				account_id: this.$route.params.account_id || this.$root.sessionStore.user.account_id,
				page: this.query.pagination.page,
				parent: 'parent_account_id' in this.$route.params && !('account_id' in this.$route.params), // so that child view shows the purchases
			};

			const filters: (FilterParams | string)[] = [
				{
					op: 'in',
					field: 'p.product',
					value: ['data'],
				},
			];

			if (this.$root.sessionStore.isAdminUser) {
				filters.push({
					op: 'in',
					field: 'p.status',
					value: this.query.filters.status,
				});
			} else {
				filters.push(`p.status IN ('complete','return_rejected','return_pending', 'return_accepted')`);
			}

			if (this.query.filters.vertical_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'p.vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.source_search) {
				filters.push({
					op: 'eq',
					field: 'p.source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state.length > 0) {
				filters.push({
					op: 'in',
					field: 'p.lead_state',
					value: this.query.filters.state,
				});
			}

			if (
				this.$route.params.parent_account_id ||
				this.$root.sessionStore.isParentAccount ||
				this.isParentAccountAdmin
			) {
				query_params.parent = true;
				query_params.account_id = this.$route.params.parent_account_id || this.$root.sessionStore.user.account_id;
				filters.push({
					op: 'in',
					field: 'p.account_id',
					value: this.ancestor_ids,
				});
			}

			if (filters.length > 0) {
				query_params.filters.push(filters);
			}

			try {
				const report = await reportAccountPurchases(query_params, refresh);
				this.revenue = report.metrics.revenue;
				this.ad_revenue = report.metrics.ad_revenue;
				this.data_leads = report.rows;
				this.purchase_count = report.row_count;
				this.ad_purchase_count = report.metrics.ad_purchases;
				if (refresh) {
					this.refreshKey += 1;
				}
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Data Leads',
					life: 3000,
				});
			}
			this.loading = false;
		},
		openAuctionLogModal(lead_id, auction_log_id) {
			this.$refs.auction_modal.open(lead_id, auction_log_id);
		},
		async reshipPurchases() {
			try {
				if (this.selected_purchases.length > 0) {
					const purchase_ids = this.selected_purchases.map((purchase) => {
						return purchase.purchase_id;
					});
					const ship_result = await shipPurchases(purchase_ids);
					if (ship_result) {
						this.$toast.add({
							severity: 'success',
							summary: 'Shipments Sent',
							detail: 'See shipment logs for details',
							life: 3000,
						});
					} else {
						throw new Error('Unknown error');
					}
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'An Error Occurred',
					detail: err.message,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.click-target .mdi-icon {
	color: var(--color-b);
	cursor: pointer;
}

.call-duration {
	color: var(--green);
}
</style>
