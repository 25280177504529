<template>
	<div id="sub-accounts" class="view-content">
		<div class="page-heading">
			<h1>Child Accounts</h1>
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getList" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.filters.created_at" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter v-if="!mq.phone" size="40px" />
					<column v-if="!mq.phone"></column>
				</row>
			</template>
		</query-form>
		<gutter size="20px" />
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Child Accounts" :value="total_records" is-number />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Spend" :value="total_spend" is-currency />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Purchases" :value="total_purchases" is-number />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="flex">
			<p-button
				type="button"
				label="New Child Account"
				icon="pi pi-plus"
				:loading="loading"
				@click.prevent="$router.push(`${$route.path}/new`)"
			/>
			<gutter size="10px" />
			<p-input-text v-model="search_query" placeholder="Type to find child accounts..." style="width: 350px" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				:rows="10"
				scrollable
				:value="sub_accounts"
				sortField="name"
				:sortOrder="1"
			>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column field="name" header="Account Name" sortable sortField="name">
					<template #body="sub_account">
						<router-link
							v-if="sub_account.data.is_parent && $root.sessionStore.isAdmin"
							class="company-name"
							:to="{
								path: `../${sub_account.data.id}`,
							}"
						>
							{{ sub_account.data.name }}
						</router-link>
						<router-link
							v-else-if="sub_account.data.is_parent"
							class="company-name"
							:to="{
								path: `../parent-accounts/${sub_account.data.id}/reports/parent-account-overview`,
							}"
						>
							{{ sub_account.data.name }}
						</router-link>
						<router-link
							v-else
							:to="{
								path: `../../accounts/manage/${sub_account.data.id}/reports/account-overview`,
							}"
						>
							{{ sub_account.data.name }}
						</router-link>

						<br />
						<span class="company-id"
							>{{ sub_account.data.id }} {{ sub_account.data.is_parent ? ' is Parent' : '' }}</span
						>
					</template>
				</p-column>
				<p-column field="agent_name" header="Agent Name" sortable sortField="primary_user.first_name">
					<template #body="sub_account">
						<div v-if="mq.phone" class="mobile-th">Sign-up Date</div>
						{{ sub_account.data.primary_user.first_name }} {{ sub_account.data.primary_user.last_name }}
					</template>
				</p-column>
				<p-column class="align-left" field="balance" header="Balance" sortable sortField="balance.actual">
					<template #body="sub_account">
						<div v-if="mq.phone" class="mobile-th">Balance</div>
						{{ currency(sub_account.data.balance.actual) }}
					</template>
				</p-column>
				<p-column class="align-left" field="spend" header="Spend" sortable sortField="spend">
					<template #body="sub_account">
						<div v-if="mq.phone" class="mobile-th">Spend</div>
						{{ currency(sub_account.data.spend) }}
					</template>
				</p-column>
				<p-column class="align-left" field="purchaseCount" header="Leads Purchased" sortable sortField="purchaseCount">
					<template #body="sub_account">
						<div v-if="mq.phone" class="mobile-th">Leads Purchased</div>
						{{ sub_account.data.purchaseCount }}
					</template>
				</p-column>
				<p-column class="align-center" field="tools" header="Tools">
					<template #body="sub_account">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click.prevent="$router.push(`${$route.path}/${sub_account.data.id}`)"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, currency } from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { getChildAccountList } from '@GQL';
import { useSessionStore } from '@/stores/session';
import { GetListParams, QueryFilters } from '@nextgenleads/db';
import { get, debounce } from 'lodash-es';
import { sumBy } from 'lodash-es';

export default {
	name: 'SubAccountsTab',
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	components: {
		dateRange,
		queryForm,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
				},
				pagination: {
					page: 0,
					page_size: 50,
				},
			},
			sub_accounts: [],
			total_records: 0,
			search_query: null,
		};
	},
	computed: {
		parentAccountId() {
			// if it's in the route or if it's the current users parent
			// more logic may be needed in the future for this
			if ('parent_account_id' in this.$route.params) {
				return this.$route.params.parent_account_id; // this may be the only one r
			} else {
				return this.sessionStore.user.account.id;
			}
		},
		total_spend() {
			return sumBy(this.sub_accounts, 'spend');
		},
		total_purchases() {
			return sumBy(this.sub_accounts, 'purchaseCount');
		},
	},
	methods: {
		get,
		formatDate,
		currency,
		async handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getList();
		},
		clearQuery() {
			this.loading = false;
		},
		async getList() {
			this.loading = true;

			const spend_filters: QueryFilters = [
				[
					{
						field: 'created_at',
						op: 'gt',
						value: this.query.filters.created_at[0],
					},
					{
						field: 'created_at',
						op: 'lt',
						value: this.query.filters.created_at[1],
					},
					{
						field: 'status',
						op: 'in',
						value: ['complete', 'return_rejected'], // only get spend for completed purchases
					},
				],
			];

			const account_params: GetListParams = {
				filters: [
					[
						{
							field: 'parent_account_id',
							op: 'eq',
							value: this.parentAccountId,
						},
					],
				],
				pagination: this.query.pagination,
			};

			if (this.search_query) {
				const search_query = this.search_query.toLowerCase();
				account_params.filters[0].push(`LOWER(name) LIKE '%${search_query}%'`);
			}

			try {
				const { rows, row_count } = await getChildAccountList(account_params, spend_filters, spend_filters);
				this.sub_accounts = rows;
				this.total_records = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		await this.getList();
	},
	watch: {
		search_query: {
			handler: debounce(async function (new_value, old_value) {
				if (new_value.length === 0 || new_value.length > 2) {
					await this.getList(true);
				}
			}, 250),
		},
	},
};
</script>

<style lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
