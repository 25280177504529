<template>
	<div id="ad-form" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.ad_id ? 'Edit' : 'Create' }} Premium Listing</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="ad-card card padded">
			<ad-form />
		</div>
	</div>
</template>

<script>
import adForm from './Form.vue';

export default {
	name: 'AdFormView',
	components: {
		adForm,
	},
};
</script>

<style lang="less">
.ad-card {
	max-width: 760px;
}
</style>
