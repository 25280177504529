import { $GQL } from '@GQL/index';

export async function reportAccountOverview(params: {
	date_range: [string, string];
	compare: any;
	account_id: string;
}) {
	const query = `
        query ReportAccountOverview($params: JSONObject!, $account_id: ID!) {
            reportAccountOverview(params: $params)
            account(id: $account_id) {
				support {
					account_manager
					{
						id
						first_name
						last_name
						phone
						email
					}
				}
            	balance {
					actual
            	}
				lastPurchase
            	created_at
				status
				parentAccount {
					name
				}
            }
        }
    `;

	const results = await $GQL.request(query, { params, account_id: params.account_id });
	return results;
}
