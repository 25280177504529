import { $GQL } from '@GQL/index';
import type { GetListParams, QueryFilters } from '@nextgenleads/db';
import log from '@/lib/Log';

export async function getChildAccountList(
	account_params: GetListParams,
	spend_params: QueryFilters,
	purchase_count_params: QueryFilters
) {
	const query = `
        query GetChildAccountList($account_params: GetListParams!, $spend_params: [[Any]], $purchase_count_params: [[Any]]) {
            accountList(params: $account_params) {
                rows {
                    id
                    name
                    status
                    is_parent
                    primary_user {
						first_name
						last_name
                    }
                    created_at
                    balance {
                    	actual
                    }
                    spend(params: $spend_params)
                    purchaseCount(filters: $purchase_count_params)
                }
                row_count
            }
        }
    `;

	const result = await $GQL.request(query, {
		account_params,
		spend_params,
		purchase_count_params,
	});

	return result.accountList;
}
